import { createTheme, ThemeOptions } from '@mui/material/styles';
import { palette } from "./palette";
import { merge } from 'lodash'
import { DialogTitle } from '@mui/material';

const themeConfig: ThemeOptions = {
  typography: {
    fontFamily: 'roboto, helvetica'
  },
  palette: palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,    // phone
      md: 768,    // tablet
      lg: 1366,   // labtop
      xl: 1920,   // hd monitor
    }
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: `${palette.alltech.dark_grey[50]} !important`
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 15
        }
      }
    }
  }
}

export const MuiTheme = (overrides?: ThemeOptions) => createTheme(merge({}, { ...themeConfig }, overrides));

export const MuiThemeDark = (overrides?: ThemeOptions) => createTheme(merge({}, { ...themeConfig }, { palette: { mode: 'dark' } }, overrides))

