import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow, IconButton, MenuItem, MenuList, Paper, Popper, TextField, Tooltip, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useTypedAppSelector } from 'src/store'
import styled, { useTheme } from 'styled-components'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import ClientSearchComponent from 'src/components/accountSearch/client-search'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import NutritionistSearchComponent from 'src/components/accountSearch/nutritionist-search'
import { NumberFormatContext } from "src/contexts/numberformat.context"
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { GridActionsCellItem, GridColumnHeaderParams, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { CircleTickIcon } from 'src/components/icons/circle-tick'
import { CircleIcon } from 'src/components/icons/circle'
import { rpmAction } from 'src/store/actions/rpm.actions'
import { FilterIcon } from 'src/components/icons/filter'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import CommentDialog from './val-comment'
import { ReviewIcon } from 'src/components/icons/review'
import HelperIconComponent from 'src/components/helperIcon/helper-icon'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { ActiveFilterIcon } from 'src/components/icons/active-filter'
import { EmailIcon } from 'src/components/icons/email'
import { Clear } from '@mui/icons-material'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const ValidationText = styled(Paper)`
  padding: 6px;
  width: 220px;
  line-height: 1.8;
  .centered {
    display: flex;
    align-items: center;
  }
  .validated-icon {
    color: ${p => p.theme.palette.success.main};
  }
`

export const StyledPopper = styled(Popper)`
  z-index: 999;
  box-shadow: 0px 4px 8px ${p => p.theme.palette.secondary[500]};
  .MuiMenuItem-root {
    font-size: 13px;
  }
  .Mui-selected {
    background-color: ${p => p.theme.palette.lightGrey[80]} !important;
  }
  .Mui-selected:hover {
    background-color: ${p => p.theme.palette.lightGrey.main} !important;
  }
`

const _RPMValidation: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [account, setAccount] = useState<{acc: API_NS.Accounts | API_NS.Nutritionists, type: 'nutritionist' | 'customer'} | undefined>(undefined)
  const [clear, setClear] = useState<boolean>(false)
  const [resetNutInput, setResetNutInput] = useState<boolean>(false)
  const [resetCusInput, setResetCusInput] = useState<boolean>(false)
  const [comment, setComment] = useState<{id: string, str: string}>({id: '', str: ''})
  const [openComment, setOpenComment] = useState<boolean>(false)
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [emailPrompt, setEmailPrompt] = useState<boolean>(false)
  const [openSendEmail, setOpenSendEmail] = useState<boolean>(false)
  const [validatedOnly, setValidatedOnly] = useState<boolean | undefined>(undefined)
  const [selectedRpm, setSelectedRpm] = useState<API_NS.Rpms>()
  const [rpmData, setRpmData] = useState<API_NS.Rpms[]>([])
  const rpms = useTypedAppSelector(s => s.rpmState.rpms)
  const rpmEmailState = useTypedAppSelector(s => s.rpmState.rpmEmailState)
  const emailList = useTypedAppSelector(s => s.rpmState.rpmEmailList)
  const rpmValidationStatus = useTypedAppSelector(state => state.rpmState.rpmValidationStatus)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { numberFormatter } = useContext(NumberFormatContext)
  const { setMessage } = useContext(SnackBarContext)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const apiRef = useGridApiRef()
  const intl = useIntl()
  const dispatch = useDispatch()
  const theme = useTheme()
  const CustomGridActionsCellItem = GridActionsCellItem as any

  const selectAccountHandler = (acc, type) => setAccount({ acc, type })

  const resetClear = () => { setAccount(undefined); setClear(false) }

  const clearSearches = () => {
    setClear(true)
    setAccount(undefined)
  }

  const updateComment = str => {
    dispatch(rpmAction.updateComment({ RpmId: comment?.id, ValidatedComment: str.replace(/\n/g, '<br>') }))
  }

  const validationToggle = row => {
    if (!row) return
    setSelectedRpm(row)
    if (row.IsValidated === 'F')
      dispatch(rpmAction.requestRpmEmailList({ clientCode: account?.type === 'customer' && account?.acc?.['ClientCode'] ? account.acc['ClientCode'] : row.AccountId }));
    dispatch(rpmAction.updateValidation({ RpmId: row.RpmId, IsValidated: row.IsValidated === 'T' ? 'F' : 'T' }))
  }

  const toggleValHandler = val => setValidatedOnly(validatedOnly === val ? undefined : val)

  const onSendHandler = () => {
    if (!selectedRpm || !emailList?.value) return
    dispatch(rpmAction.sendRpmEmail({ Email: emailList.value.email.split(','), RpmId: parseInt(selectedRpm.RpmId), RpmType: 'Execution' }))
    setSelectedRpm(undefined)
  }

  const clearHandler = () => {
    dispatch(rpmAction.clearRpmEmailList())
    if (rpmValidationStatus?.value) {
      dispatch(rpmAction.clearValidationStatus())
      setSelectedRpm(undefined)
    }
  }

  const columns = [
    {
      field: 'actions',
      renderHeader: (_p: GridColumnHeaderParams) => (<HelperIconComponent iconSize={20} helpText={<div style={{ padding: 6, width: 340 }}>
        Visit the Advisor Dashboard to edit a specific client's RPM email recipients
      </div>} />),
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={<Tooltip title='Send Email' followCursor><span><EmailIcon className='alltech-colour' size={22} /></span></Tooltip>}
          label='Send Email'
          onClick={() => {
            dispatch(rpmAction.requestRpmEmailList({ clientCode: account?.type === 'customer' && account?.acc?.['ClientCode'] ? account.acc['ClientCode'] : p.row.AccountId }))
            setSelectedRpm(p.row)
            setOpenSendEmail(true)
          }}
        />
      ]
    },
    {
      field: 'RpmDate',
      headerName: 'Date',
      flex: 0.6,
      valueFormatter: (p: any) => {
        const d = p.value as string
        return d ? intl.formatDate(d, { year: '2-digit', month: '2-digit', day: '2-digit' }) : '-'
      },
      sortComparator: (a, b) => moment(a).isBefore(b) ? -1 : 1,
    },
    {
      field: 'AccountId',
      headerName: 'Client ID',
      flex: 0.6,
      sortComparator: (a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1,
    },
    {
      field: 'FarmName',
      headerName: 'Client Name',
      flex: 1,
      sortComparator: (a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1,
    },
    {
      field: 'FCE',
      headerName: 'FCE',
      flex: 0.4,
      valueFormatter: v => v.value || v.value === 0 ? numberFormatter(v.value as number, 2, 2) : '-'
    },
    {
      field: 'Yield',
      headerName: 'Yield (l)',
      flex: 0.4,
      valueFormatter: v => v.value || v.value === 0 ? numberFormatter(v.value as number, 2, 2) : '-'
    },
    {
      field: 'DMI',
      headerName: 'DMI',
      flex: 0.6,
      valueFormatter: v => v.value || v.value === 0 ? numberFormatter(v.value as number, 2, 2) : '-'
    },
    {
      field: 'Fat',
      headerName: 'Fat (%)',
      flex: 0.4,
      valueFormatter: v => v.value || v.value === 0 ? numberFormatter(v.value as number, 2, 2) : '-'
    },
    {
      field: 'Protein',
      headerName: 'Protein (%)',
      flex: 0.5,
      valueFormatter: v => v.value || v.value === 0 ? numberFormatter(v.value as number, 2, 2) : '-'
    },
    {
      field: 'Margin',
      headerName: 'Margin (c)',
      flex: 0.5,
      valueFormatter: v => v.value || v.value === 0 ? numberFormatter(v.value as number, 2, 2) : '-'
    },
    {
      field: 'DailyCost',
      headerName: 'Cost Per Litre (c)',
      flex: 0.7,
      valueFormatter: v => v.value || v.value === 0 ? numberFormatter(v.value as number, 2, 2) : '-'
    },
    {
      field: 'MilkPrice',
      headerName: 'Milk Price (c)',
      flex: 0.5,
      valueFormatter: v => v.value || v.value === 0 ? numberFormatter(v.value as number, 2, 2) : '-'
    },
    { field: 'DaysInMilk', headerName: 'Days in Milk', flex: 0.5 },
    { field: 'CowsInMilk', headerName: 'Cows in Milk', flex: 0.5 },
    {
      field: 'ValidatedComment',
      headerName: 'Comment',
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (p: GridRenderCellParams) => <div className='comment-cell' onClick={() => { if (!hasAccess) { setComment({id: p.row?.RpmId, str: p?.value}); setOpenComment(true) } }}>
        {p?.value?.replace(/<br\s*\\?>/g, '\r\n') || ''}
      </div>
    },
    {
      field: 'IsValidated',
      flex: 0.3,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      renderHeader: (_p: GridColumnHeaderParams) => (<HelperIconComponent icon={<ReviewIcon size={30} />} helpText={<ValidationText><b>RPM Validation</b><br/>
        <div className='centered'><CircleTickIcon className='green-colour' size={20} /> - Validated</div>
        <div className='centered'><CircleIcon size={20} /> - Not Validated</div>
      </ValidationText>} />),
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={p?.row?.IsValidated === 'T' ? <CircleTickIcon className='green-colour' size={25} /> : <CircleIcon size={25} />}
          label='Validation'
          onClick={() => validationToggle(p.row)}
          disabled={rpmValidationStatus?.loading}
        />
      ]
    }
  ]

  useEffect(() => {
    setLoading(rpms?.loading || rpmEmailState?.loading || emailList?.loading || rpmValidationStatus?.loading ? true : false)
  }, [rpms?.loading, rpmEmailState?.loading, emailList?.loading, rpmValidationStatus?.loading])

  useEffect(() => {
    rpms?.message && setMessage({ message: rpms.message.msg, severity: rpms.message.level, clear: () => dispatch(rpmAction.clearRpmsMessage()) })
    rpmEmailState?.message && setMessage({ message: rpmEmailState.message.msg, severity: rpmEmailState.message.level, clear: () => dispatch(rpmAction.sendRpmEmailReset()) })
    emailList?.message && setMessage({ message: emailList.message.msg, severity: emailList.message.level, clear: () => dispatch(rpmAction.clearRpmEmailMessage()) })
    rpmValidationStatus?.message && setMessage({ message: rpmValidationStatus.message.msg, severity: rpmValidationStatus.message.level, clear: () => { dispatch(rpmAction.clearValidationStatus()) } })
  }, [rpms?.message, rpmEmailState?.message, emailList?.message, rpmValidationStatus?.message])

  useEffect(() => {
    if (rpmValidationStatus?.value && selectedRpm?.IsValidated === 'F')
      setEmailPrompt(true)
  }, [rpmValidationStatus])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('rpm', 17, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    if (account?.acc) {
      if (account?.type === 'customer')
        dispatch(rpmAction.requestClientRpms({ clientId: account.acc?.['ClientId'] }))
      else
        dispatch(rpmAction.requestNutritionistRpms({ id: account.acc?.['USERID'] }))
    }
  }, [account])

  useEffect(() => {
    if (!rpms?.value) return
    setRpmData(rpms?.value.map((r, i) => { return {...r, id: i} }))
  }, [rpms?.value])

  useEffect(() => {
    dispatch(rpmAction.clearRpms())
    return () => {
      setLoading(false)
    }
  }, [])
  
  const emailValDialog = <Dialog className={className} fullWidth={true} maxWidth='xs' open={emailPrompt} onClose={() => setEmailPrompt(false)}>
    <DialogTitle>Email Notification</DialogTitle>
    <DialogContent>Would you like to send an email notification to the RPM email list?</DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setEmailPrompt(false); onSendHandler() }} disabled={rpmEmailState?.loading} startIcon={rpmEmailState?.loading ? <CircularProgress style={{ color: 'white' }} size={15} /> : undefined}>
        Yes
      </Button>
      <Button size='small' onClick={() => { setEmailPrompt(false); clearHandler() }}>No</Button>
    </DialogActions>
  </Dialog>
  
  const sendEmailDialog = <Dialog className={className} fullWidth={true} maxWidth='xs' open={openSendEmail} onClose={() => { setOpenSendEmail(false); dispatch(rpmAction.clearRpmEmailList()) }}>
    <DialogTitle>RPM Email List</DialogTitle>
    <DialogContent>
      <DialogContentText>Send RPM email to the following email addresses</DialogContentText>
      <IconButton style={{ position: 'absolute', top: 12, right: 12 }} color='secondary' size='small' onClick={() => { setOpenSendEmail(false); dispatch(rpmAction.clearRpmEmailList()) }}>
        <Clear />
      </IconButton>
      {emailList?.loading ? <div className='loading-spinner'><CircularProgress size={50} /></div>
      : <TextField
        autoFocus
        sx={{ '.MuiInputBase-input': { fontSize: '14px' } }} 
        variant='standard'
        margin='dense'
        id='emails'
        label='Email Address(es)'
        type='email'
        value={emailList?.value?.email || ''}
        fullWidth
        multiline
        disabled
      />}
    </DialogContent>
    <DialogActions>
      <Button size='small' onClick={onSendHandler} disabled={rpmEmailState?.loading || rpmEmailState?.loaded || emailList?.loading || emailList?.value?.email?.length === 0} startIcon={rpmEmailState?.loading ? <CircularProgress style={{ color: 'white' }} size={15} /> : undefined}>
        Send Email
      </Button>
    </DialogActions>
  </Dialog>

  const HeaderMenu = () => <React.Fragment>
    <Tooltip title='Filter' followCursor>
      <Button style={{ borderRadius: 20 }} color='primary' ref={anchorRef} onClick={() => setOpenFilter(!openFilter)}>
        {validatedOnly === undefined ? <FilterIcon size={26} /> : <ActiveFilterIcon size={26} />}
      </Button>
    </Tooltip>
    <StyledPopper open={openFilter} anchorEl={anchorRef.current} placement='bottom-start' style={{ zIndex: 999 }} transition>
      {({ TransitionProps }) => (<Grow {...TransitionProps} timeout={200}>
        <Paper sx={{ bgcolor: 'white', boxShadow: `0px 4px 8px ${theme.palette.alltech.dark_grey[50]}` }}>
          <ClickAwayListener onClickAway={() => setOpenFilter(false)}>
            <MenuList style={{ maxHeight: 300, overflowY: 'auto', padding: '4px 0' }} variant='menu'>
              <MenuItem dense disableRipple sx={{ pl: '12px' }} onClick={() => toggleValHandler(true)} selected={validatedOnly}>
                {validatedOnly !== undefined && validatedOnly ? <CircleTickIcon size={25} /> : <CircleIcon size={25} />}&nbsp;
                <Typography variant='body2'>Validated RPMs Only</Typography>
              </MenuItem>
              <MenuItem dense disableRipple sx={{ pl: '12px' }} onClick={() => toggleValHandler(false)} selected={validatedOnly !== undefined && !validatedOnly}>
                {validatedOnly !== undefined && !validatedOnly ? <CircleTickIcon size={25} /> : <CircleIcon size={25} />}&nbsp;
                <Typography variant='body2'>Unvalidated RPMs Only</Typography>
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>)}
    </StyledPopper>
  </React.Fragment>

  return <div className={className}>
    <div className='search-div'>
      <ClientSearchComponent selectedAccount={account} selectAccountHandler={selectAccountHandler} clear={clear} resetClear={resetClear} clearInput={resetCusInput} setInput={setResetNutInput} disabled={hasAccess} />
      <div style={{ padding: '0 20px' }}>or</div>
      <NutritionistSearchComponent selectedNutritionist={account} selectNutritionistHandler={selectAccountHandler} clear={clear} resetClear={resetClear} clearInput={resetNutInput} setInput={setResetCusInput} disabled={hasAccess} />
      <Button style={{ marginLeft: 20 }} variant='contained' color='primary' size='small' onClick={clearSearches} disabled={account === undefined}>Clear</Button>
    </div>
    {account?.acc && <Paper className='paper'>
      <DataGridComponent
        title=' '
        data={validatedOnly ? (rpmData || []).filter(v => v.IsValidated === 'T') : validatedOnly !== undefined && !validatedOnly ? (rpmData || []).filter(v => v.IsValidated === 'F') : rpmData || []}
        columns={columns}
        apiRef={apiRef}
        rowHeight={45}
        rowsPerPage={100}
        getRowHeight={() => 'auto'}
        useSearch={false}
        loading={rpms?.loading}
        disableColumnResize={false}
        headerMenu={() => HeaderMenu()}
        defaultState={{
          sorting: { sortModel: [{ field: 'RpmDate', sort: 'desc' }] },
          columns: { columnVisibilityModel: account?.type === 'customer' ? { FarmName: false, AccountId: false } : undefined }
        }}
      />
    </Paper>}
    {emailValDialog}
    {sendEmailDialog}
    {openComment && <CommentDialog comment={comment} rpmsLoading={rpms?.loading} updateComment={updateComment} openComment={openComment} setOpenComment={setOpenComment} />}
  </div>
}

export const RPMValidationComponent = styled(_RPMValidation)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .paper {
    width: 100%;
    height: 100%;
    padding: 8px;
    margin-top: 30px;
  }
  .search-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .alltech-colour {
    color: ${p => p.theme.palette.alltech.terracotta.main};
  }
  .green-colour {
    color: ${p => p.theme.palette.success.main};
  }
  .column-header, .table-cell {
    font-size: 12px;
  }
  .comment-cell {
    border-radius: 4px;
    padding: 6px;
    margin: 2px;
    border: 1px solid ${p => p.theme.palette.alltech.dark_grey[50]};
    font-family: Roboto;
    width: 100%;
    min-height: 30px;
    max-height: 64px;
    overflow-y: auto;
    word-wrap: break-word;
    white-space: break-spaces;
    cursor: pointer;
  }
  .loading-spinner {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
`
export default RPMValidationComponent