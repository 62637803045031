import { Button, CircularProgress, Dialog, styled } from '@mui/material';
import React, { useState } from 'react'

export type proptypes = {
  comment?: {id: string, str: string};
  rpmsLoading?: boolean
  updateComment?: (str: string) => void;
  openComment: boolean;
  setOpenComment?: (b: boolean) => void
}
export const defaultProps: proptypes = {
  openComment: false
}

const _CommentDialog: React.FC<proptypes & { className?: string }> = (props) => {
  const { className, comment, rpmsLoading, updateComment, openComment, setOpenComment } = props
  const [str, setStr] = useState(comment?.str || '')
  
  return <Dialog className={className} fullWidth maxWidth='md' open={openComment} onClose={() => setOpenComment && setOpenComment(false)}>
    <div style={{ padding: 20 }}>
      <div><b>Comments</b></div>
      <div style={{ fontSize: 14, margin: '20px 0' }}>
        <textarea
          className='comment-box'
          autoFocus
          rows={8}
          value={str.replace(/<br\s*\\?>/g, '\r\n')}
          onChange={e => setStr(e.target.value)}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button
          color='primary'
          variant='contained'
          onClick={() => updateComment && updateComment(str)}
          startIcon={rpmsLoading && <CircularProgress style={{ color: 'white' }} size={15} />}
          disabled={rpmsLoading}
        >
          Save
        </Button>
        <Button color='primary' variant='contained' style={{ marginLeft: 16 }} onClick={() => setOpenComment && setOpenComment(false)}>
          Close
        </Button>
      </div>
    </div>
  </Dialog>
}

export const CommentDialog = styled(_CommentDialog)`
  
  .comment-box {
    margin-top: 8px;
    margin-bottom: 8px;
    min-width: 150px;
    width: 100%;
    padding: 6px;
    font-size: 14px;
    border-radius: 6px;
    border-color: ${p => p.theme.palette.alltech.light_grey[60]};
    font-family: 'Roboto';
  }
`
export default CommentDialog
