import { TUserAction } from "../actions/user.actions"
import { Reducer } from "redux"
import { API_NS } from "../../lib/api/endpoints/endpoint.types"
import { TStateAction } from "../actions/actions"
import { AsyncProcessState } from "../types/shared-state-types"
import { AccountInfo } from "@azure/msal-common";

export type userReducerState = {
  user: AccountInfo | undefined;
  userFeatures?: AsyncProcessState<API_NS.UserFeatures[]>;
  accounts?: AsyncProcessState<API_NS.Accounts[]>;
  nutritionists?: AsyncProcessState<API_NS.Nutritionists[]>;
}

const defaultValue: userReducerState = {
  user: undefined
}

export type severityLevels = 'success' | 'error' | 'warning' | 'info'

const resetState = JSON.parse(JSON.stringify(defaultValue))

export const userReducer: Reducer<userReducerState, TUserAction | TStateAction> = (state = defaultValue, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload
      };
    case "REQUEST_USER_FEATURES":
      return {
        ...state,
        userFeatures: { loading: true, value: undefined, message: undefined }
      };
    case "REQUEST_USER_FEATURES_SUCCESS":
      return {
        ...state,
        userFeatures: { loaded: true, loading: false, value: action.payload, message: undefined }
      };
    case "REQUEST_USER_FEATURES_FAILED":
      return {
        ...state,
        userFeatures: { value: [], loading: false, loaded: false, message: {level: 'error', msg: "We can't determine what you are able to view..."} }
      };
    
    case 'REQUEST_ALL_ACCOUNTS':
      return {
        ...state,
        accounts: { loading: true, message: undefined }
      }
    case 'REQUEST_ALL_ACCOUNTS_SUCCESS':
      return {
        ...state,
        accounts: {
          value: (action.payload || []).sort((a, b) => a.ClientCode.toLowerCase() < b.ClientCode.toLowerCase() ? -1 : 1),
          loading: false,
          loaded: true,
          message: undefined 
        }
      }
    case 'REQUEST_ALL_ACCOUNTS_FAILED':
      return {
        ...state,
        accounts: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      }

    case 'REQUEST_NUTRITIONISTS':
      return {
        ...state,
        nutritionists: { loading: true, message: undefined }
      }
    case 'REQUEST_NUTRITIONISTS_SUCCESS':
      return {
        ...state,
        nutritionists: {
          value: (action.payload || []).sort((a, b) => a.USERNAME.toLowerCase() < b.USERNAME.toLowerCase() ? -1 : 1),
          loading: false,
          loaded: true,
          message: undefined
        }
      }
    case 'REQUEST_NUTRITIONISTS_FAILED':
      return {
        ...state,
        nutritionists: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      }

    case 'CLEAR_ALL_ACCOUNTS':
      return {
        ...state,
        accounts: undefined
      }

    case 'CLEAR_NUTRITIONISTS':
      return {
        ...state,
        nutritionists: undefined
      }

    case 'RESET_STATE':
      return {
        ...resetState,
      };
  }
  return state
}
