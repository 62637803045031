import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/index.css';
import App from './pages/App';
import { reduxStore } from './store';
import * as serviceWorker from './serviceWorkerRegistration.js';
import { KADDiThemeProvider } from './lib/theme/theme.provider';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './lib/services/user-auth';
import { AppBarLoadingContextProvider } from './contexts/appbar-loading.context';
import { EnabledSecurityContextProvider } from './contexts/enable-security.context';
import { LicenseInfo } from '@mui/x-license-pro';
import { IntlProvider } from 'react-intl';
import { NumberFormatContextProvider } from './contexts/numberformat.context';
import { SnackBarContextProvider } from './contexts/snack-bar.context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';

LicenseInfo.setLicenseKey('fb68489492b9211de41419e41449e26bTz04MTAzNyxFPTE3MzQ4ODc4ODgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

localStorage.length > 1 && localStorage.clear()
const store = reduxStore

const Wrapper = () => (
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <KADDiThemeProvider>
        <EnabledSecurityContextProvider>
          <AppBarLoadingContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
              <SnackBarContextProvider>
                  <IntlProvider locale='en-GB'>
                    <NumberFormatContextProvider>
                      <Router>
                        <App />
                      </Router>
                    </NumberFormatContextProvider>
                  </IntlProvider>
                </SnackBarContextProvider>
              </LocalizationProvider>
          </AppBarLoadingContextProvider>
        </EnabledSecurityContextProvider>
      </KADDiThemeProvider>
    </MsalProvider>
  </Provider>
);

const ua = window.navigator.userAgent
const isIe = /Edg\/|Trident\/|MSIE/i.test(ua)
const isSafari = /^((?!chrome|android).)*safari/i.test(ua)

if (isSafari || isIe) {
  ReactDOM.render(<Wrapper />, document.getElementById("root"));
} else {
  if (window.location.hash !== ''){
    console.log('hash found' + window.location.hash)
  }
  else {
    ReactDOM.render(<Wrapper />, document.getElementById("root"));
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
