import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { Button, Dialog, DialogActions, DialogContent, Paper } from '@mui/material'
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { useHistory, useParams } from 'react-router'
import { intouchSelectors } from 'src/store/selectors/intouch.selector'
import { CircleTickIcon } from 'src/components/icons/circle-tick'
import { CircleIcon } from 'src/components/icons/circle'
import { ArrowLeftIcon } from 'src/components/icons/arrow-left'

export type proptypes = {}
export const defaultProps: proptypes = {}

const _ConfigExclusions: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [reportId, setReportId] = useState<number>()
  const [emailStr, setEmailStr] = useState<string>()
  const [openEmail, setOpenEmail] = useState<boolean>(false)
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const exclusions = useTypedAppSelector(s => s.intouchState.reportConfigExclusions)
  const accuracyClients = useTypedAppSelector(s => s.intouchState.accuracyClients)
  const exclusionList = useTypedAppSelector(intouchSelectors.reportExclusionsList)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { setMessage } = useContext(SnackBarContext)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const CustomGridActionsCellItem = GridActionsCellItem as any

  const columns = [
    {
      field: 'ExcludeClient',
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={p.row.ExcludeClient ? <CircleTickIcon className={`${hasAccess ? '' : 'exclusion-icon'}`} size={25} /> : <CircleIcon size={25} />}
          label='Exclude'
          onClick={() => handleClient(p.row)}
          disabled={hasAccess}
        />
      ]
    },
    {
      field: 'Client',
      headerName: 'Client',
      flex: 0.6,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1
    },
    {
      field: 'ExcludeAccountContact',
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={p.row.ExcludeAccountContact ? <CircleTickIcon className={`${p.row.ExcludeClient || hasAccess ? '' : 'exclusion-icon'}`} size={25} /> : <CircleIcon size={25} />}
          label='Exclude'
          onClick={() => toggleHandler(p.row, 'ExcludeAccountContact')}
          disabled={p.row.ExcludeClient || hasAccess}
        />
      ]
    },
    { 
      field: 'AccountEmail',
      headerName: 'Account Email',
      flex: 1,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
      renderCell: (p: GridRenderCellParams) =>
        <div style={{ width: '100%' }} onClick={() => { setEmailStr(p.value); setOpenEmail(true) }}>{p.value}</div>
    },
    {
      field: 'ExcludePrimaryContact',
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={p.row.ExcludePrimaryContact ? <CircleTickIcon className={`${p.row.ExcludeClient || hasAccess ? '' : 'exclusion-icon'}`} size={25} /> : <CircleIcon size={25} />}
          label='Exclude'
          onClick={() => toggleHandler(p.row, 'ExcludePrimaryContact')}
          disabled={p.row.ExcludeClient || hasAccess}
        />
      ]
    },
    {
      field: 'PrimaryContactEmail',
      headerName: 'Primary Email',
      flex: 1,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
      renderCell: (p: GridRenderCellParams) =>
        <div style={{ width: '100%' }} onClick={() => { setEmailStr(p.value); setOpenEmail(true) }}>{p.value}</div>
    },
    {
      field: 'ExcludeThirdPartyContact',
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={p.row.ExcludeThirdPartyContact ? <CircleTickIcon className={`${p.row.ExcludeClient || hasAccess ? '' : 'exclusion-icon'}`} size={25} /> : <CircleIcon size={25} />}
          label='Exclude'
          onClick={() => toggleHandler(p.row, 'ExcludeThirdPartyContact')}
          disabled={p.row.ExcludeClient || hasAccess}
        />
      ]
    },
    {
      field: 'ThirdPartyEmail',
      headerName: 'Third Party Email',
      flex: 1,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
      renderCell: (p: GridRenderCellParams) =>
        <div style={{ width: '100%' }} onClick={() => { setEmailStr(p.value); setOpenEmail(true) }}>{p.value}</div>
    },
    {
      field: 'ExcludeNutritionist',
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={p.row.ExcludeNutritionist ? <CircleTickIcon className={`${p.row.ExcludeClient || hasAccess ? '' : 'exclusion-icon'}`} size={25} /> : <CircleIcon size={25} />}
          label='Exclude'
          onClick={() => toggleHandler(p.row, 'ExcludeNutritionist')}
          disabled={p.row.ExcludeClient || hasAccess}
        />
      ]
    },
    {
      field: 'NutritionistEmail',
      headerName: 'Nutritionist Email',
      flex: 1,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
      renderCell: (p: GridRenderCellParams) =>
        <div style={{ width: '100%' }} onClick={() => { setEmailStr(p.value); setOpenEmail(true) }}>{p.value}</div>
    },
  ]

  const handleClient = row => {
    if (!reportId) return

    const filter = (exclusions?.value || []).find(e => e.ClientId === row.Client)
    if (row.ExcludeClient)
      filter?.ReportExclusionId && dispatch(intouchAction.deleteReportExclusion({ id: filter.ReportExclusionId }))
    else {
      filter ? dispatch(intouchAction.updateReportExclusion({
        ...filter,
        ExcludeClient: true,
        ExcludeNutritionist: true,
        ExcludeAccountContact: true,
        ExcludePrimaryContact: true,
        ExcludeThirdPartyContact: true
      }))
      : dispatch(intouchAction.addReportExclusion({
        ReportId: reportId,
        ClientId: row.Client,
        ExcludeClient: true,
        ExcludeNutritionist: true,
        ExcludeAccountContact: true,
        ExcludePrimaryContact: true,
        ExcludeThirdPartyContact: true
      }))
    }
  }

  const toggleHandler = (row, col) => {
    if (!reportId) return
    
    const filter = (exclusions?.value || []).find(e => e.ClientId === row.Client)
    if (row[col]) { // checkbox is enabled -> now disable the checkbox
      if (filter && Object.values(filter).filter(f => f === true).length > 1)
        dispatch(intouchAction.updateReportExclusion({ ...filter, [col]: !filter[col] }))
      else
        filter?.ReportExclusionId && dispatch(intouchAction.deleteReportExclusion({ id: filter.ReportExclusionId }))
    }
    else { // checkbox is disabled -> now enable the checkbox
      if (filter) dispatch(intouchAction.updateReportExclusion({ ...filter, [col]: !filter[col] }))
      else dispatch(intouchAction.addReportExclusion({
        ReportId: reportId,
        ClientId: row.Client,
        ExcludeClient: col === 'ExcludeClient' ? true : null,
        ExcludeNutritionist: col === 'ExcludeNutritionist' ? true : null,
        ExcludeAccountContact: col === 'ExcludeAccountContact' ? true : null,
        ExcludePrimaryContact: col === 'ExcludePrimaryContact' ? true : null,
        ExcludeThirdPartyContact: col === 'ExcludeThirdPartyContact' ? true : null
      }))
    }
  }

  useEffect(() => {
    setLoading(exclusions?.loading || accuracyClients?.loading ? true : false)
  }, [exclusions?.loading, accuracyClients?.loading])

  useEffect(() => {
    exclusions?.message && setMessage({ message: exclusions.message.msg, severity: exclusions.message.level, clear: () => dispatch(intouchAction.clearConfigExclusionsMessage()) })
    accuracyClients?.message && setMessage({ message: accuracyClients.message.msg, severity: accuracyClients.message.level, clear: () => dispatch(intouchAction.clearAccuracyClientsMessage()) })
  }, [exclusions?.message, accuracyClients?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 27, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    let { id } = params
    setReportId(parseInt(id))
    dispatch(intouchAction.requestReportConfigExclusions({ id: parseInt(id) }))
    dispatch(intouchAction.requestAccuracyClients())
    return () => {
      dispatch(intouchAction.clearReportConfigExclusions())
    }
  }, [])

  const emailDialog = <Dialog fullWidth open={openEmail} onClose={() => setOpenEmail(false)}>
    <DialogContent style={{ fontSize: 15 }}>{emailStr}</DialogContent>
    <DialogActions><Button size='small' onClick={() => setOpenEmail(false)}>Close</Button></DialogActions>
  </Dialog>

  return <Paper className={className}>
    <Button style={{ alignSelf: 'flex-start' }} variant='contained' color='primary' size='small' startIcon={<ArrowLeftIcon size={20} />} onClick={() => history.push({ pathname: `/intouch/ARConfig/configurations` })}>
      Back
    </Button>
    <DataGridComponent
      title=' '
      data={exclusionList}
      columns={columns as any}
      apiRef={apiRef}
      rowHeight={45}
      rowsPerPage={25}
      loading={exclusions?.loading || accuracyClients?.loading}
      defaultState={{ sorting: { sortModel: [{ field: 'Client', sort: 'asc' }] }}}
    />
    {openEmail && emailDialog}
  </Paper>
}

export const ConfigExclusionsComponent = styled(_ConfigExclusions)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  .exclusion-icon {
    color: ${p => p.theme.palette.alltech.green.main};
  }
`
export default ConfigExclusionsComponent