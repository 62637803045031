import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { useHistory, useParams } from 'react-router'
import { intouchSelectors } from 'src/store/selectors/intouch.selector'
import { CircleTickIcon } from 'src/components/icons/circle-tick'
import { CircleIcon } from 'src/components/icons/circle'
import { FilterIcon } from 'src/components/icons/filter'
import { StyledPopper } from '../../rpm/rpm-validation'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { ActiveFilterIcon } from 'src/components/icons/active-filter'
import { ArrowLeftIcon } from 'src/components/icons/arrow-left'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _ContractsInclusions: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [reportId, setReportId] = useState<number>()
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [openMarket, setOpenMarket] = useState<boolean>(false)
  const [inclusiveOnly, setInclusiveOnly] = useState<boolean>(true)
  const inclusions = useTypedAppSelector(s => s.intouchState.reportContractInclusions)
  const selectedMarket = useTypedAppSelector(s => s.intouchState.selectedMarket)
  const markets = useTypedAppSelector(s => s.intouchState.markets)
  const contractMarkets = useTypedAppSelector(s => s.intouchState.intouchContractMarkets)
  const inclusionsList = useTypedAppSelector(intouchSelectors.filteredContractList)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { setMessage } = useContext(SnackBarContext)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const marketRef = useRef<HTMLButtonElement>(null)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const params = useParams<{ id: string }>()
  const CustomGridActionsCellItem = GridActionsCellItem as any

  const columns = [
    {
      field: 'Inclusion',
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={p.row.Inclusion ? <CircleTickIcon className='inclusion-icon' size={25} /> : <CircleIcon size={25} />}
          label='Include'
          onClick={() => inclusionToggle(p.row)}
          disabled={hasAccess}
        />
      ]
    },
    {
      field: 'InTouchProductSlxId',
      headerName: 'InTouch Contract ID',
      flex: 0.6,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
    },
    {
      field: 'InTouchProductName',
      headerName: 'InTouch Contract Name',
      flex: 1,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
    },
    {
      field: 'MarketName',
      headerName: 'Market',
      flex: 0.4,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
    },
  ]

  const inclusionToggle = row => {
    if (!reportId) return
    row.Inclusion ?  dispatch(intouchAction.deleteReportInclusion({ id: row.ReportContractInclusionId }))
    : dispatch(intouchAction.addReportInclusion({ ReportId: reportId, InTouchProductSlxId: row.InTouchProductSlxId, Market: row.market }))
  }

  const headerMenu = () => <React.Fragment>
    <Tooltip title='Filter' followCursor>
      <Button className='filter-button' color='primary' ref={anchorRef} onClick={() => setOpenFilter(!openFilter)}>
       {inclusiveOnly ? <ActiveFilterIcon size={26} /> : <FilterIcon size={26} />}
      </Button>
    </Tooltip>
    <StyledPopper open={openFilter} anchorEl={anchorRef.current} placement='bottom-start' style={{ zIndex: 9999 }} transition>
      {({ TransitionProps }) => (<Grow {...TransitionProps} timeout={200}>
          <Paper sx={{ bgcolor: 'white', boxShadow: `0px 4px 8px ${theme.palette.alltech.dark_grey[50]}` }}>
              <ClickAwayListener onClickAway={() => setOpenFilter(false)}>
                  <MenuList style={{ maxHeight: 300, overflowY: 'auto', padding: '4px 0' }} variant='menu'>
                      <MenuItem dense disableRipple sx={{ pl: '12px' }} onClick={() => setInclusiveOnly(!inclusiveOnly)} selected={inclusiveOnly}>
                          {inclusiveOnly ? <CircleTickIcon size={25} /> : <CircleIcon size={25} />}&nbsp;
                          <Typography variant='body2'>Inclusions Only</Typography>
                      </MenuItem>
                  </MenuList>
              </ClickAwayListener>
          </Paper>
      </Grow>)}
    </StyledPopper>
  </React.Fragment>

  useEffect(() => {
    setLoading(markets?.loading || inclusions?.loading || contractMarkets?.loading ? true : false)
  }, [markets?.loading, inclusions?.loading, contractMarkets?.loading])

  useEffect(() => {
    markets?.message && setMessage({ message: markets.message.msg, severity: markets.message.level, clear: () => dispatch(intouchAction.clearMarketsMessage()) })
    inclusions?.message && setMessage({ message: inclusions.message.msg, severity: inclusions.message.level, clear: () => dispatch(intouchAction.clearReportContractInclusionsMessage()) })
    contractMarkets?.message && setMessage({ message: contractMarkets.message.msg, severity: contractMarkets.message.level, clear: () => dispatch(intouchAction.clearIntouchContractMarketsMessage()) })
  }, [markets?.message, inclusions?.message, contractMarkets?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 28, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    let { id } = params
    setReportId(parseInt(id))
    dispatch(intouchAction.requestReportContractInclusions({ id: id }))
    dispatch(intouchAction.requestMarkets())
    dispatch(intouchAction.requestIntouchContractMarkets())
    return () => {
      dispatch(intouchAction.clearReportContractInclusions())
    }
  }, [])

  return <Paper className={className}>
    <Button style={{ alignSelf: 'flex-start' }} variant='contained' color='primary' size='small' startIcon={<ArrowLeftIcon size={20} />} onClick={() => history.push({ pathname: `/intouch/ARConfig/contracts` })}>
      Back
    </Button>
    {(markets?.value || []).length > 0 ? <div className='market-container'>
      <Typography style={{ fontSize: 11, visibility: (selectedMarket?.Key || '').length > 0 ? 'visible' : 'hidden' }}>Choose Market</Typography>
      <Button
        className='market-dropdown'
        ref={marketRef}
        color='secondary'
        variant='outlined'
        size='small'
        onClick={() => setOpenMarket(!openMarket)}
        endIcon={openMarket ? <ArrowDropUp /> : <ArrowDropDown />}
      >
        {(selectedMarket?.Value || '').length > 0 ? selectedMarket?.Value : 'Choose Market'}
      </Button>
      <StyledPopper open={openMarket} anchorEl={marketRef.current} placement='bottom-start' style={{ minWidth: anchorRef.current?.offsetWidth || '100%' }} transition>
        {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
          <Paper sx={{ bgcolor: 'white' }}>
            <ClickAwayListener onClickAway={() => setOpenMarket(false)}>
              <MenuList autoFocusItem={openMarket} style={{ maxHeight: 300, overflowY: 'auto', padding: '4px 0' }} variant='menu'>
                {(markets?.value || []).map((m, i) => <MenuItem key={i+1} value={m.Key} onClick={() => { dispatch(intouchAction.setSelectedMarket(m)); setOpenMarket(false) }} selected={(selectedMarket?.Key || '') === m.Key}>
                  {m.Value}
                </MenuItem>)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>}
      </StyledPopper>
    </div> : undefined}
    <DataGridComponent
      title=' '
      data={inclusiveOnly ? inclusionsList.filter(inc => inc.Inclusion) : inclusionsList}
      columns={columns as any}
      apiRef={apiRef}
      rowHeight={45}
      rowsPerPage={100}
      headerMenu={headerMenu}
      loading={inclusions?.loading || contractMarkets?.loading || markets?.loading}
      defaultState={{ sorting: { sortModel: [{ field: 'InTouchProductSlxId', sort: 'asc' }] }}}
    />
  </Paper>
}

export const ContractsInclusionsComponent = styled(_ContractsInclusions)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  .inclusion-icon {
    color: ${p => p.theme.palette.alltech.green.main};
  }
  .market-container {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-bottom: 16px;
  }
  .market-dropdown {
    display: flex;
    justify-content: space-between;
    text-transform: none;
    font-size: 14px;
    padding-right: 14px;
    min-width: 150px;
  }
  .filter-button {
    border-radius: 20px;
  }
`
export default ContractsInclusionsComponent