import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { NavBar } from 'src/components/navBar/navbar'
import { adminAction } from 'src/store/actions/admin.actions'
import styled from 'styled-components'
import RoleManagementComponent from './role-management'
import UserManagementComponent from './user-management'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _UserRolesSection: React.FC<proptypes & { className?: string }> = (props) => {
  const { path } = useRouteMatch()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(adminAction.requestAllUsers())
    dispatch(adminAction.requestRoles())
  }, [])

  return <div className={props.className}>
    <ErrorBoundary page="user roles tabs">
      <NavBar className='nav-bar' tabs={[
        {
          title: 'Role Management',
          to: `${path}/roles`,
        },
        {
          title: 'User Management',
          to: `${path}/users`,
        }
      ]} />
    </ErrorBoundary>
    <Switch>
      <Route path={`${path}/roles`} render={() => <ErrorBoundary page='role management'><RoleManagementComponent /></ErrorBoundary>}></Route>
      <Route path={`${path}/users`} render={() => <ErrorBoundary page='user management'><UserManagementComponent /></ErrorBoundary>}></Route>
      <Redirect to={`${path}/roles`} />
    </Switch>
  </div>
}

export const UserRolesSectionComponent = styled(_UserRolesSection)`
  width: 100%;
  .nav-bar {
    height: 34px;
    margin-top: 0;
  }
`
export default UserRolesSectionComponent