

import React from 'react'
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

export type proptypes = TablePaginationActionsProps

export const defaultProps: Partial<proptypes> = {
  count: 10,
  onPageChange: () => { },
  rowsPerPage: 10,
  page: 0
}

const _TablePagination: React.FC<proptypes> = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageBtnClick = (event) => onPageChange(event, 0);
  const handleBackBtnClick = (event) => onPageChange(event, page - 1);
  const handleNextBtnClick = (event) => onPageChange(event, page + 1);
  const handleLastPageBtnClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  const maxPageCount = page >= Math.ceil(count / rowsPerPage) - 1

  return <div style={{ flexShrink: 0 }}>
    <IconButton
      data-cy='first-page'
      onClick={handleFirstPageBtnClick}
      disabled={page === 0}
      title='First Page'
      size='large'
    >
      <FirstPage />
    </IconButton>
    <IconButton
      data-cy='previous-page'
      onClick={handleBackBtnClick}
      disabled={page === 0}
      title='Previous Page'
      size='large'
    >
      <KeyboardArrowLeft />
    </IconButton>
    <IconButton
      data-cy='next-page'
      onClick={handleNextBtnClick}
      disabled={maxPageCount}
      title='Next Page'
      size='large'
    >
      <KeyboardArrowRight />
    </IconButton>
    <IconButton
      data-cy='last-page'
      onClick={handleLastPageBtnClick}
      disabled={maxPageCount}
      title='Last Page'
      size='large'
    >
      <LastPage />
    </IconButton>
  </div>
}

export const TablePaginationComponent = styled(_TablePagination)``