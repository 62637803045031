import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Button, ClickAwayListener, Grow, Input, lighten, ListItemText, MenuItem, MenuList, Paper, Popper } from '@mui/material'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { useTypedAppSelector } from 'src/store'

export type proptypes = {
  timezone: string;
  setTimezone?: any;
}
export const defaultProps: proptypes = {
  timezone: ''
}

const StyledPopper = styled(Popper)`
  z-index: 9999;
  .Mui-selected{
    background-color: ${p => p.theme.palette.lightGrey[60]}
  }
  .Mui-selected:hover{
    background-color: ${p => p.theme.palette.lightGrey.main}
  }
  .clear-selection {
    padding: 8px 18px;
    background-color: ${p => lighten(p.theme.palette.lightGrey[50], 0.3)};
  }
  .timezone-search {
    width: 100%;
    padding: 4px 0;
    font-size: 14px;
    background-color: ${p => lighten(p.theme.palette.lightGrey[50], 0.3)};
    &:after, &:hover:before {
      border-bottom: 1px solid black;
    }
  }
`

const _TimezoneSelector: React.FC<proptypes & { className?: string }> = (props) => {
  const { className, timezone, setTimezone } = props
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState<boolean>(false)
  const [filteredTz, setFilteredTz] = useState<string[]>()
  const [timezones, setTimezones] = useState<string[]>()
  const anchorRef = useRef<HTMLButtonElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)
  const tz = (Intl as any).supportedValuesOf('timeZone') as string[]
  const regex = /^(Pacific|Africa|America|Antarctica|Asia|Australia|Europe|Indian|Atlantic)\/([\w|-]*)(\/([\w|-]*))?$/g

  const onChangeHandler = val => {
    if (timezone === val) return
    setTimezone(val)
    setOpen(false)
    setSearch('')
  }
  
  useEffect(() => {
    searchRef?.current?.focus()
  }, [filteredTz])

  useEffect(() => {
    setFilteredTz((timezones || []).filter(t => t.toLowerCase().includes(search.toLowerCase())))
  }, [search, timezones])

  useEffect(() => {
    const filtered = tz.filter(t => regex.test(t))
    setTimezones(filtered)
  }, [])

  return <div className={className}>
    <Button
      className='timezone-button'
      id='timezone-button'
      data-cy='timezone-button'
      ref={anchorRef}
      color='secondary'
      variant='outlined'
      size='large'
      onClick={() => setOpen(!open)}
      endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
    >
      {timezone?.length > 0 ? timezone.replace('_', ' ') : 'Timezone'}
    </Button>
    <StyledPopper
      open={open}
      anchorEl={anchorRef.current}
      placement='bottom-start'
      style={{ minWidth: anchorRef.current?.offsetWidth || '100%' }}
      transition
    >
      {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
        <Paper sx={{ bgcolor: 'white' }}>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
              <Input
                className='timezone-search'
                inputRef={searchRef}
                type='search'
                placeholder='Search Timezones'
                defaultValue={search}
                onChange={e => setSearch(e.target.value)}
                inputProps={{ style: { padding: '5px 0 5px 16px' } }}
              />
              <MenuList id='timezone-menu' style={{ maxHeight: 300, overflowY: 'auto', paddingTop: 0 }} autoFocusItem={open} variant='selectedMenu'>
                {(filteredTz || []).map((t, i) => <MenuItem key={i} value={t} onClick={() => onChangeHandler(t)} selected={timezone === t}>
                    <ListItemText primaryTypographyProps={{ fontSize: '13px' }} primary={t.replace('_', ' ')} />
                  </MenuItem>
                )}
              </MenuList>
            </div>
          </ClickAwayListener>
        </Paper>
      </Grow>}
    </StyledPopper>
  </div>
}

export const TimezoneSelectorComponent = styled(_TimezoneSelector)`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
  .timezone-button {
    text-transform: none;
    font-size: 14px;
    padding: 4px 12px 4px 14px;
  }
`
export default TimezoneSelectorComponent