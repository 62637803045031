import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { Paper, Switch, Typography } from '@mui/material'
import { GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { reportsAction } from 'src/store/actions/reports.actions'

export type proptypes = {}
export const defaultProps: proptypes = {}

const _ProductMaintenance: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { setMessage } = useContext(SnackBarContext)
  const allProducts = useTypedAppSelector(s => s.reportsState.allProducts)
  const updateProductLoading = useTypedAppSelector(s => s.reportsState.updateProductLoading)
  const dispatch = useDispatch()
  const apiRef = useGridApiRef()

  const updateSelectable = (row, val) => dispatch(reportsAction.updateAlltechProduct({ AlltechProductGroupId: row.AlltechProductGroupId, IsSelectable: val }))
  const columns = [
    {
      field: 'AlltechProductGroupName',
      headerName: 'Name',
      flex: 1,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1
    },
    {
      field: 'AlltechProductGroupCode',
      headerName: 'Code',
      flex: 0.3,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1
    },
    {
      field: 'IsSelectable',
      headerName: 'Selectable?',
      flex: 0.3,
      minWidth: 50,
      sortable: false,
      headerAlign: 'center',
      renderCell: (p: GridRenderCellParams) => <div style={{ width: '100%', textAlign: 'center' }}>
        <Typography variant='caption'>No</Typography>
        <Switch
          disabled={hasAccess}
          checked={p.value}
          onClick={e => e.stopPropagation()}
          onChange={e => updateSelectable(p.row, e.target.checked)}
          value={p.value}
          color='primary'
        />
        <Typography variant='caption'>Yes</Typography>
      </div>
    }
  ]

  useEffect(() => {
    setLoading(allProducts?.loading || updateProductLoading ? true : false)
  }, [allProducts?.loading, updateProductLoading])

  useEffect(() => {
    allProducts?.message && setMessage({ message: allProducts.message.msg, severity: allProducts.message.level, clear: () => dispatch(reportsAction.clearAllProductsMessage()) })
  }, [allProducts?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('reports', 31, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    dispatch(reportsAction.requestAllProducts())
  }, [])

  return <Paper className={className}>
    <DataGridComponent
      title=''
      data={(allProducts?.value || []).map((a, i) => ({ ...a, id: i }))}
      columns={columns as any}
      apiRef={apiRef}
      rowHeight={45}
      rowsPerPage={25}
      loading={allProducts?.loading || updateProductLoading}
      defaultState={{ sorting: { sortModel: [{ field: 'AlltechProductGroupName', sort: 'asc' }] }}}
    />
  </Paper>
}

export const ProductMaintenanceComponent = styled(_ProductMaintenance)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
`
export default ProductMaintenanceComponent