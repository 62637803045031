import React, { useContext } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { NavBar } from 'src/components/navBar/navbar'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import AccuracyReportConfigComponent from './accuracy-report-config'
import AccuracyReportContractsComponent from './accuracy-report-contracts'
import ContractsInclusionsComponent from './contracts-inclusions'
import ConfigExclusionsComponent from './config-exclusions'
import ConfigInclusionsComponent from './config-inclusions'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _AccuracyReportSection: React.FC<proptypes & { className?: string }> = (props) => {
  const { path } = useRouteMatch()
  const { enabledSecurity } = useContext(EnabledSecurityContext)

  return <div className={props.className}>
    <ErrorBoundary page="accuracy report tabs">
      <NavBar className='nav-bar' tabs={[
        {
          title: 'Configurations',
          to: `${path}/configurations`,
        },
        {
          title: 'Contracts',
          to: `${path}/contracts`,
        }
      ]} />
    </ErrorBoundary>
    <Switch>
      {sortSubFeatures('intouch', 27, enabledSecurity) && <Route path={`${path}/configurations/inclusions/:id/`} render={() => <ConfigInclusionsComponent />}></Route>}
      {sortSubFeatures('intouch', 27, enabledSecurity) && <Route path={`${path}/configurations/exclusions/:id/`} render={() => <ConfigExclusionsComponent />}></Route>}
      {sortSubFeatures('intouch', 27, enabledSecurity) && <Route path={`${path}/configurations`} render={() => <ErrorBoundary page='configurations'><AccuracyReportConfigComponent /></ErrorBoundary>}></Route>}
      
      {sortSubFeatures('intouch', 28, enabledSecurity) && <Route path={`${path}/contracts/:id/`} render={() => <ContractsInclusionsComponent />}></Route>}
      {sortSubFeatures('intouch', 28, enabledSecurity) && <Route path={`${path}/contracts`} render={() => <ErrorBoundary page='contracts'><AccuracyReportContractsComponent /></ErrorBoundary>}></Route>}
    </Switch>
  </div>
}

export const AccuracyReportSectionComponent = styled(_AccuracyReportSection)`
  width: 100%;
  .nav-bar {
    height: 34px;
    margin-top: 0;
  }
`
export default AccuracyReportSectionComponent