import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { Button, Checkbox, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { intouchSelectors } from 'src/store/selectors/intouch.selector'

export type proptypes = {
  rpmId?: number;
  setOpenConfig?: (arg: boolean) => void;
}
export const defaultProps: proptypes = {
}

const _AutoRpmConfig: React.FC<proptypes & { className?: string }> = (props) => {
  const { className, rpmId, setOpenConfig, } = props
  const autoRpmConfigs = useTypedAppSelector(s => s.intouchState.autoRpmConfigs)
  const feedPens = useTypedAppSelector(s => s.intouchState.feedPens)
  const milkPens = useTypedAppSelector(s => s.intouchState.milkPens)
  const sortedMilkPens = useTypedAppSelector(intouchSelectors.sortedMilkPens)
  const sortedFeedPens = useTypedAppSelector(intouchSelectors.sortedFeedPens)
  const checkDuplicate = useTypedAppSelector(intouchSelectors.checkDuplicate)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { setMessage } = useContext(SnackBarContext)
  const dispatch = useDispatch()

  const addExternalRpmConfig = data => dispatch(intouchAction.addExternalAutoRpmConfig(data))

  const deleteRpmConfig = id => dispatch(intouchAction.deleteAutoRpmConfig({ id: id }))

  const addInternalRpmConfig = data => dispatch(intouchAction.addInternalAutoRpmConfig(data))

  const getExternalConfigId = penName => {
    return (autoRpmConfigs?.value || [])
      .filter(a => a.IsAlltechGroup === false && a.ExternalPenReference === penName)
      .map(r => r.AutoRpmConfigId)
  }

  const getIntouchConfigId = (id, penName) => {
    const filtered = (autoRpmConfigs?.value || []).filter(a => a.IsAlltechGroup === true && a.InternalPenId === id)
    const configId = (filtered || []).filter(f => f.InternalPenName === penName).map(f => f.AutoRpmConfigId)
    return configId
  }

  const feedToggleHandler = (e, penId, penName) => {
    !e.target.checked ? deleteRpmConfig(getIntouchConfigId(penId, penName)[0])
    : addInternalRpmConfig({ AutoRpmId: rpmId, IsAlltechGroup: true, InternalPenId: penId, InternalPenName: penName })
  } 

  const milkToggleHandler = (e, penName) => {
    !e.target.checked ? deleteRpmConfig(getExternalConfigId(penName)[0])
    : addExternalRpmConfig({ AutoRpmId: rpmId, ExternalPenReference: penName })
  }

  useEffect(() => {
    rpmId && dispatch(intouchAction.requestAutoRpmConfigs({ rpmId: rpmId?.toString() }))
  }, [rpmId])

  useEffect(() => {
    setLoading(autoRpmConfigs?.loading || feedPens?.loading || milkPens?.loading ? true : false)
  }, [feedPens?.loading, milkPens?.loading, autoRpmConfigs?.loading])

  useEffect(() => {
    autoRpmConfigs?.message && setMessage({ message: autoRpmConfigs.message.msg, severity: autoRpmConfigs.message.level, clear: () => dispatch(intouchAction.clearAutoRpmConfigsMessage()) })
    feedPens?.message && setMessage({ message: feedPens.message.msg, severity: feedPens.message.level, clear: () => dispatch(intouchAction.clearFeedPensMessage()) })
    milkPens?.message && setMessage({ message: milkPens.message.msg, severity: milkPens.message.level, clear: () => dispatch(intouchAction.clearMilkPensMessage()) })
  }, [autoRpmConfigs?.message, feedPens?.message, milkPens?.message])

  const tableHead = header => <TableHead>
    <TableRow>
      <TableCell align='left' padding='none'>{header}</TableCell>
      <TableCell style={{ width: '12%' }}>&nbsp;</TableCell>
    </TableRow>
  </TableHead>

  const externalTable = () => (sortedMilkPens || []).length > 0 ? <Table size='small'>
    {tableHead('External Pens')}
    <TableBody>
      {(sortedMilkPens || []).map((m, i) => <TableRow key={i}>
        <TableCell className={checkDuplicate ? 'disabled-text': ''} padding='none'>{m.PenDescription}</TableCell>
        <TableCell align="left" padding="none">
          <Checkbox color='primary' disabled={checkDuplicate} checked={m.checked} onChange={e => milkToggleHandler(e, m.PenDescription)} />
        </TableCell>
      </TableRow>)}
    </TableBody>
  </Table>
  : <div className='no-data'>No Data Available</div>

  const internalTable = () => (sortedFeedPens || []).length > 0 ? <Table size='small'>
    {tableHead('Internal Pens')}
    <TableBody>
      {(sortedFeedPens || []).map((f, i) => <TableRow key={i}>
        <TableCell className={checkDuplicate ? 'disabled-text': ''} padding='none'>{f.PenDescription}</TableCell>
        <TableCell align='left' padding='none'>
          <Checkbox color='primary' disabled={checkDuplicate} checked={f.checked} onChange={e => feedToggleHandler(e, f.PenId, f.PenDescription)} />
        </TableCell>     
      </TableRow>)}
    </TableBody>
  </Table>
  : <div className='no-data'>No Data Available</div>

  return <div className={className}>
    <div className='main-container'>
      {autoRpmConfigs?.loaded && <React.Fragment>
        <div className='pens-container'>
          <div className='external-container'>{externalTable()}</div>
          <div className='internal-container'>{internalTable()}</div>
        </div>
        <div style={{ alignSelf: 'flex-end', marginTop: 30 }}>
          <Button color='primary' variant='contained' size='small' onClick={() => setOpenConfig && setOpenConfig(false)}>
            Close
          </Button>
        </div>
      </React.Fragment>}
      {autoRpmConfigs?.loading && <div className='loading-screen'><CircularProgress size={40} /></div>}
    </div>
  </div>
}

export const AutoRpmsConfig = styled(_AutoRpmConfig)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  .main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 70vh;
    padding: 10px;
    .loading-screen {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .pens-container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      flex-grow: 1;
      column-gap: 20px;
      .external-container {
        width: 50%;
      }
      .internal-container {
        width: 50%;
      }
    }
  }
  .no-data {
    margin-top: 50px;
    text-align: center;
    font-size: 14px;
  }
  .MuiTableHead-root {
    .MuiTableCell-root {
      font-size: 12px;
    }
  }
  .MuiTableBody-root {
    .MuiTableCell-root {
      font-size: 13px;
    }
  }
  .disabled-text {
    color: ${p => p.theme.palette.alltech.dark_grey[80]}
  }
`
export default AutoRpmsConfig