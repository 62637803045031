import React, { useContext, useEffect, useState } from 'react';
import { AppBar, CssBaseline, IconButton, LinearProgress, Toolbar } from '@mui/material';
import { Menu } from '@mui/icons-material/';
import styled from 'styled-components'
import AppRoutesComponent from '../routes/app.routes';
import AccountButton from '../components/common/AccountButton';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { userAction } from 'src/store/actions/user.actions';
import { useTypedAppSelector } from 'src/store';
import { msalInstance } from 'src/lib/services/user-auth';
import LoginComponent from './sections/login/login';
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context';
import { SnackBarContext } from 'src/contexts/snack-bar.context';
import SnackbarComponent from 'src/components/common/CustomSnackBar';

export type proptypes = {}
export const defaultProps: proptypes = {}

export const _App: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const { instance, accounts } = useMsal()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const user = useTypedAppSelector(s => s.userState.user)
  const dispatch = useDispatch()
  const { loading } = useContext(AppBarLoadingContext)
  const { message } = useContext(SnackBarContext)
  const handleDrawerToggle = () => setDrawerOpen(c => !c)

  const login = user => {
    dispatch(userAction.setUser(user))
  }

  const logout = () => {
    dispatch(userAction.setUser(null))
    
    const ua = window.navigator.userAgent
    const isIe = /Edg\/|Trident\/|MSIE/i.test(ua);
    const isSafari = /^((?!chrome|android).)*safari/i.test(ua);

    if (isSafari || isIe)
      msalInstance.logoutRedirect()
    else
      msalInstance.logoutPopup()
  };

  useEffect(() => {
    accounts[0] !== null && login(accounts[0])
  }, [accounts])

  useEffect(() => {
    if (window.screen.width > 600) setDrawerOpen(true)

    if (!user) {
      const allAcc = instance.getAllAccounts()
      const msalAcc = allAcc ? allAcc[0] || null : null
      const activeMsalAcc = msalAcc || user

      if (activeMsalAcc) dispatch(userAction.setUser(activeMsalAcc))
    }
  }, [])

  return <div className={className}>
    <CssBaseline />
    <AppBar position='fixed' className='appBar'>
      <Toolbar disableGutters className='banner'>
        <div className='left-appbar'>
          {user ? <IconButton color='inherit' onClick={handleDrawerToggle}><Menu /></IconButton> : null}
          <img src='/KADDi-white.svg' alt='KADDi' style={{ marginLeft: 20, height: '100%' }} />
        </div>
        <AccountButton user={user} logout={logout} />
      </Toolbar>
      {loading && <LinearProgress style={{ width: '100%', height: 5 }} />}
    </AppBar>

    <AuthenticatedTemplate>
      <AppRoutesComponent drawerOpen={drawerOpen} onDrawerToggle={handleDrawerToggle} />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <LoginComponent msal={instance} />
    </UnauthenticatedTemplate>

    {message && <SnackbarComponent snackMessage={message?.message} severity={message?.severity} clear={message?.clear} />}
  </div>
}

export const App = styled(_App)`
  flex-grow: 1;
  min-height: 100vh;
  z-index: 1;
  position: relative;
  background-image: url('/a.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  .appBar {
    z-index: 9999;
  }
  .left-appbar {
    display: flex;
    justify-content: flex-start;
    width: 200px;
    height: 100%;
  }
  .banner {
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export default App;
