import { TRpmAction } from "../actions/rpm.actions"
import { Reducer } from "redux"
import { API_NS } from "../../lib/api/endpoints/endpoint.types"
import { TStateAction } from "../actions/actions"
import { AsyncProcessState } from "../types/shared-state-types"

export type rpmReducerState = {
  rpms?: AsyncProcessState<API_NS.Rpms[]>;
  rpmEmailState?: AsyncProcessState<string>;
  rpmEmailList?: AsyncProcessState<API_NS.RpmEmailList>;
  rpmValidationStatus?: AsyncProcessState<string>;
}

const defaultValue: rpmReducerState = {
}

const resetState = JSON.parse(JSON.stringify(defaultValue))

export const rpmReducer: Reducer<rpmReducerState, TRpmAction | TStateAction> = (state = defaultValue, action) => {
  switch (action.type) {
    case "REQUEST_CLIENT_RPMS":
      return {
        ...state,
        rpms: { loading: true, message: undefined }
      };
    case "REQUEST_CLIENT_RPMS_SUCCESS":
      return {
        ...state,
        rpms: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_CLIENT_RPMS_FAILED":
      return {
        ...state,
        rpms: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_NUTRITIONIST_RPMS":
      return {
        ...state,
        rpms: { loading: true, message: undefined }
      };
    case "REQUEST_NUTRITIONIST_RPMS_SUCCESS":
      return {
        ...state,
        rpms: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_NUTRITIONIST_RPMS_FAILED":
      return {
        ...state,
        rpms: { value: [], loading: false, loaded: false, message:  {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "UPDATE_COMMENT":
      return {
        ...state,
        rpms: { ...state.rpms, loading: true, message: undefined }
      };
    case "UPDATE_COMMENT_SUCCESS":
      return {
        ...state,
        rpms: {
          value: (state.rpms?.value || []).map(r => r.RpmId === action.payload.RpmId ? {...r, ValidatedComment: action.payload.ValidatedComment} : r),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'RPM has been updated'}
        }
      };
    case "UPDATE_COMMENT_FAILED":
      return {
        ...state,
        rpms: { ...state.rpms, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while updating RPM'} }
      };

    case "UPDATE_VALIDATION":
      return {
        ...state,
        rpmValidationStatus: { loaded: false, loading: true, value: undefined, message: undefined }
      };
    case "UPDATE_VALIDATION_SUCCESS":
      return {
        ...state,
        rpms: { value: (state.rpms?.value || []).map(r => r.RpmId === action.payload.RpmId ? {...r, IsValidated: action.payload.IsValidated} : r) },
        rpmValidationStatus: { loaded: true, loading: false, value: true, message: {level: 'success', msg: 'RPM has been updated'} }
      };
    case "UPDATE_VALIDATION_FAILED":
      return {
        ...state,
        rpmValidationStatus: { loaded: false, loading: false, value: undefined, message: {level: 'error', msg: 'A problem occurred while updating RPM'} }
      };

    case "REQUEST_RPM_EMAIL_LIST":
      return {
        ...state,
        rpmEmailList: { loaded: false, loading: true, value: undefined, message: undefined }
      };
    case "REQUEST_RPM_EMAIL_LIST_SUCCESS":
      return {
        ...state,
        rpmEmailList: { loaded: true, loading: false, value: action.payload[0], message: (action.payload[0]?.email || '').length > 0 ? undefined : {level: 'info', msg: 'RPM has no email list'} }
      };
    case "REQUEST_RPM_EMAIL_LIST_FAILED":
      return {
        ...state,
        rpmEmailList: { loaded: false, loading: false, value: undefined, message: {level: 'error', msg: action.payload} }
      };
  
    case "SEND_RPM_EMAIL":
      return {
        ...state,
        rpmEmailState: { loaded: false, loading: true, value: undefined, message: undefined }
      };
    case "SEND_RPM_EMAIL_SUCCESS":
      return {
        ...state,
        rpmEmailState: { loaded: true, loading: false, value: true, message: {level: 'success', msg: 'RPM email(s) sent'} }
      };
    case "SEND_RPM_EMAIL_FAILED":
      return {
        ...state,
        rpmEmailState: { loaded: false, loading: false, value: undefined, message: {level: 'error', msg: action.payload || 'An error occurred when sending RPM emails' } }
      };

    case "SEND_RPM_EMAIL_RESET":
      return {
        ...state,
        rpmEmailState: { loaded: false, loading: false, value: undefined, message: undefined }
      };

    case 'CLEAR_RPMS':
      return {
        ...state,
        rpms: undefined
      };
      
    case 'CLEAR_RPMS_MESSAGE':
      return {
        ...state,
        rpms: { ...state.rpms, message: undefined }
      };
      
    case "CLEAR_VALIDATION_STATUS":
      return {
        ...state,
        rpmValidationStatus: undefined,
      };

    case "CLEAR_RPM_EMAIL_LIST":
      return {
        ...state,
        rpmEmailList: [],
      };

    case 'CLEAR_RPM_EMAIL_MESSAGE':
      return {
        ...state,
        rpmEmailList: { ...state.rpmEmailList, message: undefined }
      };

    case 'RESET_STATE':
      return {
        ...resetState
      }
  }
  return state
}