import { TReportsAction } from "../actions/reports.actions"
import { Reducer } from "redux"
import { API_NS } from "../../lib/api/endpoints/endpoint.types"
import { TStateAction } from "../actions/actions"
import { AsyncProcessState } from "../types/shared-state-types"

export type reportsReducerState = {
  reports?: AsyncProcessState<API_NS.Reports[]>;
  countries?: AsyncProcessState<API_NS.Countries[]>;
  translations?: AsyncProcessState<API_NS.Translations[]>;
  defaultTranslations?: AsyncProcessState<API_NS.Translations[]>;
  translationElements?: AsyncProcessState<{FieldName: string}[]>;
  submitTranslationsLoading?: boolean;
  allProducts?: AsyncProcessState<API_NS.AlltechProduct[]>;
  updateProductLoading?: boolean;
  alltechProducts?: AsyncProcessState<API_NS.AlltechProduct[]>;
  productUoms?: AsyncProcessState<API_NS.ProductUom[]>;
  allProductUsage?: AsyncProcessState<API_NS.ProductUsage[]>;
  upsertProductUsageLoading?: boolean;
  deleteProductUsageLoading?: boolean;
}

const defaultValue: reportsReducerState = {
}

const resetState = JSON.parse(JSON.stringify(defaultValue))

export const reportsReducer: Reducer<reportsReducerState, TReportsAction | TStateAction> = (state = defaultValue, action) => {
  switch (action.type) {
    case "REQUEST_REPORTS":
      return {
        ...state,
        reports: { loading: true, message: undefined }
      };
    case "REQUEST_REPORTS_SUCCESS":
      return {
        ...state,
        reports: {
          value: action.payload.sort((a, b) => a.ReportName.toLowerCase() > b.ReportName.toLowerCase() ? 1 : -1),
          loading: false,
          loaded: true,
          message: undefined
        }
      };
    case "REQUEST_REPORTS_FAILED":
      return {
        ...state,
        reports: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_COUNTRIES":
      return {
        ...state,
        countries: { loading: true, message: undefined }
      };
    case "REQUEST_COUNTRIES_SUCCESS":
      return {
        ...state,
        countries: {
          value: action.payload.filter(a => a.LanguageCode !== null).sort((a, b) => a.CountryName.toLowerCase() > b.CountryName.toLowerCase() ? 1 : -1),
          loading: false,
          loaded: true,
          message: undefined
        }
      };
    case "REQUEST_COUNTRIES_FAILED":
      return {
        ...state,
        countries: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_TRANSLATIONS":
      return {
        ...state,
        translations: { loading: true, message: undefined }
      };
    case "REQUEST_TRANSLATIONS_SUCCESS":
      return {
        ...state,
        translations: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_TRANSLATIONS_FAILED":
      return {
        ...state,
        translations: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_DEFAULT_TRANSLATIONS":
      return {
        ...state,
        defaultTranslations: { loading: true, message: undefined }
      };
    case "REQUEST_DEFAULT_TRANSLATIONS_SUCCESS":
      return {
        ...state,
        defaultTranslations: { value: action.payload, loading: false, loaded: true, message: undefined }
      };
    case "REQUEST_DEFAULT_TRANSLATIONS_FAILED":
      return {
        ...state,
        defaultTranslations: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_TRANSLATIONS_ELEMENTS":
      return {
        ...state,
        translationElements: { loading: true, message: undefined }
      };
    case "REQUEST_TRANSLATIONS_ELEMENTS_SUCCESS":
      return {
        ...state,
        translationElements: {
          value: action.payload.sort((a, b) => a.FieldName.toLowerCase() > b.FieldName.toLowerCase() ? 1 : -1),
          loading: false,
          loaded: true,
          message: undefined
        }
      };
    case "REQUEST_TRANSLATIONS_ELEMENTS_FAILED":
      return {
        ...state,
        translationElements: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "SUBMIT_TRANSLATIONS":
      return {
        ...state,
        translations: { ...state.translations, message: undefined },
        submitTranslationsLoading: true
      };
    case "SUBMIT_TRANSLATIONS_SUCCESS":
      return {
        ...state,
        translations: { ...state.translations, value: action.payload, message: {level: 'success', msg: 'Translations has been updated'} },
        submitTranslationsLoading: false
      };
    case "SUBMIT_TRANSLATIONS_FAILED":
      return {
        ...state,
        translations: { ...state.translations, message: {level: 'error', msg: 'A problem occurred while updating translations'} },
        submitTranslationsLoading: false
      };

    case "REQUEST_ALL_PRODUCTS":
      return {
        ...state,
        allProducts: { loading: true, message: undefined }
      };
    case "REQUEST_ALL_PRODUCTS_SUCCESS":
      return {
        ...state,
        allProducts: {
          value: action.payload.sort((a, b) => a.AlltechProductGroupName.toLowerCase() > b.AlltechProductGroupName.toLowerCase() ? 1 : -1),
          loading: false,
          loaded: true,
          message: undefined
        }
      };
    case "REQUEST_ALL_PRODUCTS_FAILED":
      return {
        ...state,
        allProducts: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_PRODUCTS_LIST":
      return {
        ...state,
        alltechProducts: { loading: true, message: undefined }
      };
    case "REQUEST_PRODUCTS_LIST_SUCCESS":
      return {
        ...state,
        alltechProducts: {
          value: action.payload.sort((a, b) => a.AlltechProductGroupName.toLowerCase() > b.AlltechProductGroupName.toLowerCase() ? 1 : -1),
          loading: false,
          loaded: true,
          message: undefined
        }
      };
    case "REQUEST_PRODUCTS_LIST_FAILED":
      return {
        ...state,
        alltechProducts: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "UPDATE_ALLTECH_PRODUCT":
      return {
        ...state,
        allProducts: { ...state.allProducts, message: undefined },
        updateProductLoading: true
      };
    case "UPDATE_ALLTECH_PRODUCT_SUCCESS":
      return {
        ...state,
        allProducts: {
          ...state.allProducts,
          value: (state.allProducts?.value || []).map(a => a.AlltechProductGroupId === action.payload.AlltechProductGroupId ? { ...a, IsSelectable: action.payload.IsSelectable} : a),
          message: {level: 'success', msg: 'Alltech product has been updated'}
        },
        updateProductLoading: false
      };
    case "UPDATE_ALLTECH_PRODUCT_FAILED":
      return {
        ...state,
        allProducts: { ...state.allProducts, message: {level: 'error', msg: 'A problem occurred while updating Alltech product'} },
        updateProductLoading: false
      };
    
    case "REQUEST_PRODUCT_UOM":
      return {
        ...state,
        productUoms: { loading: true, message: undefined }
      };
    case "REQUEST_PRODUCT_UOM_SUCCESS":
      return {
        ...state,
        productUoms: {
          value: action.payload.sort((a, b) => a.AlltechProductGroupUoMId > b.AlltechProductGroupUoMId ? 1 : -1),
          loading: false,
          loaded: true,
          message: undefined
        }
      };
    case "REQUEST_PRODUCT_UOM_FAILED":
      return {
        ...state,
        productUoms: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_ALL_PRODUCT_USAGE":
      return {
        ...state,
        allProductUsage: { loading: true, message: undefined }
      };
    case "REQUEST_ALL_PRODUCT_USAGE_SUCCESS":
      return {
        ...state,
        allProductUsage: {
          value: action.payload.sort((a, b) => a.Username.toLowerCase() > b.Username.toLowerCase() ? 1 : -1),
          loading: false,
          loaded: true,
          message: undefined
        }
      };
    case "REQUEST_ALL_PRODUCT_USAGE_FAILED":
      return {
        ...state,
        allProductUsage: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "UPSERT_ALL_PRODUCT_USAGE":
      return {
        ...state,
        allProductUsage: { ...state.allProductUsage, message: undefined },
        upsertProductUsageLoading: true
      };
    case "UPSERT_ALL_PRODUCT_USAGE_SUCCESS":
      return {
        ...state,
        allProductUsage: { ...state.allProductUsage, message: {level: 'success', msg: 'Alltech product usage record has been added/updated'} },
        upsertProductUsageLoading: false
      };
    case "UPSERT_ALL_PRODUCT_USAGE_FAILED":
      return {
        ...state,
        allProductUsage: { ...state.allProductUsage, message: {level: 'error', msg: 'A problem occurred while adding/updating Alltech product usage record'} },
        upsertProductUsageLoading: false
      };

    case "DELETE_PRODUCT_USAGE":
      return {
        ...state,
        allProductUsage: { ...state.allProductUsage, message: undefined },
        deleteProductUsageLoading: true
      };
    case "DELETE_PRODUCT_USAGE_SUCCESS":
      return {
        ...state,
        allProductUsage: {
          ...state.allProductUsage,
          value: (state.allProductUsage?.value || []).filter(a => a.AlltechProductGroupUsageId !== action.payload),
          message: {level: 'success', msg: 'Alltech product usage record has been deleted'}
        },
        deleteProductUsageLoading: false
      };
    case "DELETE_PRODUCT_USAGE_FAILED":
      return {
        ...state,
        allProductUsage: { ...state.allProductUsage, message: {level: 'error', msg: 'A problem occurred while deleting Alltech product usage record'} },
        deleteProductUsageLoading: false
      };

    case 'CLEAR_REPORTS_MESSAGE':
      return {
        ...state,
        reports: { ...state.reports, message: undefined }
      };
      
    case 'CLEAR_COUNTRIES_MESSAGE':
      return {
        ...state,
        countries: { ...state.countries, message: undefined }
      };
    
    case 'CLEAR_TRANSLATIONS_MESSAGE':
      return {
        ...state,
        translations: { ...state.translations, message: undefined }
      };
      
    case 'CLEAR_DEFAULT_TRANSLATIONS_MESSAGE':
      return {
        ...state,
        defaultTranslations: { ...state.defaultTranslations, message: undefined }
      };
  
    case 'CLEAR_TRANSLATION_ELEMENTS_MESSAGE':
      return {
        ...state,
        translationElements: { ...state.translationElements, message: undefined }
      };

    case 'CLEAR_ALL_PRODUCTS_MESSAGE':
      return {
        ...state,
        allProducts: { ...state.allProducts, message: undefined }
      };
  
    case 'CLEAR_ALLTECH_PRODUCTS_MESSAGE':
      return {
        ...state,
        alltechProducts: { ...state.alltechProducts, message: undefined }
      };

    case 'CLEAR_PRODUCT_UOM_MESSAGE':
      return {
        ...state,
        productUoms: { ...state.productUoms, message: undefined }
      };
      
    case 'CLEAR_ALL_PRODUCT_USAGE_MESSAGE':
      return {
        ...state,
        allProductUsage: { ...state.allProductUsage, message: undefined }
      };

    case 'RESET_STATE':
      return {
        ...resetState,
      };
  }
  return state
}
