import React, { Component, ErrorInfo, ReactNode } from "react";
import { Paper, Typography } from "@mui/material";

interface Props { page: string, className?: string, children: ReactNode; }

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {

  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { message, stack } = error
    console.error(message, stack)
  }

  render() {
    let { className, page } = this.props;
    if (this.state.hasError) {
      return (
        <div className={className} >
          <Paper style={{ maxWidth: 500 }}>
            <Typography variant="h6" >Oops....Something went wrong with the {page}.</Typography>
          </Paper>
        </div>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
