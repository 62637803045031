import React from 'react'

export const CircleLineIcon = (props: React.SVGProps<SVGSVGElement> & { size?: number | string }) => (
    <svg {...props} className={props.className} width={props.size || 30} height={props.size || 30} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke="CurrentColor"
            strokeWidth={props.strokeWidth || "3"}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M37.5 24.9951C37.5 33.6246 30.5044 40.6201 21.875 40.6201C13.2456 40.6201 6.25 33.6246 6.25 24.9951C6.25 16.3657 13.2456 9.37012 21.875 9.37012C30.5044 9.37012 37.5 16.3657 37.5 24.9951Z" />
        <path stroke="CurrentColor"
            strokeWidth={props.strokeWidth || "3"}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 25.5C26 25.5 31.1667 25.5 31.5 25.5" />
    </svg>
)