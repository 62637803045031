import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { Paper, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { useTypedAppSelector } from 'src/store'
import { salesAction } from 'src/store/actions/sales.actions'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { DatePicker } from '@mui/x-date-pickers'
import { categoriesSelection, marketsSelection, StaffSelection } from './sales-targets-components'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import moment from 'moment'
import { FormattedNumber } from 'react-intl'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _SalesTargets: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [selectedYear, setSelectedYear] = useState<Date>(new Date())
  const [selectedMarket, setSelectedMarket] = useState<{Key: string | null, Value: string}>({Key: null, Value: ''})
  const [selectedCategory, setSelectedCategory] = useState<{id: number | null, name: string}>({id: null, name: ''})
  const [selectedStaff, setSelectedStaff] = useState<API_NS.SalesStaff>()
  const [targetsList, setTargetsList] = useState<API_NS.SalesTargetReq[]>()
  const [total, setTotal] = useState<number>(0)
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [openCategory, setOpenCategory] = useState<boolean>(false)
  const [openMarket, setOpenMarket] = useState<boolean>(false)
  const [openStaff, setOpenStaff] = useState<boolean>(false)
  const markets = useTypedAppSelector(s => s.intouchState.markets)
  const targetCategories = useTypedAppSelector(s => s.salesState.targetCategories)
  const salesStaffs = useTypedAppSelector(s => s.salesState.salesStaffs)
  const targets = useTypedAppSelector(s => s.salesState.salesTargets)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const categoryRef = useRef<HTMLButtonElement>(null)
  const marketRef = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()
  const months = moment.monthsShort()

  const categoryHandler = c => { setOpenCategory(false); setSelectedCategory(c) }
  const marketHandler = m => { setOpenMarket(false); setSelectedMarket(m) }

  const calculateTotal = list => setTotal(list.reduce((p, c) => { return p + c?.TargetValue }, 0))

  const convertTargets = (list: API_NS.SalesTarget[], staff: API_NS.SalesStaff) => {
    const _list = list.filter(l => l?.UserId === staff?.USERID)
    const convert = _list.map(l => ({
      CategorisedTargetId: null,
      UserId: l?.UserId,
      Market: l?.Market,
      CategoryId: l?.TargetCategoryId,
      TargetValue: l?.TargetValue,
      Month: l?.TargetMonth,
      Year: l?.TargetYear
    }))

    return convert.reduce((p: any, c) => {
      if (p?.find(p => p.Month === c.Month))
        return p.map(x => { return x.Month === c.Month ? {...x, TargetValue: c.TargetValue} : x })
      else
        return [...p, c]
    }, [])
  }

  const staffHandler = s => {
    openStaff && setOpenStaff(false)
    setSelectedStaff(s)
    setTargetsList(convertTargets(targets?.value || [], s))
  }

  const findTargetValue = month => {
    const target = (targetsList || []).find(t => t.Month === month)
    return target ? target.TargetValue : 0
  }

  const targetValueHandler = (number, month) => {
    const num = isNaN(number) ? 0 : number

    const update = (targetsList || []).find(t => t.Month === month)

    if (update)
      setTargetsList((targetsList || []).map(t => { return t.Month === month ? {...t, TargetValue: num} : t }))
    else {
      if (!selectedCategory?.id || !selectedMarket?.Key || !selectedYear || !selectedStaff?.USERID) return

      setTargetsList([...(targetsList || []), {
        CategorisedTargetId: null,
        UserId: selectedStaff.USERID,
        Market: selectedMarket.Key,
        CategoryId: selectedCategory.id,
        TargetValue: num,
        Month: month,
        Year: selectedYear.getFullYear()
      }])
    }
  }

  const saveSalesTargets = month => {
    if (!selectedStaff?.USERID) return
    const list = convertTargets(targets?.value || [], selectedStaff)
    const before = list.find(l => l.Month === month)
    const update = (targetsList || []).find(t => t.Month === month)
    if (before?.TargetValue !== update?.TargetValue)
      update && dispatch(salesAction.addSalesTarget(update))
  }

  useEffect(() => {
    calculateTotal(targetsList || [])
  }, [targetsList])

  useEffect(() => {
    setTargetsList(undefined)
  }, [selectedCategory?.name, selectedMarket?.Value, selectedYear])

  useEffect(() => {
    if (selectedYear && selectedCategory?.name && selectedCategory?.id && selectedMarket?.Key && selectedMarket?.Value) {
      dispatch(salesAction.requestSalesTargets({ targetCategoryId: selectedCategory.id, market: selectedMarket.Key, year: selectedYear.getFullYear() }))
      if (selectedStaff) staffHandler(selectedStaff)
    }
  }, [selectedCategory, selectedMarket, selectedYear])

  useEffect(() => {
    setLoading(markets?.loading || targetCategories?.loading || salesStaffs?.loading || targets?.loading ? true : false)
  }, [markets?.loading, targetCategories?.loading, salesStaffs?.loading, targets?.loading])

  useEffect(() => {
    markets?.message && setMessage({ message: markets.message.msg, severity: markets.message.level, clear: () => dispatch(intouchAction.clearMarketsMessage()) })
    targetCategories?.message && setMessage({ message: targetCategories.message.msg, severity: targetCategories.message.level, clear: () => dispatch(salesAction.clearTargetCategoriesMessage()) })
    salesStaffs?.message && setMessage({ message: salesStaffs.message.msg, severity: salesStaffs.message.level, clear: () => dispatch(salesAction.clearSalesStaffsMessage()) })
    targets?.message && setMessage({ message: targets.message.msg, severity: targets.message.level, clear: () => dispatch(salesAction.clearSalesTargetsMessage()) })
  }, [markets?.message, targetCategories?.message, salesStaffs?.message, targets?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 24, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    dispatch(intouchAction.requestMarkets())
    dispatch(salesAction.requestSalesStaffs())
    dispatch(salesAction.requestTargetCategories())
    return () => {
      setLoading(false)
    }
  }, [])

  const infoText = <div className='info-text'>
    <Typography variant='subtitle2' style={{ marginBottom: 20 }}>
      Make selections for all required fields at the top.
    </Typography>
    <Typography variant='subtitle2' style={{ marginBottom: 20 }}>
      When adding or changing targets, it is important to note that changes are saved automatically when you click outside the text box after changing values.
    </Typography>
    <Typography variant='subtitle2' style={{ marginBottom: 20 }}>
      Values can be set back to zero if you wish to undo your changes.
    </Typography>
  </div>
  
  return <Paper className={className}>
    <div className='selection-container'>
      <div>
        <Typography style={{ fontSize: 11, visibility: selectedYear ? 'visible' : 'hidden' }}>Year*</Typography>
        <DatePicker
          views={['year']}
          value={selectedYear}
          onChange={(newValue) => newValue && setSelectedYear(newValue)}
          renderInput={(params) => <TextField className='date-textfield' {...params} size='small' color='secondary' placeholder='Year' />}
        />
      </div>
      {categoriesSelection(targetCategories, selectedCategory, categoryHandler, categoryRef, openCategory, setOpenCategory, targetCategories?.loading)}
      {marketsSelection(markets, selectedMarket, marketHandler, marketRef, openMarket, setOpenMarket, markets?.loading)}
      {targets?.loaded && <StaffSelection className='staff-select' open={openStaff} setOpen={setOpenStaff} selectedStaff={selectedStaff} staffHandler={staffHandler} />}
    </div>
    {targets?.loaded && <div className='reminder'>
      <Typography style={{ fontWeight: 500, fontSize: 11 }}>
        Reminder - target values are saved automatically when clicking outside the text box after changing the value
    </Typography>
    </div>}
    {selectedCategory?.name && selectedYear && selectedMarket?.Value ? <div className='layout'>
      <Table>
        <TableBody>
          <TableRow>
            {targetsList && months.map((m, i) => <TableCell key={i} style={{ padding: '0 4px' }}>
              <TextField
                inputProps={{ style: { fontSize: 14, minWidth: 70 } }}
                label={m}
                size='small'
                margin='dense'
                variant='standard'
                type='tel'
                value={findTargetValue(i + 1)}
                disabled={hasAccess}
                onChange={e => targetValueHandler(parseFloat(e.target.value), i + 1)}
                onBlur={() => saveSalesTargets(i + 1)}
              />
            </TableCell>)}
            {targetsList && <TableCell className='total-value' align='left'>
              <div style={{ fontSize: 12, lineHeight: 2.3 }}>Total</div>
              <FormattedNumber value={total || 0} style='currency' currency='EUR' maximumFractionDigits={2} minimumFractionDigits={0} />
            </TableCell>}
          </TableRow>
        </TableBody>
      </Table>
    </div>
    : infoText}
  </Paper>
}

export const SalesTargetsComponent = styled(_SalesTargets)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 60vh;
  padding: 20px 16px;
  .selection-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: fit-content;
    column-gap: 10px;
    .staff-select {
      align-items: flex-start;
      width: fit-content !important;
    }
  }
  .selection-form {
    display: flex;
    flex-direction: column;
  }
  .dropdown {
    display: flex;
    justify-content: space-between;
    text-transform: none;
    font-size: 14px;
    min-width: 170px;
  }
  .date-textfield {
    input {
      font-size: 14px;
      padding: 6px;
      width: 60px;
    }
  }
  .info-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 600px;
    height: 100%;
    margin-top: -30px;
  }
  .layout {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: -30px;
  }
  .total-value {
    font-size: 14px;
    padding: 4px;
    vertical-align: bottom;
    color: ${p => p.theme.palette.alltech.dark_grey[60]};
    min-width: 80px;
  }
  .reminder {
    align-self: flex-start;
    margin-top: 8px;
  }
`
export default SalesTargetsComponent