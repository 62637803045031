import { Array, Record, Static, String, Undefined, Boolean as RTBoolean, Array as RtArray, Intersect2, Intersect3, Constraint, Unknown } from "runtypes"
import { httpClient } from "../http_client"
import { of } from 'rxjs'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { AxiosRequestConfig, AxiosResponse } from "axios"
import { createTimeoutMessage } from "../../utils/epic-error-logger"

export const createAtrificalPayloadString = <TData>(data: TData) => {
    return `{\"Payload\": ${data},\"token\": \"\"}`
}

export const createArtificialResponse = <TPayloadData, TApiData extends TResData<TPayloadData>>(payloadData?: TPayloadData, ApiData?: TApiData, statusText = 'Mock'): AxiosResponse<TApiData> => {
    return {
        data: ApiData || {
            Payload: payloadData || ({} as TPayloadData),
            error: undefined,
            token: ''
        } as TApiData,
        status: 200,
        statusText,
        headers: '',
        config: {}
    }
}

type TParamValue = string | boolean | object
type TQParam = ((arg: any) => TParamValue) | TParamValue

/**
 * converts queryParam strings or functions to a uniform input type
 * if its a function it maps to the arg type, if value maps to value type
 * @example {name:string, date:(q:Date) => string} = {name:string, date:Date}
 */
type ConvertParamInputs<Qp> = Qp extends object ?
    Partial<{ [K in keyof Qp]:
        // if its a function...
        Qp[K] extends ((q: infer TQueryArg) => any) ?
        // pass the functino args
        TQueryArg :
        // else pass the value type
        Qp[K] }> : never

/** Response Runtype extends this type*/
export type TApiResRecordExt =
    RTBoolean |
    String |
    Record<{}, false> |
    Constraint<Unknown> |
    RtArray<TApiResRecordExt, false> |
    Intersect2<TApiResRecordExt, TApiResRecordExt> |
    Intersect3<TApiResRecordExt, TApiResRecordExt, TApiResRecordExt>

export type TResData<PayloadData> = {
    Payload: PayloadData;
    error?: string | undefined;
    token?: string | undefined;
}

export class EndpointDefintion<
    U extends ((...args) => string) | string,
    R extends TApiResRecordExt,
    B extends Record<{}, false> | Array<Record<{}, false>, false> | undefined,
    Qp extends { [key: string]: TQParam | undefined },
    AdpRes,
    Adp extends ((a: Static<R>) => AdpRes)
    > {
    constructor(
        /**
         * url or function returning url
         * @example (Param1:number, Param2:Data) => `/example/params/${Param1}/${param2.getTime()}`
         * @example '/example/justastring'
         */
        public url: U,
        /**
         * expected responce object type
         * @example Record({name:String, age:Number})
         */
        public resDataRecord: R,
        /**
         * expected post object type (if using POST or PUT requests)
         */
        public reqBodyRecord: B,
        public settings: Partial<{
            queryParams: Qp
            mockResponse: Static<R> | (() => Static<R>)
            /** modify incomming response body */
            adaptor: Adp
            /** for APIs that dont return the standard body shape.
             * should return the response in the format {payload:{data:***}} */
            responseBodyDataStructureFixer: (args) => { Payload: { data, token?: string } }
            /** override default request timeout (ms) */
            timeout: number
            headers: object
        }> = {}
    ) { }

    post = (...parameters:
        // has route parameters
        U extends ((a: infer Rparams) => string) ?
        // has route and query params
        Qp extends { [key: string]: TQParam } ?
        // look for body, route and query params
        [
            /**Request Body */
            Static<B extends RtArray<any, any> | Record<any, any> ? B : never>,
            /** Route Parameters */
            Rparams,
            /** Query Parameters */
            ConvertParamInputs<Qp>
        ] :
        // has only route params, look for only body and route params
        [
            /**Request Body */
            Static<B extends RtArray<any, any> | Record<any, any> ? B : never>,
            /** Route Parameters */
            Rparams
        ] :
        // has only query params
        Qp extends { [key: string]: TQParam } ?
        // look for only body and query params
        [
            /**Request Body */
            Static<B extends RtArray<any, any> | Record<any, any> ? B : never>,
            /** Query Parameters */
            ConvertParamInputs<Qp>] :
        // no params at all, just body required
        [
            Static<B extends RtArray<any, any> | Record<any, any> ? B : never>]) => {

        return this.call(parameters, 'post')
    }
    put = (...parameters:
        // has route parameters
        U extends ((a: infer Rparams) => string) ?
        // has route and query params
        Qp extends { [key: string]: TQParam } ?
        // look for body, route and query params
        [
            /**Request Body */
            Static<B extends RtArray<any, any> | Record<any, any> ? B : never>,
            /** Route Parameters */
            Rparams,
            /** Query Parameters */
            ConvertParamInputs<Qp>
        ] :
        // has only route params, look for only body and route params
        [
            /**Request Body */
            Static<B extends RtArray<any, any> | Record<any, any> ? B : never>,
            /** Route Parameters */
            Rparams
        ] :
        // has only query params
        Qp extends { [key: string]: TQParam } ?
        // look for only body and query params
        [
            /**Request Body */
            Static<B extends RtArray<any, any> | Record<any, any> ? B : never>,
            /** Query Parameters */
            ConvertParamInputs<Qp>] :
        // body or no params at all
        B extends RtArray<any, any> | Record<any, any> ?
        [Static<B extends RtArray<any, any> | Record<any, any> ? B : never>] : never) => {

        return this.call(parameters, 'put')
    }

    get = (...parameters:
        // has route parameters
        U extends ((a: infer Rparams) => string) ?
        // has route and query params
        Qp extends { [key: string]: TQParam } ?
        // look for route and query params
        [Rparams, ConvertParamInputs<Qp>] :
        // has only route params, look for only route params
        [Rparams] :
        // has only query params
        Qp extends { [key: string]: TQParam } ?
        // look for only query params
        [ConvertParamInputs<Qp>] :
        // no params at all, no args required
        never) => {
        return this.call(parameters, 'get')
    }

    delete = (...parameters:
        // has route parameters
        U extends ((a: infer Rparams) => string) ?
        // has route and query params
        Qp extends { [key: string]: TQParam } ?
        // look for route and query params
        [Rparams, ConvertParamInputs<Qp>] :
        // has only route params, look for only route params
        [Rparams] :
        // has only query params
        Qp extends { [key: string]: TQParam } ?
        // look for only query params
        [ConvertParamInputs<Qp>] :
        // no params at all, no args required
        never) => {
        return this.call(parameters, 'delete')
    }

    private call = (parameters: any[], method: 'post' | 'get' | 'put' | 'delete') => {
        const resBodyRecord = Record({
            Payload: this.resDataRecord,
            error: String.Or(Undefined),
            token: String.Or(Undefined)
        })

        type TApiBodyRes = Static<typeof resBodyRecord>

        let requestBody: Static<B extends Record<any, any> ? B : never> = {}

        if (method === 'post' || (method === 'put' && parameters)) {
            // request body is first argument
            requestBody = parameters[0]
            // remaining args will follow same order as other methods
            parameters.shift()
        }

        const hasRouteParams = typeof this.url === 'function'
        const hasQueryParams = typeof this.settings?.queryParams === 'object'
        const routeParams = hasRouteParams ? parameters[0] : null

        /** overwrite any Endpoint defined query param values with params passed in request args  */
        const getQueryParams = (qp) => {
            const qparms = hasRouteParams ? ([...parameters])[1] : (parameters)[0]
            return Object.keys(qp).reduce((p, c) => {
                const val = (typeof qp[c] === 'function') ? qp[c](qparms[c]) : qparms ? (qparms[c] || qp[c]) : qp[c]
                // console.log({ [c]: val, type: typeof val })
                if (val === undefined) {
                    return p
                }

                // dont serialize strings, only objects
                p[c] = typeof val === "object" ? JSON.stringify(val) : val
                // p[c] = val
                return p
            }, {})
        }

        const queryParams = hasQueryParams ? getQueryParams(this.settings.queryParams) : null

        let _url = this.url as any

        let parsedUrl: string = typeof _url === 'string' ? this.url : _url(routeParams)

        type TResponce = ReturnType<Adp> extends Object ? TResData<ReturnType<Adp>> : TResData<Static<R>>

        const _createArtificialResponse = (data: Static<R> | AdpRes, statusText = 'Mock'): AxiosResponse<TResponce> => {
            return {
                data: {
                    Payload: data,
                    error: undefined,
                    token: ''
                } as TResponce,
                status: 200,
                statusText,
                headers: '',
                config: {}
            }
        }

        if (this.settings.mockResponse) {
            const mockValue: Static<R> = typeof this.settings.mockResponse === 'function' ? (this.settings.mockResponse as () => Static<R>)() : this.settings.mockResponse
            return of(_createArtificialResponse(this.settings.adaptor ? this.settings.adaptor(mockValue) : mockValue  )) as AxiosObservable<TResponce>
        }

        const requestSettings: AxiosRequestConfig = {
            // Do type checking on response body. If it does not corrospond to the provided record, log an error but dont block the response
            transformResponse: (responseString: string, ...x) => {
                if (!responseString) return responseString
                let parsedResponse
                try {
                    parsedResponse = JSON.parse(responseString)
                    parsedResponse = this.settings.responseBodyDataStructureFixer ? this.settings.responseBodyDataStructureFixer(parsedResponse) : parsedResponse
                } catch (error) {
                    console.error('failed to parse JSON reponse', parsedUrl)
                }
                const validateRes = resBodyRecord.validate(parsedResponse)
                if (!validateRes.success) {
                    console.warn(`${validateRes.message} at ${validateRes.key}`, parsedUrl)
                }
                if (this.settings.adaptor) {
                    try {
                        const body = parsedResponse.Payload
                        if (body) parsedResponse.Payload = this.settings.adaptor(body)
                        return parsedResponse
                    } catch (error) {
                        console.error(`Failed to apply settings.adaptor for url ${parsedUrl}`)
                        console.log({ error, parsedResponse })
                    }
                }
                return parsedResponse

            },

            baseURL: undefined,
            params: queryParams,
            timeout: this.settings.timeout,
            timeoutErrorMessage: createTimeoutMessage(this.settings.timeout, parsedUrl),
            headers: this.settings.headers
        }

        switch (method) {
            case 'post':
                return httpClient.post<TResponce>(parsedUrl, requestBody, requestSettings)
            case 'put':
                return Object.keys(requestBody || {}).length > 0 ? httpClient.put<TResponce>(parsedUrl, requestBody, requestSettings) : httpClient.put<TResponce>(parsedUrl, requestSettings)
            case 'get':
                return httpClient.get<TResponce>(parsedUrl, requestSettings)
            case 'delete':
                return httpClient.delete<TResponce>(parsedUrl, requestSettings)
            default:
                return httpClient.get<TResponce>(parsedUrl, requestSettings)
        }
    }
}