import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { Button, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableRow, TextField } from '@mui/material'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { useTypedAppSelector } from 'src/store'
import { salesAction } from 'src/store/actions/sales.actions'
import { WarningIcon } from 'src/components/icons/warning'
import { ClearIcon } from 'src/components/icons/clear'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _AuthoriseOrder: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [orderStr, setOrderStr] = useState<string>('')
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const order = useTypedAppSelector(s => s.salesState.order)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const dispatch = useDispatch()

  const authoriseOrder = () => order?.value?.SALESORDERID && dispatch(salesAction.authoriseOrder({ SalesOrderId: order.value.SALESORDERID}))

  const submitHandler = () => dispatch(salesAction.requestOrder({ id: orderStr }))

  const clearOrders = () => {
    setOrderStr('')
    dispatch(salesAction.clearOrder())
  }

  useEffect(() => {
    setLoading(order?.loading ? true : false)
  }, [order?.loading])

  useEffect(() => {
    order?.message && setMessage({ message: order.message.msg, severity: order.message.level, clear: () => dispatch(salesAction.clearOrderMessage()) })
  }, [order?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 25, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    return () => {
      setLoading(false)
      dispatch(salesAction.clearOrder())
    }
  }, [])

  return <Paper className={className}>
    <div className='container'>
      <TextField
        label='Order Number'
        inputProps={{ style: { fontSize: 14, minWidth: 400, padding: '10px 14px' } }}
        size='small'
        margin='dense'
        value={orderStr || ''}
        disabled={hasAccess}
        onChange={e => setOrderStr(e.target.value)}
        InputProps={{ endAdornment: <InputAdornment style={{ display: orderStr?.length > 0 ? 'flex' : 'none' }} position='end'>
          <IconButton onClick={() => clearOrders()} edge='end'>
            <ClearIcon size={16} />
          </IconButton>
        </InputAdornment>}}
      />
      <Button className='submit-button' color='primary' variant='contained' size='small' disabled={(orderStr || '').length === 0 || hasAccess} onClick={submitHandler}>Submit</Button>
    </div>
    {order?.loaded ?
      Object.keys(order?.value || {})?.length > 0 ? <div className='table-div'>
        <Table className='sales-table'>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>Customer Purchase Order Number</TableCell>
              <TableCell align='center'>{order?.value?.CUSTOMERPURCHASEORDERNUMBER}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>Sales Order ID</TableCell>
              <TableCell align='center'>{order?.value?.SALESORDERID}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row'>Minimum Price Authorised</TableCell>
              <TableCell align='center'>
                {order?.value?.MINPRICEUNDERAUTHORISED !== 'T' ? <Button
                  variant='contained'
                  color='primary'
                  onClick={authoriseOrder}
                  disabled={order?.loading || hasAccess}
                  style={{ width: '80%' }}
                  size='small'
                >
                  {order?.loading ? 'Saving....' : 'Authorise This Order'}
                </Button>
                : <div>Authorised</div>}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      : <div className='empty-div'>
        <WarningIcon size={80} />
        There is no order to authorise
      </div>
    : undefined}
  </Paper>
}

export const AuthoriseOrderComponent = styled(_AuthoriseOrder)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 50vh;
  padding: 30px;
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: fit-content;
  }
  .table-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 50px;
  }
  .empty-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 60px;
  }
  .sales-table {
    width: 70%;
    .MuiTableRow-root {
      .MuiTableCell-root:nth-child(2) {
        width: 30%;
      }
    }
  }
  .submit-button {
    margin-left: 20px;
  }
`
export default AuthoriseOrderComponent