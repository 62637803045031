import React, { useContext } from 'react'
import { Typography } from '@mui/material'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { NavBar } from 'src/components/navBar/navbar'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import AuthoriseOrderComponent from './authorise-order'
import { sortSubFeatures } from 'src/lib/utils/methods'
import TradeinsComponent from './trade-ins'
import SalesTargetsComponent from './sales-targets'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _SalesSectionNav: React.FC<proptypes & { className?: string }> = () => {
  const { path } = useRouteMatch()
  return <ErrorBoundary page="sales nav bar">
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography variant='h5'>Sales</Typography>
      <NavBar tabs={[
        {
          title: 'Authorise Order',
          to: `${path}/salesorderauth`,
        },
        {
          title: 'Sales Target',
          to: `${path}/targets`,
        },
        {
          title: 'Trade-ins',
          to: `${path}/TradeIns`,
        }
      ]} />
    </div>
  </ErrorBoundary>
}

const _SalesSection: React.FC<proptypes & { className?: string }> = () => {
  const { path } = useRouteMatch()
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  
  return <React.Fragment>
    <Switch>
      {sortSubFeatures('sales', 25, enabledSecurity) && <Route path={`${path}/salesorderauth`} render={() => <ErrorBoundary page='authorise order'><AuthoriseOrderComponent /></ErrorBoundary>}></Route>}
      {sortSubFeatures('sales', 24, enabledSecurity) && <Route path={`${path}/targets`} render={() => <ErrorBoundary page='sales target'><SalesTargetsComponent /></ErrorBoundary>}></Route>}
      {sortSubFeatures('sales', 20, enabledSecurity) && <Route path={`${path}/TradeIns`} render={() => <ErrorBoundary page='trade ins'><TradeinsComponent /></ErrorBoundary>}></Route>}
    </Switch>
  </React.Fragment>
}

export const SalesSectionComponent = _SalesSection
export const SalesSectionNavComponent = _SalesSectionNav
export default SalesSectionComponent