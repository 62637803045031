import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import ClientSearchComponent from 'src/components/accountSearch/client-search'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { BinIcon } from 'src/components/icons/bin'
import { Button, Dialog, DialogActions, DialogContent, FormControl, MenuItem, Paper, Select, SxProps, Tooltip } from '@mui/material'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { ArrowRightIcon } from 'src/components/icons/arrow-right'
import { SnackBarContext } from 'src/contexts/snack-bar.context'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const uomSelection = (uom: API_NS.UOM[], selectedUomId: number, updateUomMapping: (data: API_NS.UOMMaps | number) => void, hasAccess: boolean, sx?: SxProps, row?: any) => {
  return <FormControl sx={{ ...sx, minWidth: 120 }} size='small'>
    <Select color='secondary' style={{ fontSize: 13 }} value={selectedUomId} disabled={hasAccess}>
      {uom.map((u, i) => <MenuItem 
        key={i}
        style={{ fontSize: 14 }}
        value={u.UnitOfMeasureId}
        onClick={() => row ? updateUomMapping({ UnitOfMeasureClientMappingId: row.UnitOfMeasureClientMappingId, UnitOfMeasureId: u.UnitOfMeasureId, ClientId: row.ClientId }) : updateUomMapping(u.UnitOfMeasureId)}>
        {u.UnitOfMeasureDescription}
      </MenuItem>)}
    </Select>
  </FormControl>
}

const _UOMMappings: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [account, setAccount] = useState<{acc: API_NS.Accounts, type: 'customer'} | undefined>(undefined)
  const [selectedUomId, setSelectedUomId] = useState<number>(1)
  const [uomToDelete, setUomToDelete] = useState<number>()
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [clear, setClear] = useState<boolean>(false)
  const uom = useTypedAppSelector(s => s.intouchState.uom)
  const uomMappings = useTypedAppSelector(s => s.intouchState.uomMappings)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const CustomGridActionsCellItem = GridActionsCellItem as any

  const selectAccountHandler = (acc, type) => setAccount({ acc, type })
  
  const resetClear = () => setClear(false)

  const newUomSelectionHandler = uom => setSelectedUomId(uom)

  const updateUomMapping = uom => dispatch(intouchAction.updateUomMapping(uom))

  const addUomMapping = () => {
    if (!account) return

    const exists = (uomMappings?.value || []).find(u => u.ClientId === account?.acc.ClientCode)

    if (Object.values(exists || {})?.length > 0) setMessage({ message: 'This UOM mapping has already been set up', severity: 'warning', clear: null})

    dispatch(intouchAction.addUomMapping({ UnitOfMeasureId: selectedUomId, ClientId: account?.acc.ClientCode }))
    setClear(true)
    setSelectedUomId(1)
  }

  const deleteUomMapping = () => uomToDelete && dispatch(intouchAction.deleteUomMapping({ mapId: uomToDelete.toString() }))

  const columns = [
    {
      field: 'ClientId',
      headerName: 'InTouch Client',
      flex: 1,
      headerClassName: 'column-header',
      cellClassName: 'table-cell',
      sortComparator: (a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1,
    },
    {
      field: 'arrow',
      headerName: '',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (_p: GridRenderCellParams) => <div style={{ width: '100%', textAlign: 'center' }}><ArrowRightIcon size={25} /></div>
    },
    {
      field: 'UnitOfMeasureId',
      headerName: 'Units of Measurement',
      flex: 1,
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (p: GridRenderCellParams) => uomSelection((uom?.value || []), p.value, updateUomMapping, hasAccess, { width: '60%' }, p.row)
    },
    {
      field: 'actions',
      flex: 0.2,
      type: 'actions',
      sortable: false,
      filterable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={<Tooltip title='Delete' followCursor><span><BinIcon className='primary-color' size={25} /></span></Tooltip>}
          label='Delete'
          onClick={() => { setUomToDelete(p.row.UnitOfMeasureClientMappingId); setOpenDelete(true) }}
        />
      ]
    }
  ]

  useEffect(() => {
    if (!uom?.loaded) return
    const uomFound = (uom?.value || []).find(u => u.UnitOfMeasureCode === 'kg')
    uomFound && setSelectedUomId(uomFound.UnitOfMeasureId)
  }, [uom?.loaded, uom?.value])

  useEffect(() => {
    setLoading(uom?.loading || uomMappings?.loading ? true : false)
  }, [uom?.loading, uomMappings?.loading])

  useEffect(() => {
    uom?.message && setMessage({ message: uom.message.msg, severity: uom.message.level, clear: () => dispatch(intouchAction.clearUomMessage()) })
    uomMappings?.message && setMessage({ message: uomMappings.message.msg, severity: uomMappings.message.level, clear: () => dispatch(intouchAction.clearUomMappingsMessage()) })
  }, [uom?.message, uomMappings?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 19, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    dispatch(intouchAction.requestUom())
    dispatch(intouchAction.requestUomMappings())
    return () => {
      setLoading(false)
    }
  }, [])

  const deleteUOMDialog = <Dialog fullWidth open={openDelete} onClose={() => setOpenDelete(false)}>
    <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this UOM?</DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenDelete(false); deleteUomMapping() }}>Confirm</Button>
      <Button size='small' onClick={() => setOpenDelete(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>

  return <div className={className}>
    <Paper className='paper'>
      <div className='new-uom-div'>
        <ClientSearchComponent selectedAccount={account} selectAccountHandler={selectAccountHandler} clear={clear} resetClear={resetClear} disabled={hasAccess} />
        <ArrowRightIcon className='arrow-icon' size={25} />
        {uomSelection((uom?.value || []), selectedUomId, newUomSelectionHandler, hasAccess, { width: 160 })}
        <Button className='add-button' color='primary' variant='contained' size='small' disabled={!account?.acc} onClick={addUomMapping}>Add Mapping</Button>
      </div>
      <DataGridComponent
        sx={{ width: '95%', margin: '0 auto' }}
        title=' '
        data={(uomMappings?.value || []).map((u, i) => ({ ...u, id: i }))}
        columns={columns as any}
        apiRef={apiRef}
        rowHeight={45}
        rowsPerPage={100}
        loading={uom?.loading || uomMappings?.loading}
        defaultState={{ sorting: { sortModel: [{ field: 'ClientId', sort: 'asc' }] }}}
      />
    </Paper>
    {deleteUOMDialog}
  </div>
}

export const UOMMappingsComponent = styled(_UOMMappings)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .paper {
    width: 100%;
    height: 100%;
    min-height: 75vh;
    padding: 8px;
  }
  .new-uom-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0 30px 0;
  }
  .arrow-icon {
    margin: 0 10px;
  }
  .add-button {
    margin-left: 20px;
  }
  .primary-color {
    color:  ${p => p.theme.palette.alltech.terracotta.main};
  }
`
export default UOMMappingsComponent