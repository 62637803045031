import { Clear } from "@mui/icons-material";
import { Autocomplete, Box, Button, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ClientSearchComponent from "src/components/accountSearch/client-search";
import { CalendarIcon } from "src/components/icons/calendar";
import { SnackBarContext } from "src/contexts/snack-bar.context";
import { API_NS } from "src/lib/api/endpoints/endpoint.types";
import { useTypedAppSelector } from "src/store";
import { reportsAction } from "src/store/actions/reports.actions";
import { AsyncProcessState } from "src/store/types/shared-state-types";

type AddRecordProptypes = {
  hasAccess: boolean;
  alltechProducts: API_NS.AlltechProduct[] | undefined;
  allProductUsage: AsyncProcessState<API_NS.ProductUsage[]> | undefined;
  uomList: API_NS.ProductUom[] | undefined;
  loading?: boolean;
}

export const AddRecordContainer: React.FC<AddRecordProptypes & { className?: string }> = (props) => {
  const {className, alltechProducts, allProductUsage, uomList, loading, hasAccess} = props
  const [account, setAccount] = useState<{acc: API_NS.Accounts, type: 'customer'} | undefined>(undefined)
  const [product, setProduct] = useState<API_NS.AlltechProduct>()
  const [startDate, setStartDate] = useState<string>(moment().format())
  const [endDate, setEndDate] = useState<string | null>(null)
  const [fedPerHead, setFedPerHead] = useState<number>()
  const [uom, setUom] = useState<number>()
  const [openStart, setOpenStart] = useState(false)
  const [openEnd, setOpenEnd] = useState(false)
  const [clear, setClear] = useState<boolean>(false)
  const { setMessage } = useContext(SnackBarContext)
  const deleteProductUsageLoading = useTypedAppSelector(s => s.reportsState.deleteProductUsageLoading)
  const dispatch = useDispatch()

  const selectAccount = (acc, type) => setAccount({ acc, type })
  
  const resetClear = () => setClear(false)

  const addProductUsage = () => {
    if (!account || !product || !startDate || !fedPerHead || !uom) return

    const productActive = (allProductUsage?.value || []).filter(pu => pu.Username === account.acc?.ClientCode && pu.AlltechProductGroupId === product.AlltechProductGroupId && !pu.EndDate)

    if (productActive.length > 0) {
      setMessage({ message: 'Cannot create new product usage record as this product is still active', severity: 'warning', clear: null })
      return
    }
    
    dispatch(reportsAction.upsertAllProductUsage({ 
      AlltechProductGroupUsageId: 0,
      Username: account.acc.ClientCode,
      AlltechProductGroupId: product.AlltechProductGroupId,
      StartDate: moment(startDate).format('YYYYMMDD'),
      EndDate: endDate ? moment(endDate).format('YYYYMMDD') : null,
      Quantity: fedPerHead,
      AlltechProductUoMId: uom
    }))
  }

  useEffect(() => {
    if (!deleteProductUsageLoading && allProductUsage?.message?.level === 'success') {
      setAccount(undefined)
      setProduct(undefined)
      setStartDate(moment().format())
      setEndDate(null)
      setFedPerHead(undefined)
      setUom(undefined)
    }
  }, [deleteProductUsageLoading])

  return <div className={className} style={{ width: '100%', position: 'relative' }}>
    {loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', position: 'absolute', background: 'white', opacity: 0.6, zIndex: 9 }}>
      <CircularProgress />
    </div>}
    <Paper style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', margin: '30px 0', padding: '12px 18px' }}>
      <Typography variant='subtitle2' style={{ paddingBottom: 8 }}>New Record</Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', columnGap: '6px', flexWrap: 'wrap' }}>
        <ClientSearchComponent selectedAccount={account} selectAccountHandler={selectAccount} clear={clear} resetClear={resetClear} disabled={hasAccess} />
        <FormControl sx={{ minWidth: 300 }} size='small'>
          <InputLabel id='product-select'>Alltech Product</InputLabel>
          <Select sx={{ fontSize: '14px' }} labelId='product-select' variant='outlined' color='secondary' value={product?.AlltechProductGroupId === undefined ? '' : product.AlltechProductGroupId} label='Alltech Product' disabled={hasAccess}>
            {alltechProducts?.map((p, i) => <MenuItem key={i} style={{ fontSize: 14 }} value={p.AlltechProductGroupId} onClick={() => setProduct(p)}>
              {p.AlltechProductGroupName}
            </MenuItem>)}
          </Select>
        </FormControl>
        <DatePicker
          components={{ OpenPickerIcon: CalendarIcon }}
          orientation='landscape'
          value={startDate}
          views={['day']}
          open={openStart}
          onOpen={() => setOpenStart(true)}
          onClose={() => setOpenStart(false)}
          disableMaskedInput
          disableHighlightToday
          inputFormat='dd/MM/yyyy'
          onChange={e => setStartDate(moment(e).format())}
          renderInput={(params) => <TextField {...params} variant='standard' style={{ paddingBottom: '10px' }} sx={{ '.MuiInputBase-input': { fontSize: '14px', width: '85px' } }} label='Start Date*' />}
        />
        <DatePicker
          components={{ OpenPickerIcon: CalendarIcon }}
          orientation='landscape'
          value={endDate}
          views={['day']}
          open={openEnd}
          onOpen={() => setOpenEnd(true)}
          onClose={() => setOpenEnd(false)}
          disableMaskedInput
          disableHighlightToday
          inputFormat='dd/MM/yyyy'
          onChange={e => setEndDate(moment(e).format())}
          renderInput={(params) => <TextField {...params} variant='standard' style={{ paddingBottom: '10px' }} sx={{ '.MuiInputBase-input': { fontSize: '14px', width: '85px' } }} label='End Date' />}
        />
        <TextField
          placeholder='Fed Per Head'
          inputProps={{ style: { fontSize: 14, minWidth: 100, width: 110 } }}
          type='number'
          size='small'
          margin='dense'
          value={fedPerHead || ''}
          onChange={e => setFedPerHead(e.target.value?.length > 0 ? parseFloat(e.target.value) : undefined)}
        />
         <FormControl sx={{ minWidth: 150 }} size='small'>
          <InputLabel id='uom-select'>UOM</InputLabel>
          <Select sx={{ fontSize: '14px' }} labelId='uom-select' variant='outlined' color='secondary' value={uom === undefined ? '' : uom} label='UOM' disabled={hasAccess}>
            {uomList?.map((u, i) => <MenuItem key={i} style={{ fontSize: 14 }} value={u.AlltechProductGroupUoMId} onClick={() => setUom(u.AlltechProductGroupUoMId)}>
              {u.UoMName}
            </MenuItem>)}
          </Select>
        </FormControl>
        <Button className='add-button' color='primary' variant='contained' size='small' onClick={addProductUsage} disabled={!product || !account || !fedPerHead || !startDate || !uom}>Add Record</Button>
      </div>
    </Paper>
  </div>
}

type ActiveFilterProptypes = {
  filter: string | undefined;
  setFilter: (b: string | undefined) => void;
}

export const ActiveFilter: React.FC<ActiveFilterProptypes & { className?: string }> = (props) => {
  const { filter, setFilter } = props

  return <div style={{ display: 'flex', alignItems: 'center', columnGap: '6px' }}>
    <FormControl sx={{ width: '100%' }} size='small'>
      <InputLabel id='active-select'>Is Active</InputLabel>
      <Select
        sx={{ fontSize: '14px', '&:hover, &:focus-within': { '.end-icon': { visibility: 'visible' } } }}
        MenuProps={{ disableScrollLock: true, style: { zIndex: 9999 } }}
        labelId='active-select'
        variant='outlined'
        color='secondary'
        label='Is Active'
        value={filter === undefined ? '' : filter}
        endAdornment={
          filter && <InputAdornment className='end-icon' sx={{ marginRight: '16px', visibility: 'hidden' }} position='end'>
            <IconButton size='small' onClick={() => setFilter(undefined)}><Clear fontSize='small' /></IconButton>
          </InputAdornment>
        }
      >
        <MenuItem style={{ fontSize: 14 }} value='Yes' onClick={() => setFilter(filter === 'Yes' ? undefined : 'Yes')}>Yes</MenuItem>
        <MenuItem style={{ fontSize: 14 }} value='No' onClick={() => setFilter(filter === 'No' ? undefined : 'No')}>No</MenuItem>
      </Select>
    </FormControl>
  </div>
}

type UomFilterProptypes = {
  filter: number | undefined;
  setFilter: (b: number | undefined) => void;
  options: API_NS.ProductUom[] | undefined;
}

export const UomFilter: React.FC<UomFilterProptypes & { className?: string }> = (props) => {
  const { filter, setFilter, options } = props

  return <div style={{ display: 'flex', alignItems: 'center', columnGap: '6px' }}>
    <FormControl sx={{ width: '100%' }} size='small'>
      <InputLabel id='uom-select'>UOM</InputLabel>
      <Select
        sx={{ fontSize: '14px', '&:hover, &:focus-within': { '.end-icon': { visibility: 'visible' } } }}
        MenuProps={{ disableScrollLock: true, style: { zIndex: 9999 } }}
        labelId='uom-select'
        variant='outlined'
        color='secondary'
        label='UOM'
        value={filter === undefined ? '' : filter}
        endAdornment={
          filter && <InputAdornment className='end-icon' sx={{ marginRight: '16px', visibility: 'hidden' }} position='end'>
            <IconButton size='small' onClick={() => setFilter(undefined)}><Clear fontSize='small' /></IconButton>
          </InputAdornment>
        }
      >
        {options?.map((o, i) => <MenuItem key={i} style={{ fontSize: 14 }} value={o.AlltechProductGroupUoMId} onClick={() => setFilter(filter === o.AlltechProductGroupUoMId ? undefined : o.AlltechProductGroupUoMId)}>
          {o.UoMName}
        </MenuItem>)}
      </Select>
    </FormControl>
  </div>
}

type ProductFilterProptypes = {
  filter: API_NS.AlltechProduct | undefined;
  setFilter: (b: API_NS.AlltechProduct | undefined) => void;
  options: API_NS.AlltechProduct[] | undefined;
}

export const ProductFilter: React.FC<ProductFilterProptypes & { className?: string }> = (props) => {
  const { filter, setFilter, options } = props

  return <div style={{ display: 'flex', alignItems: 'center', columnGap: '6px' }}>
    <FormControl sx={{ width: '100%' }} size='small'>
      <Autocomplete
        disablePortal
        id='product-select'
        sx={{ width: '100%' }}
        size='small'
        options={options || []}
        getOptionLabel={(o) => o.AlltechProductGroupName || ''}
        value={filter || null}
        onChange={(e: any, newVal: any | null) => setFilter(newVal) }
        renderOption={(props, option) => {
          const { ...optionProps } = props;
          return (
            <Box key={`${option.AlltechProductGroupId}-${option.AlltechProductGroupName}`} component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
              {option.AlltechProductGroupName}
            </Box>
          );
        }}
        renderInput={(params) => <TextField {...params} label='Product' />}
      />
    </FormControl>
  </div>
}