import React, { useState, useEffect, useMemo } from 'react'
import { Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, FormControlLabel, IconButton, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import styled from 'styled-components'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { parseExpression } from 'cron-parser'
import { BinIcon } from 'src/components/icons/bin'
import { CalendarIcon } from 'src/components/icons/calendar'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import moment from 'moment'
import TimezoneSelectorComponent from './timezone-selector'
import HelperIconComponent from '../helperIcon/helper-icon'
import { useIntl } from 'react-intl'

export type proptypes = {
  onSubmit?: (str: string, startDate: string, endDate: string | null, timezone: string) => void,
  onDisable?: (tz: string) => void,
  onEnable?: (tz: string) => void,
  scheduler?: API_NS.AutoRpmSchedulerRes,
  submitLoading?: boolean,
  onClose?: () => void
}
export const defaultProps: proptypes = {
}

const _CronScheduler: React.FC<proptypes & { className?: string }> = (props) => {
  const { scheduler, onDisable, onEnable, onSubmit, onClose, submitLoading, className } = props
  const [weekdays, setWeekdays] = useState([1])
  const [dayMode, setDayMode] = useState<'everyday' | 'specificdays'>('everyday')
  const [time, setTime] = useState<{id: number, date: Date}[]>([{ id: 0, date: new Date('01 01 2020 12:00:00') }])
  const [selectedTz, setSelectedTz] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [cronString, setCronString] = useState<string>('0 0 12 ? * *')
  const [submitCronString, setSubmitCronString] = useState<string>('0 0 12 ? * *')
  const [previewList, setPreviewList] = useState<{date: string, time: string}[]>()
  const [startDate, setStartDate] = useState<string>(moment().format())
  const [endDate, setEndDate] = useState<string | null>(null)
  const [hasEndDate, sethasEndDate] = useState(false)
  const [openStart, setOpenStart] = useState(false)
  const [openEnd, setOpenEnd] = useState(false)
  const [openDisable, setOpenDisable] = useState(false)
  const [openEnable, setOpenEnable] = useState(false)
  const intl = useIntl()
  const DayOptions = Array(7).fill(null).map((_i, x) => intl.formatDate(new Date(`2020 01 ${x + 5}`), { weekday: 'short' }))
  const previewCount: number = 5

  const consecutivePeriod = period => {
    let consecPeriod: { first?: number, last?: number }[] = [];

    (period.sort((a, b) => a - b) || []).map((m, i) => {
      if (i === 0)
        consecPeriod = [...consecPeriod, { first: m }]
      else {
        const latestConsec = consecPeriod[consecPeriod.length - 1]
        if (latestConsec?.first && !latestConsec?.last && m - latestConsec.first === 1) { // if it's consecutive and there's only a first month
          const index = consecPeriod.findIndex((c => c.first === latestConsec.first))
          consecPeriod[index].last = m
        }
        else if (latestConsec?.last && m - latestConsec.last === 1) { // else if it's consecutive and there's a last month
          const index = consecPeriod.findIndex((c => c.last === latestConsec.last))
          consecPeriod[index].last = m
        }
        else // not consecutive
          consecPeriod = [...consecPeriod, { first: m }]
      }
    })

    const arr = consecPeriod.map(c => { return c?.last ? `${c?.first}-${c.last}` : `${c?.first}` })
    return arr.join(',').replace(/, ([^,]*)$/, '')
  }

  const convertWeekedays = str => {
    const replace = [...str].map(a => {
      switch(a) {
        case '1': return 'SUN';
        case '2': return 'MON';
        case '3': return 'TUE';
        case '4': return 'WED';
        case '5': return 'THU';
        case '6': return 'FRI';
        case '7': return 'SAT';
        default: return a
      } 
    })

    return replace.join('')
  }

  const toggleWeekday = (weekday: number) => {
    setWeekdays(c => { return c.includes(weekday) ? c.filter(x => x !== weekday) : [...c, weekday] })
  }

  const handleTimeChange = useMemo(() => (val, id) => {
    setTime((time || []).map(t => {
      let updated;
      if (!(t.date.getMinutes() === val.getMinutes())) 
        updated = {...t, date: new Date(`${t.date.getDate()} ${t.date.getMonth() + 1} ${t.date.getFullYear()} ${t.date.getHours().toString().padStart(2, '0')}:${val.getMinutes().toString().padStart(2, '0')}:00`)}
      else
        updated = t

      return t.id === id ? { id: t.id, date: val} : updated
    }))
  }, [time])

  const addTime = () => {
    const arr = time || []
    const index = time && Object.values(time || {}).length > 0 ? time[time.length - 1].id + 1 : 1
    setTime([...arr, { id: index, date: index === 1 ? new Date('01 01 2020 12:00:00') : new Date(`01 01 2020 12:${time?.[0]?.date.getMinutes().toString().padStart(2, '0') || '00'}:00`)} ])
  }

  const deleteTime = id => setTime(time?.filter(t => t.id !== id))

  useEffect(() => {
    if (!time) return
    
    if (dayMode === 'specificdays' && weekdays.length === 0) {
      setPreviewList(undefined)
      setCronString('')
      return
    }
    else {
      let res: string
      let resSubmit: string
      let timeStr = ''

      time.map((t, i) => {
        const [hour, minute] = intl.formatDate(t.date, { hour: '2-digit', minute: '2-digit' }).split(':')
        timeStr = i === 0 ? `${minute} ${hour}` : timeStr + `,${hour}`
      })

      switch (dayMode) {
        case 'everyday':
          res = `0 ${timeStr} ? * *`
          resSubmit = `0 ${timeStr} ? * *`
          break;
        case 'specificdays':
          res = `0 ${timeStr} ? * ${weekdays.length === 7 ? '*' : consecutivePeriod(weekdays)}`
          resSubmit = `0 ${timeStr} ? * ${weekdays.length === 7 ? '*' : convertWeekedays(consecutivePeriod(weekdays?.map(w => w + 1)))}`
          break;
      }
      setCronString(res)
      setSubmitCronString(resSubmit)
    }
  }, [weekdays, dayMode, time])

  useEffect(() => {
    if (cronString === undefined || cronString === '') return
    let parser;

    try {
      parser = parseExpression(cronString, { currentDate: new Date(startDate) })
    }
    catch(e) {
      console.log('cron string parser error', e as string, '\n', parser)
    }
    if (!parser) return;

    parser.prev()
    const list = Array(previewCount).fill(null).map(_x => {
      const next = parser.next().toDate()
      return { date: intl.formatDate(next, { dateStyle: 'full' }), time: moment(next).format('HH:mm')}
    })
    setPreviewList(list)
  }, [cronString, startDate])

  return <div className={className}>
    <div className='title-section'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{ fontWeight: 500 }} variant='body1'>Scheduler</Typography>
        {scheduler?.ReadableCronSChedule && <HelperIconComponent iconSize={22} helpText={<div style={{ padding: 6, width: 340 }}>
          Submitting a newly created schedule using the form below will replace an existing schedule
        </div>} />}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', columnGap: 20 }}>
        {scheduler?.Enabled ? <Button
          style={{ display: scheduler?.CronSchedule ? 'flex' : 'none' }}
          variant='contained'
          size='small'
          disabled={submitLoading}
          startIcon={submitLoading && <CircularProgress style={{ color: 'white', marginRight: 10 }} size={15} /> }
          onClick={() => setOpenDisable(true)}
        >
          Disable
        </Button>
        : <Button
          style={{ display: scheduler?.CronSchedule ? 'flex' : 'none' }}
          variant='contained'
          size='small'
          disabled={submitLoading}
          startIcon={submitLoading && <CircularProgress style={{ color: 'white', marginRight: 10 }} size={15} /> }
          onClick={() => setOpenEnable(true)}
        >
          Enable
        </Button>}
        <Button
          variant='contained'
          size='small'
          disabled={submitLoading || selectedTz === '' || (hasEndDate && endDate === null) || weekdays.length === 0}
          startIcon={submitLoading && <CircularProgress style={{ color: 'white', marginRight: 10 }} size={15} /> }
          onClick={() => { onSubmit && onSubmit(submitCronString, moment(startDate).format('YYYY/MM/DD'), hasEndDate ? moment(endDate).format('YYYY/MM/DD') : null, selectedTz )}}
        >
          Save
        </Button>
        {onClose && <Button
          variant='contained'
          size='small'
          disabled={submitLoading}
          onClick={onClose}
        >
          Close
        </Button>}
      </div>
    </div>

    {scheduler?.ReadableCronSChedule && <div className='current-schedule'>
      <Typography style={{ paddingRight: 8, fontSize: 15, fontWeight: 500 }}>Current Schedule:</Typography>
      <Typography style={{ fontSize: 15 }}>{scheduler?.ReadableCronSChedule}</Typography>
    </div>}

    <Typography style={{ marginBottom: 10, fontSize: 15, fontWeight: 500 }}>New Schedule</Typography>
    <div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content' }}>
        <DatePicker
          components={{ OpenPickerIcon: CalendarIcon }}
          orientation='landscape'
          value={startDate}
          views={['day']}
          open={openStart}
          onOpen={() => setOpenStart(true)}
          onClose={() => setOpenStart(false)}
          disableMaskedInput
          disableHighlightToday
          inputFormat='dd/MM/yyyy'
          onChange={e => setStartDate(moment(e).format())}
          renderInput={(params) => <TextField {...params} variant='standard' style={{ marginRight: 50 }} sx={{ '.MuiInputBase-input': { fontSize: '15px' } }} label='Start Date*' />}
        />
        <DatePicker
          components={{ OpenPickerIcon: CalendarIcon }}
          orientation='landscape'
          value={endDate}
          views={['day']}
          open={openEnd}
          minDate={startDate || moment().format()}
          onOpen={() => setOpenEnd(true)}
          onClose={() => setOpenEnd(false)}
          disableMaskedInput
          disableHighlightToday
          inputFormat='dd/MM/yyyy'
          onChange={e => setEndDate(moment(e).format())}
          renderInput={(params) => <TextField {...params} style={{ display: hasEndDate ? 'block' : 'none', marginRight: 30 }} sx={{ '.MuiInputBase-input': { fontSize: '15px' } }} variant='standard' label='End Date' />}
        />
      <FormControlLabel
        value={hasEndDate}
        checked={hasEndDate}
        control={<Switch size='small' color='primary' />}
        onChange={() => sethasEndDate(!hasEndDate)}
        label='Specify End Date'
        labelPlacement='end'
      />
    </div>

    <Typography style={{ fontSize: 13, margin: '14px 0 2px 0' }}>Timezone*</Typography>
    <TimezoneSelectorComponent timezone={selectedTz} setTimezone={setSelectedTz} />

    <Typography style={{ fontSize: 13, margin: '14px 0 2px 0' }}>Frequency*</Typography>
    <ButtonGroup>
      <Button size='small' variant={dayMode === 'everyday' ? 'contained' : 'outlined'} onClick={() => setDayMode('everyday')}>Every Day</Button>
      <Button size='small' variant={dayMode === 'specificdays' ? 'contained' : 'outlined'} onClick={() => setDayMode('specificdays')}>Specific Days</Button>
    </ButtonGroup>

    {dayMode === 'specificdays' && <React.Fragment>
      <Typography style={{ fontSize: 13, margin: '14px 0 2px 0' }}>Days*</Typography>
      <ButtonGroup>
        {DayOptions.map((weekday, i) => {
          return <Button key={`day-option-${weekday}`} variant={weekdays.includes(i) ? 'contained' : 'outlined'} onClick={() => toggleWeekday(i)}>{weekday}</Button>
        })}
      </ButtonGroup>
    </React.Fragment>}

    <div style={{ display: 'flex', alignItems: 'center', margin: '14px 0 6px 0' }}>
      <Typography style={{ fontSize: 13 }}>Time(s)* </Typography>
      <HelperIconComponent iconSize={18} helpText={<div style={{ padding: 6, width: 320 }}>Minutes will be consistent for all scheduled times below.</div>} />
    </div>
    <div className='time-section'>
      {(time || []).length > 0 && time?.map((t, i) => <div key={i} className='picker'>
        <TimePicker
          value={t.date}
          ampm={false}
          onChange={(newValue) => { handleTimeChange(newValue, t.id) }}
          renderInput={(params) => <TextField className='time-field' size='small' {...params} />}
        />
        {i !== 0 ? <IconButton style={{ padding: '0 5px' }} color='primary' size='small' onClick={() => deleteTime(t.id)}>
          <BinIcon size={26} />
        </IconButton>
        : <div style={{ width: 40 }}></div>}
      </div>)}
      <Tooltip title='Add' followCursor>
        <Button className='add-icon' variant='contained' color='primary' size='small' onClick={() => addTime()}>Add Time</Button>
      </Tooltip>
    </div>
    <br/>
    <br/>
    {previewList && <React.Fragment>
      <Typography style={{ fontSize: 14, fontWeight: 500, marginBottom: 6 }}>
        Preview of the next 5 dates the scheduler will run on when saved
      </Typography>
      <Table style={{ width: '50%' }}>
        <TableHead>
          <TableRow>
            <TableCell className='table-cell'>Date</TableCell>
            <TableCell className='table-cell'>Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(previewList || []).map((preview, i) => {
            return <TableRow key={i}>
              <TableCell className='table-cell'><Typography style={{ fontSize: 14 }}>{preview.date}</Typography></TableCell>
              <TableCell className='table-cell'><Typography style={{ fontSize: 14 }}>{preview.time}</Typography></TableCell>
            </TableRow>
          })}
      </TableBody>
      </Table>
    </React.Fragment>}
    <Dialog open={openDisable} onClose={() => setOpenDisable(false)}>
      <DialogContent style={{ fontSize: 15 }}>
        Are you sure you want to disable the existing scheduled job?
      </DialogContent>
      <DialogActions>
        <Button size='small' onClick={() => { setOpenDisable(false); onDisable && onDisable(selectedTz) }}>Confirm</Button>
        <Button size='small' onClick={() => setOpenDisable(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
    <Dialog open={openEnable} onClose={() => setOpenEnable(false)}>
      <DialogContent style={{ fontSize: 15 }}>
        Are you sure you want to enable the existing scheduled job?
      </DialogContent>
      <DialogActions>
        <Button size='small' onClick={() => { setOpenEnable(false); onEnable && onEnable(selectedTz) }}>Confirm</Button>
        <Button size='small' onClick={() => setOpenEnable(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  </div>
}

export const CronSchedulerComponent = styled(_CronScheduler)`
  width: 100%;
  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }
  .time-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    .picker {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }
  }
  .time-field {
    width: 150px;
    input {
      padding: 2px 14px 6px;
      font-size: 15px;
    }
    legend {
      display: none;
    }
    button {
      padding: 6px 6px 10px;
    }
  }
  .table-cell {
    padding: 6px;
  }
  .add-icon {
    margin-right: 40px;
    padding: 2px;
    font-size: 12px;
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
  }
  .current-schedule {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 35px;
  }
`