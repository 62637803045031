import { API_NS } from '../../lib/api/endpoints/endpoint.types'

export const adminAction = {
  requestMethodGroupRole: () => {
    return { type: "REQUEST_METHOD_GROUP_ROLE" as const };
  },
  requestMethodGroupRoleSuccess: (payload: API_NS.MethodGroupRole[]) => {
    return { payload, type: "REQUEST_METHOD_GROUP_ROLE_SUCCESS" as const };
  },
  requestMethodGroupRoleFailed: (payload: string) => {
    return { payload, type: "REQUEST_METHOD_GROUP_ROLE_FAILED" as const };
  },

  addMethodGroupRole: (payload: API_NS.MethodGroupRole) => {
    return { payload, type: "ADD_METHOD_GROUP_ROLE" as const };
  },
  addMethodGroupRoleSuccess: (payload: API_NS.MethodGroupRole) => {
    return { payload, type: "ADD_METHOD_GROUP_ROLE_SUCCESS" as const };
  },
  addMethodGroupRoleFailed: (payload: string) => {
    return { payload, type: "ADD_METHOD_GROUP_ROLE_FAILED" as const };
  },

  deleteMethodGroupRole: (payload: { id: string, data: API_NS.MethodGroupRole }) => {
    return { payload, type: "DELETE_METHOD_GROUP_ROLE" as const };
  },
  deleteMethodGroupRoleSuccess: (payload) => {
    return {payload, type: "DELETE_METHOD_GROUP_ROLE_SUCCESS" as const };
  },
  deleteMethodGroupRoleFailed: (payload: string) => {
    return { payload, type: "DELETE_METHOD_GROUP_ROLE_FAILED" as const };
  },

  requestMethodGroups: () => {
    return { type: "REQUEST_METHOD_GROUPS" as const };
  },
  requestMethodGroupsSuccess: (payload: { [key: number]: string }) => {
    return { payload, type: "REQUEST_METHOD_GROUPS_SUCCESS" as const };
  },
  requestMethodGroupsFailed: (payload: string) => {
    return { payload, type: "REQUEST_METHOD_GROUPS_FAILED" as const };
  },

  requestRoles: () => {
    return { type: "REQUEST_ROLES" as const };
  },
  requestRolesSuccess: (payload: API_NS.Roles[]) => {
    return { payload, type: "REQUEST_ROLES_SUCCESS" as const };
  },
  requestRolesFailed: (payload: string) => {
    return { payload, type: "REQUEST_ROLES_FAILED" as const };
  },

  addNewRole: (payload: API_NS.Role ) => {
    return { payload, type: "ADD_NEW_ROLE" as const };
  },
  addNewRoleSuccess: (payload: API_NS.Roles) => {
    return {payload, type: "ADD_NEW_ROLE_SUCCESS" as const };
  },
  addNewRoleFailed: (payload: string) => {
    return { payload, type: "ADD_NEW_ROLE_FAILED" as const };
  },

  requestAllUsers: () => {
    return { type: "REQUEST_ALL_USERS" as const };
  },
  requestAllUsersSuccess: (payload: API_NS.Users[]) => {
    return { payload, type: "REQUEST_ALL_USERS_SUCCESS" as const };
  },
  requestAllUsersFailed: (payload: string) => {
    return { payload, type: "REQUEST_ALL_USERS_FAILED" as const };
  },

  addNewUser: (payload: API_NS.User ) => {
    return { payload, type: "ADD_NEW_USER" as const };
  },
  addNewUserSuccess: (payload: API_NS.Users) => {
    return {payload, type: "ADD_NEW_USER_SUCCESS" as const };
  },
  addNewUserFailed: (payload: string) => {
    return { payload, type: "ADD_NEW_USER_FAILED" as const };
  },

  deleteUser: (payload: { userId: string }) => {
    return { payload, type: "DELETE_USER" as const };
  },
  deleteUserSuccess: (payload: { userId: string }) => {
    return {payload, type: "DELETE_USER_SUCCESS" as const };
  },
  deleteUserFailed: (payload: string) => {
    return { payload, type: "DELETE_USER_FAILED" as const };
  },

  requestRoleUsers: (payload: { id: string }) => {
    return {payload, type: "REQUEST_ROLE_USERS" as const };
  },
  requestRoleUsersSuccess: (payload: API_NS.RoleUsers[]) => {
    return { payload, type: "REQUEST_ROLE_USERS_SUCCESS" as const };
  },
  requestRoleUsersFailed: (payload: string) => {
    return { payload, type: "REQUEST_ROLE_USERS_FAILED" as const };
  },

  addRoleUser: (payload: API_NS.RoleUserReq) => {
    return { payload, type: "ADD_ROLE_USER" as const };
  },
  addRoleUserSuccess: (payload: API_NS.RoleUserRes) => {
    return {payload, type: "ADD_ROLE_USER_SUCCESS" as const };
  },
  addRoleUserFailed: (payload: string) => {
    return { payload, type: "ADD_ROLE_USER_FAILED" as const };
  },
  
  deleteRoleUser: (payload: { id: string }) => {
    return { payload, type: "DELETE_ROLE_USER" as const };
  },
  deleteRoleUserSuccess: (payload: { id: string }) => {
    return {payload, type: "DELETE_ROLE_USER_SUCCESS" as const };
  },
  deleteRoleUserFailed: (payload: string) => {
    return { payload, type: "DELETE_ROLE_USER_FAILED" as const };
  },

  requestUserRoles: (payload: { id: string }) => {
    return {payload, type: "REQUEST_USER_ROLES" as const };
  },
  requestUserRolesSuccess: (payload: API_NS.UserRoles[]) => {
    return { payload, type: "REQUEST_USER_ROLES_SUCCESS" as const };
  },
  requestUserRolesFailed: (payload: string) => {
    return { payload, type: "REQUEST_USER_ROLES_FAILED" as const };
  },

  addUserRole: (payload: API_NS.RoleUserReq) => {
    return { payload, type: "ADD_USER_ROLE" as const };
  },
  addUserRoleSuccess: (payload: API_NS.RoleUserRes) => {
    return {payload, type: "ADD_USER_ROLE_SUCCESS" as const };
  },
  addUserRoleFailed: (payload: string) => {
    return { payload, type: "ADD_USER_ROLE_FAILED" as const };
  },
  
  updateUserRole: (payload: API_NS.UpdateRoleUser) => {
    return { payload, type: "UPDATE_USER_ROLE" as const };
  },
  updateUserRoleSuccess: (payload: API_NS.UpdateRoleUser) => {
    return {payload, type: "UPDATE_USER_ROLE_SUCCESS" as const };
  },
  updateUserRoleFailed: (payload: string) => {
    return { payload, type: "UPDATE_USER_ROLE_FAILED" as const };
  },

  deleteUserRole: (payload: { id: string }) => {
    return { payload, type: "DELETE_USER_ROLE" as const };
  },
  deleteUserRoleSuccess: (payload: { id: string }) => {
    return {payload, type: "DELETE_USER_ROLE_SUCCESS" as const };
  },
  deleteUserRoleFailed: (payload: string) => {
    return { payload, type: "DELETE_USER_ROLE_FAILED" as const };
  },

  clearRolesMessage: () => {
    return { type: "CLEAR_ROLES_MESSAGE" as const };
  },

  clearRoleUsersMessage: () => {
    return { type: "CLEAR_ROLE_USERS_MESSAGE" as const };
  },
  
  clearUsersMessage: () => {
    return { type: "CLEAR_USERS_MESSAGE" as const };
  },

  clearUserRolesMessage: () => {
    return { type: "CLEAR_USER_ROLES_MESSAGE" as const };
  },

  clearMethodGroupsMessage: () => {
    return { type: "CLEAR_METHOD_GROUPS_MESSAGE" as const };
  },

  clearMethodGroupRoleMessage: () => {
    return { type: "CLEAR_METHOD_GROUP_ROLE_MESSAGE" as const };
  },

}

export type TAdminAction = ReturnType<typeof adminAction[keyof typeof adminAction]>