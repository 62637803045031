import { combineEpics, Epic, } from "redux-observable"
import { TAdminAction, adminAction } from "../actions/admin.actions"
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators"
import { endpoints } from "../../lib/api/endpoints/endpoint_list"
import { of } from "rxjs"
import { TCombinedState } from '../index'
import { epicErrorLogger } from "../../lib/utils/epic-error-logger"

/**
 * Trigger API calls or any other async actions
 */
export const adminEpic: Epic<TAdminAction, any, TCombinedState> = combineEpics(

  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_METHOD_GROUP_ROLE' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.methodGroupRole.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return adminAction.requestMethodGroupRoleSuccess(epicRes.data.Payload)
          } else {
            return adminAction.requestMethodGroupRoleFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.requestMethodGroupRoleFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_METHOD_GROUPS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.methodGroups.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return adminAction.requestMethodGroupsSuccess(epicRes.data.Payload)
          } else {
            return adminAction.requestMethodGroupsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.requestMethodGroupsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_ROLES' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.roles.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return adminAction.requestRolesSuccess(epicRes.data.Payload)
          } else {
            return adminAction.requestRolesFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.requestRolesFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_NEW_ROLE' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.role.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return adminAction.addNewRoleSuccess(epicRes.data.Payload)
          } else {
            return adminAction.addNewRoleFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.addNewRoleFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_ALL_USERS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.users.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return adminAction.requestAllUsersSuccess(epicRes.data.Payload)
          } else {
            return adminAction.requestAllUsersFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.requestAllUsersFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_NEW_USER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.newUser.post(a.payload).pipe(
        mergeMap(epicRes => {
          if (epicRes.status === 200) {
            const successAction = adminAction.addNewUserSuccess(epicRes.data.Payload)

            const fetchAction = adminAction.requestAllUsers()
            return [successAction, fetchAction]
          } else {
            return [adminAction.addNewUserFailed(epicRes.data?.error as string || '')]
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.addNewUserFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_USER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteUser.delete({ userId: a.payload.userId }).pipe(
        mergeMap(epicRes => {
          if (epicRes.status === 200) {
            const successAction = adminAction.deleteUserSuccess({ userId: a.payload.userId })

            const fetchAction = adminAction.requestAllUsers()
            return [successAction, fetchAction]
          } else {
            return [adminAction.deleteUserFailed(epicRes.data?.error as string || '')]
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.deleteUserFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_METHOD_GROUP_ROLE' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.addMethodGroupRole.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200 || epicRes.status === 202) {
            return adminAction.addMethodGroupRoleSuccess(epicRes.data.Payload)
          } else {
            return adminAction.addMethodGroupRoleFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.addMethodGroupRoleFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_METHOD_GROUP_ROLE' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteMethodGroupRole.delete({ id: a.payload.id }).pipe(
        map(epicRes => {
          if (epicRes.status === 200 || epicRes.status === 202) {
            return adminAction.deleteMethodGroupRoleSuccess(a.payload.data)
          } else {
            return adminAction.deleteMethodGroupRoleFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.deleteMethodGroupRoleFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_ROLE_USERS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.roleUsers.get({ id: a.payload.id }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return adminAction.requestRoleUsersSuccess(epicRes.data.Payload)
          } else {
            return adminAction.requestRoleUsersFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.requestRoleUsersFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_ROLE_USER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.addRoleUser.post(a.payload).pipe(
        mergeMap(epicRes => {
          if (epicRes.status === 200) {
            const successAction = adminAction.addRoleUserSuccess(epicRes.data.Payload)

            if (!a.payload.RoleId) {
              const failAction = adminAction.requestRoleUsersFailed('There was a problem accessing the database. Please try again later or contact the IT department')
              return [successAction, failAction]
            }

            const fetchAction = adminAction.requestRoleUsers({ id: a.payload.RoleId.toString() })

            return [successAction, fetchAction]
          } else {
            return [adminAction.addRoleUserFailed(epicRes.data?.error as string || '')]
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.addRoleUserFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_ROLE_USER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteRoleUser.delete({ userId: a.payload.id }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return adminAction.deleteRoleUserSuccess({ id: a.payload.id })
          } else {
            return adminAction.deleteRoleUserFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.deleteRoleUserFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_USER_ROLES' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.userRoles.get({ id: a.payload.id }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return adminAction.requestUserRolesSuccess(epicRes.data.Payload)
          } else {
            return adminAction.requestUserRolesFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.requestUserRolesFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_USER_ROLE' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.addRoleUser.post(a.payload).pipe(
        mergeMap(epicRes => {
          if (epicRes.status === 200) {
            const successAction = adminAction.addUserRoleSuccess(epicRes.data.Payload)

            if (!a.payload.InternalUserId) {
              const failAction = adminAction.requestUserRolesFailed('There was a problem accessing the database. Please try again later or contact the IT department')
              return [successAction, failAction]
            }

            const fetchAction = adminAction.requestUserRoles({ id: a.payload.InternalUserId.toString() })

            return [successAction, fetchAction]
          } else {
            return [adminAction.addUserRoleFailed(epicRes.data?.error as string || '')]
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.addUserRoleFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_USER_ROLE' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateRoleUser.put(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return adminAction.updateUserRoleSuccess(a.payload)
          } else {
            return adminAction.updateUserRoleFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.addUserRoleFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_USER_ROLE' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteRoleUser.delete({ userId: a.payload.id }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return adminAction.deleteUserRoleSuccess({ id: a.payload.id })
          } else {
            return adminAction.deleteUserRoleFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(adminAction.deleteUserRoleFailed(epicError))
        })
      )
    })
  ),
)