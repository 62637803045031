import { AccountInfo } from '@azure/msal-browser';
import { AxiosRequestConfig } from 'axios';
import { Axios } from 'axios-observable';
import { reduxStore } from 'src/store';
import { loginRequest, msalInstance } from '../services/user-auth';
import { isRunningInJsDom } from '../utils/test.utils';

const baseURL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PORT !== "0" ? ":" + process.env.REACT_APP_API_PORT : ""}`;

export const baseAxiosConfig: AxiosRequestConfig = {
    timeout: 30000,
    baseURL,
}

/**
 * configurable instance of Axios
 */
const httpClient = Axios.create(baseAxiosConfig)

/**
 * attach interceptors to outgoing API requests.
 */
httpClient.interceptors.request.use(
    /**attach bearer token to outgoing API requests */
    async req => {
        let account: AccountInfo | null = (msalInstance.getAllAccounts() || [])[0] || reduxStore.getState().userState.user as AccountInfo | null
        const runningJSDom = isRunningInJsDom()

        if (!account && !runningJSDom) {
            const loginRes = await msalInstance.loginPopup()
            account = loginRes.account
        }
        
        if (!account) {
            console.warn('could not retrieve an account')
            req.headers = {
                ...req.headers,
                "Content-Type": "application/json",
            }
            return req
        }
    
        let token: string = ''
        try {
            token = (await msalInstance.acquireTokenSilent({ ...loginRequest, account })).idToken
        } catch (acquireTokenSilentError) {
            console.warn({ acquireTokenSilentError })
            const loginRes = await msalInstance.loginPopup()
            account = loginRes.account
            if(!account) {
                req.headers = {
                    ...req.headers,
                    "Content-Type": "application/json",
                }
                return req
            }
            token = (await msalInstance.acquireTokenSilent({ ...loginRequest, account })).idToken
        }

        req.headers = {
            ...req.headers,
            "authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        }

        return req
    }
)

/**
 * attach interceptors to incomming API responses.
 */
httpClient.interceptors.response.use(
    /**on successful response validate the body object. Then let the response pass through to the app */
    res => {
        return res
    },

    /**
     * on error response read the response body and show a warning before letting the error pass through to app 
     * this does not include runtype checks because are they are not related to the HTTP request
     * */
    errorResponse => {
        const url = errorResponse.config.url
        const status = errorResponse.response.status

        const defaultErrorMessage =
            errorResponse.response?.data?.InnerException?.Message ||
            errorResponse.response?.data?.Message ||
            errorResponse.message

        console.warn(`Error ${status}: ${defaultErrorMessage}`, url)

        throw defaultErrorMessage
    }
)

export { httpClient }