import { combineEpics, Epic, } from "redux-observable"
import { TReportsAction, reportsAction } from "../actions/reports.actions"
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators"
import { endpoints } from "../../lib/api/endpoints/endpoint_list"
import { of } from "rxjs"
import { TCombinedState } from '../index'
import { epicErrorLogger } from "../../lib/utils/epic-error-logger"

/**
 * Trigger API calls or any other async actions
 */
export const reportsEpic: Epic<TReportsAction, any, TCombinedState> = combineEpics(

  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_REPORTS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.reports.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.requestReportsSuccess(epicRes.data.Payload)
          } else {
            return reportsAction.requestReportsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.requestReportsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_COUNTRIES' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.countries.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.requestCountriesSuccess(epicRes.data.Payload)
          } else {
            return reportsAction.requestCountriesFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.requestCountriesFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_TRANSLATIONS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.translations.get({ report: a.payload.report, languageCode: a.payload.languageCode }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.requestTranslationsSuccess(epicRes.data.Payload)
          } else {
            return reportsAction.requestTranslationsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.requestTranslationsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_DEFAULT_TRANSLATIONS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.translations.get({ report: a.payload.report, languageCode: 'UK' }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.requestDefaultTranslationsSuccess(epicRes.data.Payload)
          } else {
            return reportsAction.requestDefaultTranslationsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.requestDefaultTranslationsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_TRANSLATIONS_ELEMENTS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.translationElements.get({ report: a.payload.report }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.requestTranslationElementsSuccess(epicRes.data.Payload)
          } else {
            return reportsAction.requestTranslationElementsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.requestTranslationElementsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'SUBMIT_TRANSLATIONS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.submitTranslations.post(a.payload.body, { report: a.payload.report, languageCode: a.payload.languageCode }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.submitTranslationsSuccess(a.payload.body)
          } else {
            return reportsAction.submitTranslationsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.submitTranslationsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_ALL_PRODUCTS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.allProducts.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.requestAllProductsSuccess(epicRes.data.Payload)
          } else {
            return reportsAction.requestAllProductsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.requestAllProductsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_PRODUCTS_LIST' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.productsList.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.requestProductsListSuccess(epicRes.data.Payload)
          } else {
            return reportsAction.requestProductsListFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.requestProductsListFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_ALLTECH_PRODUCT' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateAlltechProduct.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.updateAlltechProductSuccess(a.payload)
          } else {
            return reportsAction.updateAlltechProductFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.updateAlltechProductFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_PRODUCT_UOM' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.productsUom.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.requestProductUomSuccess(epicRes.data.Payload)
          } else {
            return reportsAction.requestProductUomFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.requestProductUomFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_ALL_PRODUCT_USAGE' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.allProductUsage.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.requestAllProductUsageSuccess(epicRes.data.Payload)
          } else {
            return reportsAction.requestAllProductUsageFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.requestAllProductUsageFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPSERT_ALL_PRODUCT_USAGE' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.upsertProductUsage.post(a.payload).pipe(
        mergeMap(epicRes => {
          if (epicRes.status === 200) {
            const successAction = reportsAction.upsertAllProductUsageSuccess(epicRes.data.Payload)

            const fetchAction = reportsAction.requestAllProductUsage()
            return [successAction, fetchAction]
          } else {
            return [reportsAction.upsertAllProductUsageFailed(epicRes.data?.error as string || '')]
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.upsertAllProductUsageFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'DELETE_PRODUCT_USAGE' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.deleteProductUsage.delete({ id: a.payload.id.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return reportsAction.deleteProductUsageSuccess(a.payload.id)
          } else {
            return reportsAction.deleteProductUsageFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(reportsAction.deleteProductUsageFailed(epicError))
        })
      )
    })
  ),
)