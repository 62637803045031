import { TAdminAction } from "../actions/admin.actions"
import { Reducer } from "redux"
import { API_NS } from "../../lib/api/endpoints/endpoint.types"
import { TStateAction } from "../actions/actions"
import { AsyncProcessState } from "../types/shared-state-types"

export type adminReducerState = {
  methodGroupRole?: AsyncProcessState<API_NS.MethodGroupRole[]>;
  methodGroups?: AsyncProcessState<{ [key: number]: string }>;
  roles?: AsyncProcessState<API_NS.Roles[]>;
  users?: AsyncProcessState<API_NS.Users[]>;
  roleUsers?: AsyncProcessState<API_NS.RoleUsers[]>;
  userRoles?: AsyncProcessState<API_NS.UserRoles[]>;
}

const defaultValue: adminReducerState = {
}

const resetState = JSON.parse(JSON.stringify(defaultValue))

export const adminReducer: Reducer<adminReducerState, TAdminAction | TStateAction> = (state = defaultValue, action) => {
  switch (action.type) {
    case "REQUEST_METHOD_GROUP_ROLE":
      return {
        ...state,
        methodGroupRole: { loading: true, message: undefined }
      };
    case "REQUEST_METHOD_GROUP_ROLE_SUCCESS":
      return {
        ...state,
        methodGroupRole: { loaded: true, loading: false, value: action.payload, message: undefined }
      };
    case "REQUEST_METHOD_GROUP_ROLE_FAILED":
      return {
        ...state,
        methodGroupRole: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_METHOD_GROUP_ROLE":
      return {
        ...state,
        methodGroupRole: { ...state.methodGroupRole, loading: true, message: undefined }
      };
    case "ADD_METHOD_GROUP_ROLE_SUCCESS":
      return {
        ...state,
        methodGroupRole: { loaded: true, loading: false, value: [...(state.methodGroupRole?.value || []), action.payload ], message: undefined }
      };
    case "ADD_METHOD_GROUP_ROLE_FAILED":
      return {
        ...state,
        methodGroupRole: { ...state.methodGroupRole, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding permissions'} }
      };
  
    case "DELETE_METHOD_GROUP_ROLE":
      return {
        ...state,
        methodGroupRole: { ...state.methodGroupRole, loading: true, message: undefined }
      };
    case "DELETE_METHOD_GROUP_ROLE_SUCCESS":
      return {
        ...state,
        methodGroupRole: {
          loaded: true,
          loading: false,
          value: (state.methodGroupRole?.value || []).filter(m => !(m.RoleId === action.payload.RoleId && m.MethodGroupName === action.payload.MethodGroupName)),
          message: undefined
        }
      };
    case "DELETE_METHOD_GROUP_ROLE_FAILED":
      return {
        ...state,
        methodGroupRole: { ...state.methodGroupRole, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while removing permissions'} }
      };

    case "REQUEST_METHOD_GROUPS":
      return {
        ...state,
        methodGroups: { loading: true, message: undefined }
      };
    case "REQUEST_METHOD_GROUPS_SUCCESS":
      return {
        ...state,
        methodGroups: { loaded: true, loading: false, value: action.payload, message: undefined }
      };
    case "REQUEST_METHOD_GROUPS_FAILED":
      return {
        ...state,
        methodGroups: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };
    
    case "REQUEST_ROLES":
      return {
        ...state,
        roles: { loading: true, message: undefined }
      };
    case "REQUEST_ROLES_SUCCESS":
      return {
        ...state,
        roles: { loaded: true, loading: false, value: action.payload, message: undefined }
      };
    case "REQUEST_ROLES_FAILED":
      return {
        ...state,
        roles: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };
  
    case "ADD_NEW_ROLE":
      return {
        ...state,
        roles: { ...state.roles, loading: true, message: undefined }
      };
    case "ADD_NEW_ROLE_SUCCESS":
      return {
        ...state,
        roles: {
          ...state.roles,
          loaded: true,
          loading: false,
          value: [...(state.roles?.value || []), action.payload].sort((a, b) => a.RoleName.toLowerCase() > b.RoleName.toLowerCase() ? 1 : -1),
          message: {level: 'success', msg: 'Role ahs been added'} }
      };
    case "ADD_NEW_ROLE_FAILED":
      return {
        ...state,
        roles: { ...state.roles, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new role'} }
      };

    case "REQUEST_ALL_USERS":
      return {
        ...state,
        users: { loading: true, message: undefined }
      };
    case "REQUEST_ALL_USERS_SUCCESS":
      return {
        ...state,
        users: { ...state.users, loaded: true, loading: false, value: action.payload.sort((a, b) => a.Login.toLowerCase() > b.Login.toLowerCase() ? 1 : -1), message: undefined }
      };
    case "REQUEST_ALL_USERS_FAILED":
      return {
        ...state,
        users: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_NEW_USER":
      return {
        ...state,
        users: { ...state.users, loading: true, message: undefined }
      };
    case "ADD_NEW_USER_SUCCESS":
      return {
        ...state,
        users: { ...state.users, loaded: true, loading: false, value: [...(state.users?.value || []), action.payload].sort((a, b) => a.Login.toLowerCase() > b.Login.toLowerCase() ? 1 : -1), message: {level: 'success', msg: 'User has been added'} }
      };
    case "ADD_NEW_USER_FAILED":
      return {
        ...state,
        users: { ...state.users, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new user'} }
      };

    case "DELETE_USER":
      return {
        ...state,
        users: { ...state.users, loading: true, message: undefined }
      };
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        users: {
          ...state.users,
          value: (state.users?.value || []).filter(u => u.InternalUserId !== parseInt(action.payload.userId)),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'User has been deleted'} }
      };
    case "DELETE_USER_FAILED":
      return {
        ...state,
        users: { ...state.users, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while deleting user'} }
      };
  
    case "REQUEST_ROLE_USERS":
      return {
        ...state,
        roleUsers: { loading: true, message: undefined }
      };
    case "REQUEST_ROLE_USERS_SUCCESS":
      return {
        ...state,
        roleUsers: { loaded: true, loading: false, value: action.payload.sort((a, b) => a.InternalUser?.Login?.toLowerCase() > b.InternalUser?.Login?.toLowerCase() ? 1 : -1), message: undefined }
      };
    case "REQUEST_ROLE_USERS_FAILED":
      return {
        ...state,
        roleUsers: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_ROLE_USER":
      return {
        ...state,
        roleUsers: { ...state.roleUsers, loading: true, message: undefined }
      };
    case "ADD_ROLE_USER_SUCCESS":
      return {
        ...state,
        roleUsers: { ...state.roleUsers, loading: false, loaded: true, message: {level: 'success', msg: 'Role user has been added'} }
      };
    case "ADD_ROLE_USER_FAILED":
      return {
        ...state,
        roleUsers: { ...state.roleUsers, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new role user'} }
      };

    case "DELETE_ROLE_USER":
      return {
        ...state,
        roleUsers: { ...state.roleUsers, loading: true, message: undefined }
      };
    case "DELETE_ROLE_USER_SUCCESS":
      return {
        ...state,
        roleUsers: {
          ...state.roleUsers,
          loaded: true,
          loading: false,
          value: (state.roleUsers?.value || []).filter(u => u.UserRoleId !== parseInt(action.payload.id)),
          message: {level: 'success', msg: 'Role user has been deleted'}
        }
      };
    case "DELETE_ROLE_USER_FAILED":
      return {
        ...state,
        roleUsers: { ...state.roleUsers, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while deleting role user'} }
      };
  
    case "REQUEST_USER_ROLES":
      return {
        ...state,
        userRoles: { loading: true, message: undefined }
      };
    case "REQUEST_USER_ROLES_SUCCESS":
      return {
        ...state,
        userRoles: { loaded: true, loading: false, value: action.payload, message: undefined }
      };
    case "REQUEST_USER_ROLES_FAILED":
      return {
        ...state,
        userRoles: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "ADD_USER_ROLE":
      return {
        ...state,
        userRoles: { ...state.userRoles, loading: true, message: undefined }
      };
    case "ADD_USER_ROLE_SUCCESS":
      return {
        ...state,
        userRoles: { ...state.userRoles, loading: false, loaded: true, message: {level: 'success', msg: 'User role has been added'} }
      };
    case "ADD_USER_ROLE_FAILED":
      return {
        ...state,
        userRoles: { ...state.userRoles, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding new user role'} }
      };

    case "UPDATE_USER_ROLE":
      return {
        ...state,
        userRoles: {...state.userRoles, loading: true, errorMessage: undefined }
      };
    case "UPDATE_USER_ROLE_SUCCESS":
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          value: (state.userRoles?.value || []).map(ur => {
            return action.payload?.UserRoleId === ur.UserRoleId ? {...ur, HasWriteAccess: !ur.HasWriteAccess} : ur
          }),
          loading: false,
          loaded: true,
          message: {level: 'success', msg: 'User role has been updated'}
        }
      };
    case "UPDATE_USER_ROLE_FAILED":
      return {
        ...state,
        userRoles: { ...state.userRoles, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while updating user role'} }
      };
        
    case "DELETE_USER_ROLE":
      return {
        ...state,
        userRoles: { ...state.userRoles, loading: true, message: undefined }
      };
    case "DELETE_USER_ROLE_SUCCESS":
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          loaded: true,
          loading: false,
          value: (state.userRoles?.value || []).filter(u => u.UserRoleId !== parseInt(action.payload.id)),
          message: {level: 'success', msg: 'User role has been deleted'}
        }
      };
    case "DELETE_USER_ROLE_FAILED":
      return {
        ...state,
        userRoles: { ...state.userRoles, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while deleting user role'} }
      };

    case 'CLEAR_ROLES_MESSAGE':
      return {
        ...state,
        roles: { ...state.roles, message: undefined }
      }
      
    case 'CLEAR_ROLE_USERS_MESSAGE':
      return {
        ...state,
        roleUsers: { ...state.roleUsers, message: undefined }
      }

    case 'CLEAR_USERS_MESSAGE':
      return {
        ...state,
        users: { ...state.users, message: undefined }
      }
      
    case 'CLEAR_USER_ROLES_MESSAGE':
      return {
        ...state,
        userRoles: { ...state.userRoles, message: undefined }
      }

    case 'CLEAR_METHOD_GROUPS_MESSAGE':
      return {
        ...state,
        methodGroups: { ...state.methodGroups, errorMessage: undefined }
      }
      
    case 'CLEAR_METHOD_GROUP_ROLE_MESSAGE':
      return {
        ...state,
        methodGroupRole: { ...state.methodGroupRole, errorMessage: undefined }
      }

    case 'RESET_STATE':
      return {
        ...resetState,
      };
  }
  return state
}
