import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { Button, Dialog, DialogActions, DialogContent, Grow, Paper, TextField, Tooltip } from '@mui/material'
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { reportsAction } from 'src/store/actions/reports.actions'
import { BinIcon } from 'src/components/icons/bin'
import { ActiveFilter, AddRecordContainer, ProductFilter, UomFilter } from './components'
import { useIntl } from 'react-intl'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { DatePicker } from '@mui/x-date-pickers'
import { CalendarIcon } from 'src/components/icons/calendar'
import { StyledPopper } from '../../rpm/rpm-validation'
import moment from 'moment'
import { FilterIcon } from 'src/components/icons/filter'
import { ActiveFilterIcon } from 'src/components/icons/active-filter'

export type proptypes = {}
export const defaultProps: proptypes = {}

const _ProductUsage: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [recordToDelete, setRecordToDelete] = useState<API_NS.ProductUsage | undefined>()
  const [activeFilterVal, setActiveFilterVal] = useState<string | undefined>()
  const [productFilterVal, setProductFilterVal] = useState<API_NS.AlltechProduct | undefined>()
  const [uomFilterVal, setUomFilterVal] = useState<number | undefined>()
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { setMessage } = useContext(SnackBarContext)
  const alltechProducts = useTypedAppSelector(s => s.reportsState.alltechProducts)
  const allProductUsage = useTypedAppSelector(s => s.reportsState.allProductUsage)
  const productUoms = useTypedAppSelector(s => s.reportsState.productUoms)
  const upsertProductUsageLoading = useTypedAppSelector(s => s.reportsState.upsertProductUsageLoading)
  const deleteProductUsageLoading = useTypedAppSelector(s => s.reportsState.deleteProductUsageLoading)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const dispatch = useDispatch()
  const apiRef = useGridApiRef()
  const intl = useIntl()
  const CustomGridActionsCellItem = GridActionsCellItem as any
  
  const updateEndDate = (row: API_NS.ProductUsage, date) => {
    if (!date) return

    dispatch(reportsAction.upsertAllProductUsage({ 
      AlltechProductGroupUsageId: row.AlltechProductGroupUsageId,
      Username: row.Username,
      AlltechProductGroupId: row.AlltechProductGroupId,
      StartDate: moment(row.StartDate).format('YYYYMMDD'),
      EndDate: moment(date).format('YYYYMMDD'),
      Quantity: row.Quantity,
      AlltechProductUoMId: row.AlltechProductUoMId
    }))
  }
  
  const columns = [
    {
      field: 'Username',
      headerName: 'Client',
      flex: 0.6,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1
    },
    {
      field: 'AlltechProductGroupId',
      headerName: 'Product Name',
      flex: 1,
      valueGetter: (p: any) => {
        const d = p.row.AlltechProductGroupId as number
        const product = d ? (alltechProducts?.value || []).find(a => a.AlltechProductGroupId === d) : null
        return product ? product.AlltechProductGroupName : '-'
      },
      sortComparator: (a, b) => {
        return a?.toLowerCase() < b?.toLowerCase() ? -1 : 1
      }
    },
    {
      field: 'StartDate',
      headerName: 'Start Date',
      flex: 0.5,
      valueFormatter: (p: any) => {
        const d = p.value as string
        return d ? intl.formatDate(d, { year: 'numeric', month: '2-digit', day: '2-digit' }) : '-'
      }
    },
    {
      field: 'EndDate',
      headerName: 'End Date',
      flex: 0.5,
      renderCell: (p: GridRenderCellParams) => <div style={{ width: '100%' }}>
        <DatePicker
          components={{ OpenPickerIcon: CalendarIcon }}
          orientation='landscape'
          value={p.row.EndDate}
          views={['day']}
          disableMaskedInput
          disableHighlightToday
          inputFormat='dd/MM/yyyy'
          onChange={e => updateEndDate(p.row, e)}
          renderInput={(params) => <TextField {...params} variant='standard' sx={{ '.MuiInputBase-input': { fontSize: '14px' } }} />}
        />
      </div>
    },
    {
      field: 'Quantity',
      headerName: 'Fed Per Head',
      flex: 0.5,
      valueFormatter: (p: any) => {
        const val = p.value as number
        return val || val === 0 ? val : '-'
      }
    },
    {
      field: 'AlltechProductUoMId',
      headerName: 'UOM',
      flex: 0.6,
      valueGetter: (p: any) => {
        const d = p.row.AlltechProductUoMId as number
        const uom = d || d === 0 ? (productUoms?.value || []).find(a => a.AlltechProductGroupUoMId === d) : null
        return uom ? uom.UoMName : '-'
      }
    },
    {
      field: 'active',
      headerName: 'Is Active',
      flex: 0.3,
      minWidth: 50,
      headerAlign: 'center',
      valueGetter: (p: any) => {
        return p.row.EndDate && moment().isAfter(moment(p.row.EndDate)) ? 'No' : 'Yes'
      },
      renderCell: (p: GridRenderCellParams) => <div style={{ width: '100%', textAlign: 'center' }}>
        {p.row.EndDate && moment().isAfter(moment(p.row.EndDate)) ? 'No' : 'Yes' }
      </div>,
      sortComparator: (a, b) => {
        return a?.toLowerCase() < b?.toLowerCase() ? -1 : 1
      }
    },
    {
      field: 'delete',
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={<Tooltip title='Remove Record' followCursor><span><BinIcon className='primary-color' size={25} /></span></Tooltip>}
          label='Remove Record'
          onClick={() => { setRecordToDelete(p.row); setOpenDelete(true) }}
          disabled={hasAccess}
        />
      ]
    }
  ]

  const deleteRecord = () => {
    recordToDelete && dispatch(reportsAction.deleteProductUsage({ id: recordToDelete.AlltechProductGroupUsageId }))
    setRecordToDelete(undefined)
  }

  useEffect(() => {
    setLoading(alltechProducts?.loading || allProductUsage?.loading || upsertProductUsageLoading || productUoms?.loading || deleteProductUsageLoading ? true : false)
  }, [alltechProducts?.loading, allProductUsage?.loading, upsertProductUsageLoading, productUoms?.loading, deleteProductUsageLoading])

  useEffect(() => {
    alltechProducts?.message && setMessage({ message: alltechProducts.message.msg, severity: alltechProducts.message.level, clear: () => dispatch(reportsAction.clearAlltechProductsMessage()) })
    allProductUsage?.message && setMessage({ message: allProductUsage.message.msg, severity: allProductUsage.message.level, clear: () => dispatch(reportsAction.clearAllProductUsageMessage()) })
    productUoms?.message && setMessage({ message: productUoms.message.msg, severity: productUoms.message.level, clear: () => dispatch(reportsAction.clearProductUomMessage()) })
  }, [alltechProducts?.message, allProductUsage?.message, productUoms?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('reports', 31, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    dispatch(reportsAction.requestProductsList())
    dispatch(reportsAction.requestAllProductUsage())
    dispatch(reportsAction.requestProductUom())
  }, [])

  const deleteRecordDialog = <Dialog fullWidth open={openDelete} onClose={() => setOpenDelete(false)}>
    <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this record?</DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenDelete(false); deleteRecord() }}>Confirm</Button>
      <Button size='small' onClick={() => setOpenDelete(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>

  const headerMenu = () => <React.Fragment>
    <Tooltip title='Filter' followCursor>
      <Button className='filter-button' color='primary' ref={anchorRef} onClick={() => setOpenFilter(!openFilter)}>
        {activeFilterVal || uomFilterVal || productFilterVal ? <ActiveFilterIcon size={26} /> : <FilterIcon size={26} />}
      </Button>
    </Tooltip>
    <StyledPopper open={openFilter} anchorEl={anchorRef.current} placement='bottom-start' style={{ zIndex: 9999, width: '300px' }} transition>
      {({ TransitionProps }) => (<Grow {...TransitionProps} timeout={200}>
          <Paper sx={{ bgcolor: 'white', padding: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px', padding: '8px 0' }}>
              <ActiveFilter filter={activeFilterVal} setFilter={setActiveFilterVal} />
              <UomFilter filter={uomFilterVal} setFilter={setUomFilterVal} options={productUoms?.value} />
              <ProductFilter filter={productFilterVal} setFilter={setProductFilterVal} options={alltechProducts?.value} />
            </div>
          </Paper>
      </Grow>)}
    </StyledPopper>
  </React.Fragment>

  return <Paper className={className}>
    <AddRecordContainer
      alltechProducts={alltechProducts?.value}
      allProductUsage={allProductUsage}
      uomList={productUoms?.value}
      loading={alltechProducts?.loading || allProductUsage?.loading || upsertProductUsageLoading || productUoms?.loading || deleteProductUsageLoading}
      hasAccess={hasAccess}
    />
    <DataGridComponent
      title=' '
      data={(allProductUsage?.value || [])
        .filter(a => (uomFilterVal ? a.AlltechProductUoMId === uomFilterVal : true) && (activeFilterVal ? (a.EndDate && moment().isAfter(moment(a.EndDate)) ? 'No' : 'Yes') === activeFilterVal : true) && (productFilterVal?.AlltechProductGroupId ? a.AlltechProductGroupId === productFilterVal?.AlltechProductGroupId : true))
        .map((a, i) => ({ ...a, id: i }))
      }
      columns={columns as any}
      apiRef={apiRef}
      rowHeight={45}
      rowsPerPage={25}
      headerMenu={headerMenu}
      loading={alltechProducts?.loading || allProductUsage?.loading || upsertProductUsageLoading || productUoms?.loading || deleteProductUsageLoading}
      defaultState={{ sorting: { sortModel: [{ field: 'Username', sort: 'asc' }] } }}
    />
    {deleteRecordDialog}
  </Paper>
}

export const ProductUsageComponent = styled(_ProductUsage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  .primary-color {
    color: ${p => p.theme.palette.alltech.terracotta.main};
  }
  .filter-button {
    border-radius: 20px;
  }
`
export default ProductUsageComponent