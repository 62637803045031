import React, { useState } from 'react';

const EnabledSecurityContext = React.createContext();

const EnabledSecurityContextProvider = (props) => {
  const [enabledSecurity, _setEnabledSecurity] = useState(true)

  const setEnabledSecurity = es => _setEnabledSecurity(es)

  return <EnabledSecurityContext.Provider value={{ enabledSecurity, setEnabledSecurity }}>
    {props.children}
  </EnabledSecurityContext.Provider>
}

export { EnabledSecurityContext, EnabledSecurityContextProvider };