/**
 * Each main sections and sub sections feature ID
 * 
 * `ADMIN (22)`  
 * 22: Permissions, User Role Management, User Management, Role Management
 * 
 * `INTOUCH`  
 * 1: Feed Library  
 * 18: Provider Mapping  
 * 19: UoM Mapping  
 * 26: API Management  
 * 27: Accuracy Report, Configurations  
 * 28: Contracts  
 * 29: Demo Users  
 * 
 * `RPM`  
 * 17: RPM Validation
 * 
 * SALES`  
 * 20: Trade-ins  
 * 24: Sales Targets  
 * 25: Authorise Order  
 * 
 * `REPORTS (30)`  
 * 30:  Email Translations
 * 31:  Alltech Products
 * 
 */
export const allFeatures = {
  admin: [ 22 ],
  intouch: [ 1, 18, 19, 26, 27, 28, 29 ],
  rpm: [ 17 ],
  sales: [ 20, 24, 25 ],
  reports: [ 30, 31 ]
}