import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Typography } from "@mui/material"
import { StyledPopper } from "../../rpm/rpm-validation"

export const reportsSelection = (reports, selectedReport, anchorRef, openReport, setOpenReport, handleReportChange, hasAccess) => {
  return (reports || []).length > 0 ? <div className='selection-form'>
    <Typography style={{ fontSize: 11, visibility: (selectedReport?.ReportName || '').length > 0 ? 'visible' : 'hidden' }}>Choose Report</Typography>
    <Button
      className='reports-dropdown'
      ref={anchorRef}
      color='secondary'
      variant='outlined'
      size='small'
      onClick={() => setOpenReport(!openReport)}
      endIcon={openReport ? <ArrowDropUp /> : <ArrowDropDown />}
      disabled={hasAccess}
    >
      {(selectedReport?.ReportName || '').length > 0 ? selectedReport?.ReportName  === 'ExecutionRpm' ? 'Dairy RPM' : selectedReport?.ReportName : 'Choose Report'}
    </Button>
    <StyledPopper
      open={openReport}
      anchorEl={anchorRef.current}
      placement='bottom-start'
      style={{ minWidth: anchorRef.current?.offsetWidth || '100%' }}
      transition
    >
      {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
        <Paper sx={{ bgcolor: 'white' }}>
          <ClickAwayListener onClickAway={() => setOpenReport(false)}>
            <MenuList autoFocusItem={openReport} style={{ maxHeight: 300, overflowY: 'auto', padding: '4px 0' }} variant='menu'>
              {reports.map((r, i) => (
                <MenuItem key={i} value={r.ReportId} onClick={() => handleReportChange(r)} selected={(selectedReport?.ReportName || '').includes(r.ReportName)}>
                  {r.ReportName === 'ExecutionRpm' ? 'Dairy RPM' : r.ReportName}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>}
    </StyledPopper>
  </div> : undefined
}

export const countriesSelection = (countries, selectedCountry, anchorRef, openCountry, setOpenCountry, handleCountryChange, hasAccess) => {
  return (countries || []).length > 0 ? <div className='selection-form'>
    <Typography style={{ fontSize: 11, visibility: (selectedCountry?.CountryName || '').length > 0 ? 'visible' : 'hidden' }}>Choose Country</Typography>
    <Button
      className='countries-dropdown'
      ref={anchorRef}
      color='secondary'
      variant='text'
      size='small'
      onClick={() => setOpenCountry(!openCountry)}
      endIcon={openCountry ? <ArrowDropUp /> : <ArrowDropDown />}
      disabled={hasAccess}
    >
      {(selectedCountry?.CountryName || '').length > 0 ? selectedCountry?.CountryName : 'Choose Country'}
    </Button>
    <StyledPopper
      open={openCountry}
      anchorEl={anchorRef.current}
      placement='bottom-start'
      style={{ minWidth: anchorRef.current?.offsetWidth || '100%' }}
      transition
    >
      {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
        <Paper sx={{ bgcolor: 'white' }}>
          <ClickAwayListener onClickAway={() => setOpenCountry(false)}>
            <MenuList autoFocusItem={openCountry} style={{ maxHeight: 300, overflowY: 'auto', padding: '4px 0' }} variant='menu'>
              {countries.map((c, i) => (
                <MenuItem key={i} value={c.CountryName} onClick={() => handleCountryChange(c)} selected={(selectedCountry?.CountryName || '').includes(c.CountryName)}>
                  {c.CountryName}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>}
    </StyledPopper>
  </div> : undefined
}
