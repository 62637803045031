import React, { useContext, useEffect, useState } from 'react'
import { useTypedAppSelector } from 'src/store'
import styled from 'styled-components'
import { Avatar, Button, Chip, darken, Dialog, DialogActions, DialogContent, DialogTitle, Fab, LinearProgress, MenuItem, MenuList, Paper, TextField, Tooltip, Typography } from '@mui/material'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { Add } from '@mui/icons-material'
import { adminAction } from 'src/store/actions/admin.actions'
import { useDispatch } from 'react-redux'
import { BinIcon } from 'src/components/icons/bin'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { SnackBarContext } from 'src/contexts/snack-bar.context'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _RoleManagement: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [role, setRole] = useState<API_NS.Roles | null>(null)
  const [userToDelete, setUserToDelete] = useState<API_NS.RoleUsers | null>(null)
  const [newRoleStr, setNewRoleStr] = useState<string>('')
  const [searchUser, setSearchUser] = useState<string>('')
  const [searchUserList, setSearchUserList] = useState<string>('')
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [openAdd, setOpenAdd] = useState<boolean>(false)
  const [openNewRole, setOpenNewRole] = useState<boolean>(false)
  const users = useTypedAppSelector(s => s.adminState.users)
  const roles = useTypedAppSelector(s => s.adminState.roles)
  const roleUsers = useTypedAppSelector(s => s.adminState.roleUsers)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { setMessage } = useContext(SnackBarContext)
  const dispatch = useDispatch()

  const chooseRole = r => {
    setRole(r)
    dispatch(adminAction.requestRoleUsers({ id: r.RoleId }))
  }

  const addNewRole = () => newRoleStr.length > 0 && dispatch(adminAction.addNewRole({ RoleName: newRoleStr }))

  const addRoleUser = roleUserId => dispatch(adminAction.addRoleUser({ InternalUserId: roleUserId, RoleId: role?.RoleId, HasWriteAccess: false }))

  const deleteRoleUser = () => {
    userToDelete && dispatch(adminAction.deleteRoleUser({ id: userToDelete.UserRoleId?.toString() }))
    setUserToDelete(null)
  }

  const deleteRoleUserDialog = <Dialog fullWidth open={openDelete} onClose={() => setOpenDelete(false)}>
    <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this user's role?</DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenDelete(false); deleteRoleUser() }}>Confirm</Button>
      <Button size='small' onClick={() => setOpenDelete(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>

  const addRoleUserDialog = <Dialog fullWidth open={openAdd} onClose={() => setOpenAdd(false)}>
    <DialogTitle>Add New User for {role?.RoleName}</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        variant='filled'
        size='small'
        placeholder='Search for user'
        inputProps={{ style: { padding: 8, fontSize: 14 } }}
        value={searchUser}
        onChange={e => setSearchUser(e.target.value)}
      />
      <MenuList>
        {(users?.value || [])
        .filter(u => !Boolean((roleUsers?.value || []).find(r => r.InternalUser?.Login === u?.Login)))
        .filter(u => u.Login.toLowerCase().includes(searchUser.toLowerCase()))
        .map((user, i) => 
          <MenuItem key={i} style={{ fontSize: 15 }} value={user.InternalUserId} onClick={() => { setOpenAdd(false); addRoleUser(user.InternalUserId) }}>
            {user.Login}
          </MenuItem>
        )}
      </MenuList>
    </DialogContent>
    <DialogActions><Button size='small' onClick={() => setOpenAdd(false)}>Cancel</Button></DialogActions>
  </Dialog>
  
  const addRoleDialog = <Dialog fullWidth open={openNewRole} onClose={() => setOpenNewRole(false)}>
    <DialogTitle>Add New Role</DialogTitle>
    <DialogContent style={{ fontSize: 14 }}>
      <TextField autoFocus fullWidth variant='outlined' size='small' value={newRoleStr} onChange={e => setNewRoleStr(e.target.value)} />
    </DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenNewRole(false); addNewRole() }}>Add</Button>
      <Button size='small' onClick={() => setOpenNewRole(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>

  useEffect(() => {
    setLoading(roles?.loading || roleUsers?.loading ? true : false)
  }, [roles?.loading, roleUsers?.loading])

  useEffect(() => {
    roleUsers?.message && setMessage({ message: roleUsers.message.msg, severity: roleUsers.message.level, clear: () => dispatch(adminAction.clearRoleUsersMessage()) })
    roles?.message && setMessage({ message: roles.message.msg, severity: roles.message.level, clear: () => dispatch(adminAction.clearRolesMessage()) })
  }, [roles?.message, roleUsers?.message])

  return <div className={className}>
    <Paper className='paper paper-div'>
      <Paper className='role-paper'>
        <Tooltip title='Add New Role' placement='top'>
          <Fab className='fab-button' color='primary' size='small' onClick={() => setOpenNewRole(true)}><Add /></Fab>
        </Tooltip>
        <div className='userrole-container'>
          {(roles?.value || [])
            .sort((a, b) => a.RoleName.toLowerCase() > b.RoleName.toLowerCase() ? 1 : -1)
            .map((r, i) => <Chip className={`chip ${role?.RoleName === r?.RoleName ? 'selected' : ''}`} key={i} label={r?.RoleName} color='primary' onClick={() => chooseRole(r)} />)}
        </div>
      </Paper>
      <Paper className='paper user-paper'>
        {role ? <React.Fragment>
          <Tooltip title='Add New User' placement='top'>
            <Fab className='fab-button' color='primary' size='small' onClick={() => setOpenAdd(true)} disabled={!role}><Add /></Fab>
          </Tooltip>
          <Typography variant='subtitle2'>{role?.RoleName}</Typography>
          <div className='userrole-container' style={{ marginTop: 8 }}>
            {Object.values(roleUsers?.value || []).length > 0 && <TextField
              style={{ marginBottom: 6 }}
              fullWidth
              variant='filled'
              size='small'
              placeholder='Search for user'
              inputProps={{ style: { padding: 8, fontSize: 14 } }}
              value={searchUserList}
              onChange={e => setSearchUserList(e.target.value)}
            />}
            {roleUsers?.value && Array.isArray(roleUsers?.value) && (roleUsers?.value || [])
            .filter(u => u?.InternalUser?.Login.toLowerCase().includes(searchUserList.toLowerCase()))
            .map((user, i) => <Chip
              className='chip'
              style={{ display: 'flex', justifyContent: 'space-between' }}
              key={i}
              label={user?.InternalUser?.Login}
              color='primary'
              deleteIcon={<Tooltip title='Delete'><span style={{ display: 'flex', alignItems: 'center' }}><BinIcon strokeWidth={2} size={25} /></span></Tooltip>}
              onDelete={() => { setUserToDelete(user); setOpenDelete(true) }}
              avatar={<Avatar>{user.InternalUser && user.InternalUser.Login.substring(0, 2).toUpperCase()}</Avatar>}
            />)}
            {roleUsers?.loading && <LinearProgress style={{ position: 'relative', width: '100%' }} />}
          </div>
        </React.Fragment>
        : <Typography style={{ textAlign: 'center', marginTop: 16 }} variant='subtitle2'>Choose a role from the list on the left</Typography>}
      </Paper>
    </Paper>
    {addRoleUserDialog}
    {addRoleDialog}
    {deleteRoleUserDialog}
  </div>
}

export const RoleManagementComponent = styled(_RoleManagement)`
  .paper {
    padding: 24px;
    background: white;
  }
  .paper-div {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .role-paper {
    position: relative;
    width: 40%;
    padding: 32px;
  }
  .user-paper {
    position: relative;
    background: ${p => p.theme.palette.alltech.light_grey[50]};
    width: 60%;
    margin-left: 20px;
  }
  .fab-button {
    position: absolute;
    top: -18px;
    right: 16px;
  }
  .chip {
    margin: 4px 10px;
    &:hover {
      background: ${p => darken(p.theme.palette.alltech.terracotta.main, 0.3)}
    }
  }
  .selected {
    background: ${p => darken(p.theme.palette.alltech.terracotta.main, 0.3)}
  }
  .userrole-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    height: 50vh;
    overflow-y: auto;
  }
  .MuiChip-avatar {
    margin-left: 0 !important;
    width: 30px !important;
    height: 30px !important;
  }
`
export default RoleManagementComponent