import React, { useContext, useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import styled from "styled-components";
import { severityLevels } from "src/store/reducers/user.reducer";
import { SnackBarContext } from "src/contexts/snack-bar.context";

export type proptypes = {
  snackMessage: string | undefined;
  severity: severityLevels;
  clear: any;
}
export const defaultProps: proptypes = {
  snackMessage: undefined,
  severity: 'success',
  clear: null
}

const _CustomSnackBar: React.FC<proptypes & { className?: string }> = (props) => {
  const { className, snackMessage, severity, clear } = props
  const [msg, setMsg] = useState<string | undefined>(undefined)
  const { setMessage } = useContext(SnackBarContext)

  useEffect(() => {
    setMsg((snackMessage || '')?.length > 0 ? snackMessage : undefined)
  }, [snackMessage])

  return <div className={className}>
    <Snackbar
      open={msg !== undefined}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={() => { clear && clear(); setMessage({message: undefined, severity: 'success', clear: null}); setMsg(undefined) }}
    >
      <Alert severity={severity}><span>{msg}</span></Alert>
    </Snackbar>
  </div>
}

const SnackbarComponent = styled(_CustomSnackBar)`
  .MuiAlert-root {
    padding: 10px 16px;
  }
`
export default SnackbarComponent
