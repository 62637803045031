import { allFeatures } from "./features-list"

export const numberFormat = (num, min, max) => {
  const format = new Intl.NumberFormat("en", {
    style: "decimal",
    useGrouping: true,
    minimumFractionDigits: min,
    maximumFractionDigits: max
  })
  return format.format(num)
}

export const padZero = (num, l = 2) => ("00000000000000" + num).slice(0 - l)

export const sortFeatures = (features, key: string, bypass?: boolean) => {
  if (bypass) return true
  if (!features?.loaded) return
  return Boolean(features.find(f => allFeatures[key].includes(f.WebFunction)))
}

export const sortSubFeatures = (key: string, featureId: number, bypass?: boolean) => {
  if (bypass) return true
  return Boolean(allFeatures[key].includes(featureId))
}