import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Button, Paper, Avatar, Typography } from '@mui/material';
import { LockOutlined, Forward } from '@mui/icons-material';
import { loginRequest } from 'src/lib/services/user-auth';
import { IPublicClientApplication } from '@azure/msal-browser';
import { SnackBarContext } from 'src/contexts/snack-bar.context';

export type proptypes = {
  msal?: IPublicClientApplication;
}
export const defaultProps: proptypes = {
}

const _Login: React.FC<proptypes & { className?: string }> = (props) => {
  const { msal, className } = props
  const [useRedirect, setUseRedirect] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setMessage } = useContext(SnackBarContext)
  
  const signIn = () => {
    setLoading(true)
    useRedirect ?
      msal?.loginRedirect(loginRequest)
    : msal?.loginPopup(loginRequest).then(res => {
      setLoading(false)
    }).catch(loginError => {
      setLoading(false)
      console.error({ loginError })
    })
  }

  msal?.handleRedirectPromise().then(loginRes => {
    loginRes !== null && setLoading(false)
  }).catch(loginError => {
    setLoading(false)
    console.error({ loginError })
  })
  
  useEffect(() => {
    const ua = window.navigator.userAgent
    const isIe = /Edg\/|Trident\/|MSIE/i.test(ua);
    const isSafari = /^((?!chrome|android).)*safari/i.test(ua);

    if (isSafari || isIe) {
      setUseRedirect(true)
      setMessage({ message: 'You may encounter issues when using KADDi as IE and Microsoft Edge are not supported browsers', severity: 'warning', clear: null })
    }
  }, [])
  
  return <div className={className}>
    <div className='container'>
      <Paper className='card'>
        <div className='center'>
          <Avatar className='avatar'>
            <LockOutlined />
          </Avatar>
          < Typography component='h1' variant='h5'>
            Sign in
          </Typography>
        </div>
        <div style={{ marginTop: 24 }}>
          <Typography variant='subtitle2'>
            KADDi uses Microsoft Authentication.
          </Typography>
        </div>
        <div style={{ marginTop: 24 }}>
          <Button
            onClick={signIn}
            variant='contained'
            color='primary'
            style={{ width: '100%' }}
            disabled={loading ? true : undefined}
          >
            Proceed
            <Forward style={{ marginLeft: 6 }} />
          </Button>
        </div>
      </Paper>
    </div>
  </div>
}

const LoginComponent = styled(_Login)`
  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .card {
    max-width: 345px;
    min-width: 240px;
    padding: 24px;
  }
  .center {
    text-align: center;
  }
  .textField {
    width: 100%;
  }
  .avatar {
    margin: 0 auto;
    background-color: ${p => p.theme.palette.alltech.terracotta.main};
  }
`
export default LoginComponent