import React, { useState, useRef }from "react";
import { Grow, Paper, Popper, PopperPlacementType } from "@mui/material";
import styled from "styled-components";
import { QuestionIcon } from "../icons/question";

export type proptypes = {
  className?: string;
  position?: PopperPlacementType;
  helpText: JSX.Element | string;
  iconSize?: number;
  icon?: JSX.Element | null;
};
  
const _Popper: React.FC<proptypes> = props => {
  const { className, position, helpText, iconSize, icon } = props;
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>();
  const Icon = icon;

  return <div className={className} ref={ref as any}>
    <div className='help-icon' onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      {Icon ? Icon : <QuestionIcon className='green' size={iconSize || 25} />}
    </div>
    {ref.current && <Popper
      style={{ pointerEvents: 'none', zIndex: 9999 }}
      open={open}
      anchorEl={ref.current}
      placement={position || 'bottom-start'}
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={150}>
          <Paper sx={{ fontSize: 12, bgcolor: 'white' }}>{helpText}</Paper>
        </Grow>
      )}
    </Popper>}
  </div>
}

export const HelperIconComponent = styled(_Popper)`
  .help-icon {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }
  .green {
    color: ${p => p.theme.palette.success[600]};
  }
`
export default HelperIconComponent
