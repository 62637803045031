import React, { useContext, useEffect, useState } from 'react'
import { Checkbox, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useTypedAppSelector } from 'src/store'
import { adminAction } from 'src/store/actions/admin.actions'
import styled from 'styled-components'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { SnackBarContext } from 'src/contexts/snack-bar.context'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _Permissions: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [groups, setGroups] = useState<{name: string, id: number}[]>()
  const methodGroupRole = useTypedAppSelector(s => s.adminState.methodGroupRole)
  const methodGroups = useTypedAppSelector(s => s.adminState.methodGroups)
  const roles = useTypedAppSelector(s => s.adminState.roles)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { setMessage } = useContext(SnackBarContext)
  const dispatch = useDispatch()

  const setPermission = (mgr, isEnabled) => {
    isEnabled ?
      dispatch(adminAction.deleteMethodGroupRole({ id: mgr.MethodGroupRoleId, data: mgr }))
      : dispatch(adminAction.addMethodGroupRole(mgr))
  }

  useEffect(() => {
    setLoading(true)
    dispatch(adminAction.requestMethodGroupRole())
    dispatch(adminAction.requestMethodGroups())
    dispatch(adminAction.requestRoles())
  }, [])

  useEffect(() => {
    setLoading(methodGroups?.loading || roles?.loading || methodGroupRole?.loading ? true : false)
  }, [methodGroups?.loading, roles?.loading, methodGroupRole?.loading])

  useEffect(() => {
    methodGroups?.loaded && setGroups(Object.keys(methodGroups?.value || []).map(mg => ({
      name: methodGroups?.value[mg],
      id: parseInt(mg, 10)
    })))
  }, [methodGroups?.loaded, methodGroups?.value])

  useEffect(() => {
    methodGroups?.message && setMessage({message: methodGroups.message.msg, severity: methodGroups.message.level, clear: () => dispatch(adminAction.clearMethodGroupsMessage()) })
    methodGroupRole?.message && setMessage({message: methodGroupRole.message.msg, severity: methodGroupRole.message.level, clear: () => dispatch(adminAction.clearMethodGroupRoleMessage()) })
    roles?.message && setMessage({message: roles.message.msg, severity: roles.message.level, clear: () => dispatch(adminAction.clearRolesMessage()) })
  }, [methodGroups?.message, methodGroupRole?.message, roles?.message])

  const tableHead = (roles?.value || []).map((role, i) => <TableCell key={i} align='center' style={{ height: 50 }}>
    {role.RoleName}
  </TableCell>)

  const tableBody = (groups || []).map((g, key) => {
    return <TableRow key={key}>
      <TableCell component='th' scope='row'>{g.name}</TableCell>
      {(roles?.value || []).map((r, i) => {
        const MGR = (methodGroupRole?.value || []).find(mgr => mgr.RoleId === r.RoleId && mgr.MethodGroupName === g.name)

        return <TableCell key={i} padding='none' style={{ textAlign: 'center' }}>
          <Checkbox
            name='permissions'
            checked={!!MGR}
            disabled={g.name === 'AccessSecurity' && r.RoleId === 1}
            onChange={() => setPermission(
              {
                MethodGroupRoleId: (MGR && MGR.MethodGroupRoleId) || 0,
                MethodGroupName: g.name,
                RoleId: r.RoleId
              },
              !!MGR
            )}
          />
        </TableCell>
      })}
    </TableRow>
  })

  return <div className={className}>
    <Paper style={{ width: '100%', padding: '0 8px' }}>
      {Object.values(methodGroups?.value || []).length > 0 && Object.values(roles?.value || []).length > 0 && Object.values(methodGroupRole?.value || []).length > 0 ? <Table>
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            {tableHead}
          </TableRow>
        </TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
      : <div></div>}
    </Paper>
  </div>
}


export const PermissionsComponent = styled(_Permissions)`
  display: flex;
  justify-content: center;
  width: 100%;
  .empty-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 30vh;
    padding: 16px;
  }
  .MuiTableCell-root {
    padding: 2px 10px;
    font-size: 12px;
  }
`
export default PermissionsComponent