import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton, MenuItem, Menu, ListItemIcon, ListItemText } from '@mui/material';
import { AccountCircle, ExitToApp } from '@mui/icons-material';
import { AccountInfo } from '@azure/msal-browser';

export type proptypes = {
  user?: AccountInfo;
  logout: () => void;
}
export const defaultProps: proptypes = {
  logout: () => {}
}

const _AccountButton: React.FC<proptypes & { className?: string }> = (props) => {
  const { user, logout, className } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleLogOut = () => {
    setAnchorEl(null)
    logout()
  }

  return <div className={className}>
    {!user ? undefined : <React.Fragment>
      <IconButton className='account-icon' onClick={e => setAnchorEl(e.currentTarget)}>
        <AccountCircle />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <MenuItem disabled>
          <ListItemIcon><AccountCircle /></ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: 14 }} sx={{ color: 'black !important' }} primary={user?.username} />
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon><ExitToApp /></ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: 14 }} primary='Logout' />
        </MenuItem>
      </Menu>
    </React.Fragment>}
  </div>
}

const AccountButtonComponent = styled(_AccountButton)`
  .account-icon {
    margin-left: 20px;
    color: white;
  }
`
export default AccountButtonComponent
