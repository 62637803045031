import React, { useContext, useEffect, useState } from 'react'
import { ClickAwayListener, Grow, IconButton, InputAdornment, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, TextField } from '@mui/material'
import styled from 'styled-components'
import { AvatarIcon } from '../icons/avatar';
import { useDispatch } from 'react-redux';
import { userAction } from 'src/store/actions/user.actions';
import { useTypedAppSelector } from 'src/store';
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context';
import { ClearIcon } from '../icons/clear';
import { API_NS } from 'src/lib/api/endpoints/endpoint.types';

export type proptypes = {
  selectedAccount?: {acc: API_NS.Accounts | API_NS.Nutritionists, type: 'nutritionist' | 'customer' };
  selectAccountHandler?: (acc: API_NS.Accounts | API_NS.Nutritionists | undefined, type: 'nutritionist' | 'customer') => void;
  clear: boolean;
  resetClear?: () => void;
  disabled: boolean;
  clearInput?: boolean;
  setInput?: (b: boolean) => void;
}

export const defaultProps: proptypes = {
  clear: false,
  disabled: false
}

export const StyledPopper = styled(Popper)`
  z-index: 9999;
  .menu-list {
    max-height: 300px;
    overflow-y: auto;
  }
  .MuiListItemText-primary {
    font-size: 15px;
  }
  .MuiListItemText-secondary {
    font-size: 13px;
  }
`

const _ClientSearch: React.FC<proptypes & { className?: string }> = props => {
  const { className, selectedAccount, selectAccountHandler, clear, resetClear, clearInput, setInput, disabled } = props
  const [searchText, setSearchText] = useState<string>('')
  const [anchorRef, setAnchorRef] = useState<any>(null)
  const [filteredAccounts, setFilteredAccounts] = useState<any>([])
  const accounts = useTypedAppSelector(s => s.userState.accounts)
  const { setLoading } = useContext(AppBarLoadingContext)
  const dispatch = useDispatch()

  const chooseAccount = acc => {
    setSearchText(`${acc.ClientCode} ${acc.ClientName ? `- ${acc.ClientName}` : ''}`)
    setFilteredAccounts([])
    selectAccountHandler && selectAccountHandler(acc, 'customer')
    setInput && setInput(true)
  }

  const filterAccounts = () => {
    setFilteredAccounts(
      (accounts?.value || [])
        .filter(a => (a.ClientCode && a.ClientCode.toLowerCase().includes((searchText || '').toLowerCase()))
          || (a.ClientName && a.ClientName.toLowerCase().includes((searchText || '').toLowerCase())))
        .sort((a, b) => a.ClientCode.toLowerCase() < b.ClientCode.toLowerCase() ? -1 : 1)
    )
  }

  const searchHandler = e => {
    setAnchorRef(e.currentTarget.parentNode)
    const str = e.target.value
    if (selectedAccount?.acc) selectAccountHandler && selectAccountHandler(undefined, 'customer')
    setSearchText(str)
    if (str?.length < 3) return
    if (str?.length === 3) dispatch(userAction.requestAllAccounts({ search: str }))
    if (str?.length > 3) filterAccounts()
  }

  const clearAccounts = () => {
    setSearchText('')
    selectAccountHandler && selectAccountHandler(undefined, 'customer')
    setFilteredAccounts([])
  }

  useEffect(() => {
    if (clear) {
      setSearchText('')
      selectAccountHandler && selectAccountHandler(undefined, 'customer')
      setFilteredAccounts([])
      resetClear && resetClear()
    }
  }, [clear, resetClear, selectAccountHandler])

  useEffect(() => {
    if (clearInput) {
      setSearchText('')
      setInput && setInput(false)
    }
  }, [clearInput])

  useEffect(() => {
    Object.values(accounts?.value || []).length > 0 ? setFilteredAccounts(accounts?.value) : setFilteredAccounts([])
  }, [accounts?.value])

  useEffect(() => {
    setLoading(accounts?.loading ? true : false)
  }, [accounts?.loading])

  useEffect(() => {
    return () => {
      dispatch(userAction.clearAccounts())
      setLoading(false)
    }
  }, [])
  
  return <div className={className}>
    <TextField
      inputRef={anchorRef}
      style={{ background: 'white' }}
      fullWidth
      variant='outlined'
      size='small'
      placeholder='Enter Client Account No. or Name'
      inputProps={{ style: { padding: 8, fontSize: 14, minWidth: 300 } }}
      value={searchText}
      onChange={searchHandler}
      disabled={disabled}
      InputProps={{ endAdornment: <InputAdornment style={{ display: searchText?.length > 0 ? 'flex' : 'none' }} position='end'>
        <IconButton onClick={() => clearAccounts()} edge='end'>
          <ClearIcon size={16} />
        </IconButton>
      </InputAdornment>}}
    />
    {anchorRef && <StyledPopper
      open={Boolean(anchorRef) && filteredAccounts?.length > 0}
      anchorEl={anchorRef}
      placement='bottom-start'
      style={{ minWidth: anchorRef.offsetWidth || '100%', zIndex: 9999 }}
      transition
    >
      {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
        <Paper sx={{ bgcolor: 'white' }}>
          <ClickAwayListener onClickAway={() => { setFilteredAccounts([]); setAnchorRef(null) }}>
            <MenuList className='menu-list'>
              {filteredAccounts.map((f, i) => <MenuItem key={i} onClick={() => { chooseAccount(f); setAnchorRef(null) }}>
                <ListItemIcon><AvatarIcon size={20} /></ListItemIcon>
                <ListItemText secondary={f.ClientName} primary={f.ClientCode} />
              </MenuItem>)}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>}
    </StyledPopper>}
  </div>
}

export const ClientSearchComponent = styled(_ClientSearch)`
`
export default ClientSearchComponent