import { baseAxiosConfig } from "../api/http_client"

const TIMEOUT_PREFIX = 'Request Timeout'

export const createTimeoutMessage = (limitMS, url) => `${TIMEOUT_PREFIX} ${limitMS && `(${limitMS || baseAxiosConfig.timeout}ms)`}: ${url}`

const timeoutRegex = new RegExp(`^${TIMEOUT_PREFIX}`)

export const epicErrorLogger = (errorMessage: any) => {
    if (typeof errorMessage !== 'string') return
    const isTimeoutError = timeoutRegex.test(errorMessage)

    if (isTimeoutError) {
        const [, time, url] = errorMessage.match(/(\d*)ms\):\s(\S*)$/) || []
        console.error(url, parseInt(time))
    }
}