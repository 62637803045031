import { combineEpics, Epic, } from "redux-observable"
import { TSalesAction, salesAction } from "../actions/sales.actions"
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators"
import { endpoints } from "../../lib/api/endpoints/endpoint_list"
import { of } from "rxjs"
import { TCombinedState } from '../index'
import { epicErrorLogger } from "../../lib/utils/epic-error-logger"

/**
 * Trigger API calls or any other async actions
 */
export const salesEpic: Epic<TSalesAction, any, TCombinedState> = combineEpics(

  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_ORDER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.order.get({ id: a.payload.id }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return salesAction.requestOrderSuccess(epicRes.data.Payload)
          } else {
            return salesAction.requestOrderFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(salesAction.requestOrderFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'AUTHORISE_ORDER' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.authoriseOrder.put(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return salesAction.authoriseOrderSuccess(epicRes.data.Payload)
          } else {
            return salesAction.authoriseOrderFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(salesAction.authoriseOrderFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_TRADE_INS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.tradeIns.get({ orderId: a.payload.orderId }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return salesAction.requestTradeInsSuccess(epicRes.data.Payload)
          } else {
            return salesAction.requestTradeInsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(salesAction.requestTradeInsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'SAVE_TRADE_INS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.saveTradeIns.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return salesAction.saveTradeInsSuccess(epicRes.data.Payload)
          } else {
            return salesAction.saveTradeInsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(salesAction.saveTradeInsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_SALES_STAFFS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.salesStaffs.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return salesAction.requestSalesStaffsSuccess(epicRes.data.Payload)
          } else {
            return salesAction.requestSalesStaffsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(salesAction.requestSalesStaffsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_TARGET_CATEGORIES' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.targetCategories.get().pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return salesAction.requestTargetCategoriesSuccess(epicRes.data.Payload)
          } else {
            return salesAction.requestTargetCategoriesFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(salesAction.requestTargetCategoriesFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_SALES_TARGETS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.salesTargets.get({ targetCategoryId: a.payload.targetCategoryId, market: a.payload.market, year: a.payload.year }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return salesAction.requestSalesTargetsSuccess(epicRes.data.Payload)
          } else {
            return salesAction.requestSalesTargetsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(salesAction.requestSalesTargetsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'ADD_SALES_TARGET' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateSalesTarget.post(a.payload).pipe(
        mergeMap(epicRes => {
          if (epicRes.status === 200) {
            const successAction = salesAction.addSalesTargetSuccess(epicRes.data.Payload)

            const fetchAction = salesAction.requestSalesTargets({ targetCategoryId: a.payload.CategoryId, market: a.payload.Market, year: a.payload.Year })
            return [successAction, fetchAction]
          } else {
            return [salesAction.addSalesTargetFailed(epicRes.data?.error as string || '')]
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(salesAction.addSalesTargetFailed(epicError))
        })
      )
    })
  )

)