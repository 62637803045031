import { TCombinedState } from '../index'
import { createSelector } from "reselect";
import { REGION_NAMES } from 'src/lib/utils/region-names';

const _regions = (state: TCombinedState) => {
  return state.intouchState.regions
}

const providers = (state: TCombinedState) => {
  return state.intouchState.providers
}

const _providerMappings = (state: TCombinedState) => {
  return state.intouchState.providerMappings
}
const _autoRpms = (state: TCombinedState) => {
  return state.intouchState.autoRpmList
}

const _autoRpmConfigs = (state: TCombinedState) => {
  return state.intouchState.autoRpmConfigs
}

const _milkPens = (state: TCombinedState) => {
  return state.intouchState.milkPens
}

const _feedPens = (state: TCombinedState) => {
  return state.intouchState.feedPens
}

const _inclusions = (state: TCombinedState) => {
  return state.intouchState.reportContractInclusions
}

const _markets = (state: TCombinedState) => {
  return state.intouchState.markets
}

const _contractMarkets = (state: TCombinedState) => {
  return state.intouchState.intouchContractMarkets
}

const _selectedMarket = (state: TCombinedState) => {
  return state.intouchState.selectedMarket
}

const _exclusions = (state: TCombinedState) => {
  return state.intouchState.reportConfigExclusions
}

const _accuracyClients = (state: TCombinedState) => {
  return state.intouchState.accuracyClients
}

const regionsList = createSelector(_regions, (regions) => {
  if (!regions?.loaded) return []

  return regions.value.map(r => ({...r, FullName: REGION_NAMES[r.RegionName] || ''}))
})

const providersList = createSelector(providers, _providerMappings, (providers, mappings) => {
  if (!providers?.loaded || !mappings?.loaded) return []

  return mappings.value.map((m, i) => {
    const prov = providers.value.find(p => p.ProviderCode === m.Provider)
    return {...m, id: i, ProviderName: prov?.ProviderName || ''}
  })
})

const extClients = createSelector(_providerMappings, (mappings) => {
  if (!mappings?.loaded) return []

  return mappings.value.map(m => m.ExternalClientId)
})

const intouchClients = createSelector(_providerMappings, (mappings) => {
  if (!mappings?.loaded) return []

  return mappings.value.map(m => m.Username)
})

const checkDuplicate = createSelector(_autoRpms, (autoRpms) => {
  if (!autoRpms?.loaded) return

  if (!autoRpms?.value?.[0]?.AutoRpmConfigs) return

  const count: any = Object.values((autoRpms.value[0].AutoRpmConfigs)
    .map(f => { return (f.InternalPenId !== null && f.InternalPenId !== 0) && f.InternalPenName !== null ?
      { InternalPenId: f.InternalPenId, InternalPenName: f.InternalPenName }
      : null
    })
    .filter(t => t).reduce((p, c) => {
      p[`${c?.InternalPenName}${c?.InternalPenId}`] = p[`${c?.InternalPenName}${c?.InternalPenId}`] ? p[`${c?.InternalPenName}${c?.InternalPenId}`] + 1 : 1;
      return p;
    }, {}))

  const feedDuplicates = count.filter(c => c > 1).length > 0
    
  const milkDuplicates = autoRpms.value[0].AutoRpmConfigs
    .filter( t => t.ExternalPenReference)
    .map(v => v.ExternalPenReference)
    .filter((v, i, vIds) => vIds.indexOf(v) !== i).length > 0
    
  return feedDuplicates || milkDuplicates
})

const sortedMilkPens = createSelector(_milkPens, _autoRpmConfigs, (milkPens, configList) => {
  if (!milkPens?.loaded || !configList?.loaded) return []

  const pens = milkPens.value.map(m => ({
    ...m,
    checked: Boolean(configList.value.find(c => c.IsAlltechGroup === false && c.ExternalPenReference === m.PenDescription))
  }))

  return [
    ...pens.filter(m => m.checked).sort((a ,b) => a.PenDescription?.toLowerCase() < b.PenDescription?.toLowerCase() ? -1 : 1),
    ...pens.filter(m => !m.checked).sort((a ,b) => a.PenDescription?.toLowerCase() < b.PenDescription?.toLowerCase() ? -1 : 1)
  ]
})

const sortedFeedPens = createSelector(_feedPens, _autoRpmConfigs, (feedPens, configList) => {
  if (!feedPens?.loaded || !configList?.loaded) return []

  const checkIntouch = pen => {
    const obj = configList.value.filter(c => c.IsAlltechGroup === true && c.InternalPenId === pen.PenId)
    return (obj || []).filter(f => f?.InternalPenName === pen.PenDescription)?.length > 0
  }

  const pens = feedPens.value.map(f => ({...f, checked: checkIntouch(f)}))

  return [
    ...pens.filter(f => f.checked).sort((a ,b) => a.PenDescription?.toLowerCase() < b.PenDescription?.toLowerCase() ? -1 : 1),
    ...pens.filter(f => !f.checked).sort((a ,b) => a.PenDescription?.toLowerCase() < b.PenDescription?.toLowerCase() ? -1 : 1)
  ]
})

const reportContractList = createSelector(_markets, _contractMarkets, _inclusions, (markets, contractMarkets, inclusions) => {
  if (!markets?.loaded || !contractMarkets?.loaded || !inclusions?.loaded) return []

  return contractMarkets.value.map((c, i) => {
    const obj = {...c, MarketName: markets.value.find(m => m.Key === c.market)?.Value}

    const inc = inclusions.value.find(inc => inc.InTouchProductSlxId === c.InTouchProductSlxId && inc.Market === c.market)

    return {...obj, id: i, Inclusion: inc ? true : false, ReportContractInclusionId: inc ? inc.ReportContractInclusionId : null}
  })
})

const filteredContractList = createSelector(reportContractList, _selectedMarket, (list, market) => {
  if (!market) return list
  return market.Value === 'All' ? list : list.filter(l => l.market === market?.Key)
})

const reportExclusionsList = createSelector(_accuracyClients, _exclusions, (accuracyClients, exclusions) => {
  if (!accuracyClients?.loaded || !exclusions?.loaded) return []

  return accuracyClients.value.map((a, i) => {
    const exc = exclusions.value.find(ex => ex.ClientId === a.Client)
    if (exc) {
      const { ClientId, ...rest } = exc
      return {...a, ...rest, id: i}
    }
    else 
      return {...a, id: i}
  })
})

export const intouchSelectors = {
  regionsList,
  providersList,
  checkDuplicate,
  sortedMilkPens,
  sortedFeedPens,
  filteredContractList,
  reportExclusionsList,
  extClients,
  intouchClients
}