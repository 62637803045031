import React, { useContext } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Typography } from '@mui/material'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { NavBar } from 'src/components/navBar/navbar'
import { sortSubFeatures } from 'src/lib/utils/methods'
import APIManagementComponent from './api-management'
import AutoRpmsPage from './provider-mappings/auto-rpms-page'
import DemoUsersComponent from './demo-users'
import FeedLibraryComponent from './feed-library'
import ProviderMappingsComponent from './provider-mappings/provider-mappings'
import UOMMappingsComponent from './uom-mapping'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import AccuracyReportSectionComponent from './accuracy-reports/accuracy-report-section'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _IntouchSectionNav: React.FC<proptypes & { className?: string }> = () => {
  const { path } = useRouteMatch()
  return <ErrorBoundary page="intouch nav bar">
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography variant='h5'>InTouch</Typography>
      <NavBar tabs={[
        {
          title: 'API Management',
          to: `${path}/API`,
        },
        {
          title: 'Accuracy Reports',
          to: `${path}/ARConfig`,
        },
        {
          title: 'Demo Users',
          to: `${path}/IntouchDemoUser`,
        },
        {
          title: 'Feed Library',
          to: `${path}/FeedLibrary`,
        },
        {
          title: 'Provider Mappings',
          to: `${path}/ProviderMappings`,
        },
        {
          title: 'UOM Mappings',
          to: `${path}/UoMMapping`,
        }
      ]} />
    </div>
  </ErrorBoundary>
}

const _IntouchSection: React.FC<proptypes & { className?: string }> = () => {
  const { path } = useRouteMatch()
  const { enabledSecurity } = useContext(EnabledSecurityContext)

  return <React.Fragment>
    <Switch>
      {sortSubFeatures('intouch', 26, enabledSecurity) && <Route path={`${path}/API`} render={() => <ErrorBoundary page='api management'><APIManagementComponent /></ErrorBoundary>}></Route>}
      {sortSubFeatures('intouch', 27, enabledSecurity) && <Route path={`${path}/ARConfig`} render={() => <ErrorBoundary page='accuracy report'><AccuracyReportSectionComponent /></ErrorBoundary>}></Route>}
      {sortSubFeatures('intouch', 29, enabledSecurity) && <Route path={`${path}/IntouchDemoUser`} render={() => <ErrorBoundary page='demo users'><DemoUsersComponent /></ErrorBoundary>}></Route>}
      {sortSubFeatures('intouch', 1, enabledSecurity) && <Route path={`${path}/FeedLibrary`} render={() => <ErrorBoundary page='feed library'><FeedLibraryComponent /></ErrorBoundary>}></Route>}
      {sortSubFeatures('intouch', 19, enabledSecurity) && <Route path={`${path}/UoMMapping`} render={() => <ErrorBoundary page='uom mappings'><UOMMappingsComponent /></ErrorBoundary>}></Route>}

      {sortSubFeatures('intouch', 18, enabledSecurity) && <Route path={`${path}/ProviderMappings/AutoRpms/:id/`} render={() => <AutoRpmsPage />}></Route>}
      {sortSubFeatures('intouch', 18, enabledSecurity) && <Route path={`${path}/ProviderMappings`} render={() => <ErrorBoundary page='provider mappings'><ProviderMappingsComponent /></ErrorBoundary>}></Route>}
    </Switch>
  </React.Fragment>
}

export const IntouchSectionComponent = _IntouchSection
export const IntouchSectionNavComponent = _IntouchSectionNav
export default IntouchSectionComponent