import React from 'react';
import { useIntl } from 'react-intl';

interface propTypes {
    numberFormatter: (number: number, minimum: number | undefined, maximum: number | undefined) => string;
    percentageFormatter: (number: number, minimum: number | undefined, maximum: number | undefined) => string;
}

interface providerPropsTypes {
    children: React.ReactNode;
}

const NumberFormatContext = React.createContext<propTypes>({} as propTypes);

const NumberFormatContextProvider: React.FC<providerPropsTypes> = (props) => {
    const intl = useIntl()

    const numberFormatter = (number, minimum, maximum) =>
        intl.formatNumber(parseFloat(number), { minimumFractionDigits: minimum || minimum === 0 ? minimum : 2, maximumFractionDigits: maximum || maximum === 0 ? maximum : 2 })

    const percentageFormatter = (number, minimum, maximum) => {
        return intl.formatNumber(
            number,
            {
                style: 'percent',
                minimumFractionDigits: minimum || minimum === 0 ? minimum : 2,
                maximumFractionDigits: maximum || maximum === 0 ? maximum : 2
            })
    }

    return (
        <NumberFormatContext.Provider value={{ numberFormatter, percentageFormatter }}>
            {props.children}
        </NumberFormatContext.Provider>
    );
}

export { NumberFormatContext, NumberFormatContextProvider };