import { combineEpics, Epic, } from "redux-observable"
import { TUserAction, userAction } from "../actions/user.actions"
import { catchError, filter, map, switchMap } from "rxjs/operators"
import { endpoints } from "../../lib/api/endpoints/endpoint_list"
import { of } from "rxjs"
import { TCombinedState } from '../index'
import { epicErrorLogger } from "../../lib/utils/epic-error-logger"

/**
 * Trigger API calls or any other async actions
 */
export const userEpic: Epic<TUserAction, any, TCombinedState> = combineEpics(

  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_USER_FEATURES' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.userFeatures.get({ login: a.payload.email }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return userAction.requestUserFeaturesSuccess(epicRes.data.Payload)
          } else {
            return userAction.requestUserFeaturesFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(userAction.requestUserFeaturesFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_ALL_ACCOUNTS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.accounts.get({ search: a.payload.search }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return userAction.requestAllAccountsSuccess(epicRes.data.Payload)
          } else {
            return userAction.requestAllAccountsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(userAction.requestAllAccountsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_NUTRITIONISTS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.nutritionists.get({ search: a.payload.search }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return userAction.requestNutritionistsSuccess(epicRes.data.Payload)
          } else {
            return userAction.requestNutritionistsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(userAction.requestNutritionistsFailed(epicError))
        })
      )
    })
  ),
  
)