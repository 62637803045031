import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import ClientSearchComponent from 'src/components/accountSearch/client-search'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { Button, Paper } from '@mui/material'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import RegionSelectComponent from 'src/components/regionSelect/region-select'
import { SyncIcon } from 'src/components/icons/sync'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _FeedLibrary: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [account, setAccount] = useState<{acc: API_NS.Accounts, type: 'customer'} | undefined>(undefined)
  const [region, setRegion] = useState<API_NS.Regions & { FullName: string}>()
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [regionDisabled, setRegionDisabled] = useState<boolean>(true)
  const [clear, setClear] = useState<boolean>(false)
  const feedLibrary = useTypedAppSelector(s => s.intouchState.feedLibrary)
  const regions = useTypedAppSelector(s => s.intouchState.regions)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const dispatch = useDispatch()

  const selectAccountHandler = (acc, type) => {
    setAccount({ acc, type })
    !regionDisabled && setRegionDisabled(true)
    if (acc)
      dispatch(intouchAction.requestFeedLibrary({ clientId: acc.ClientId }))
  }

  const selectRegionHandler = r => setRegion(r)
  
  const resetClear = () => setClear(false)

  const updateFeedAccounts = () => dispatch(intouchAction.updateFeedLibrary())

  const submitFeedLibrary = () => {
    if (!region || !account) return
    dispatch(intouchAction.submitFeedLibrary({ RegionId: region.RegionId, ClientId: account.acc.ClientId }))
  }

  useEffect(() => {
    feedLibrary?.loaded && setRegionDisabled(false)
  }, [feedLibrary?.loaded])

  useEffect(() => {
    setLoading(regions?.loading || feedLibrary?.loading ? true : false)
  }, [regions?.loading, feedLibrary?.loading])

  useEffect(() => {
    regions?.message && setMessage({ message: regions.message.msg, severity: regions.message.level, clear: () => dispatch(intouchAction.clearRegionsMessage()) })
    feedLibrary?.message && setMessage({ message: feedLibrary.message.msg, severity: feedLibrary.message.level, clear: () => dispatch(intouchAction.clearFeedLibraryMessage()) })
    if (feedLibrary?.message?.level === 'success') {
      setClear(true)
      setRegion(undefined)
      !regionDisabled && setRegionDisabled(true)
    }
  }, [regions?.message, feedLibrary?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 1, enabledSecurity))
  }, [enabledSecurity])

  return <Paper className={className}>
    <Button
      style={{ alignSelf: 'flex-end' }}
      color='primary'
      variant='contained'
      size='small'
      onClick={updateFeedAccounts}
      startIcon={<SyncIcon size={25} />}
      disabled={feedLibrary?.loading}
    >
      Sync Accounts
    </Button>
    <div className='feed-container'>
      <div className='inputs'>
        <ClientSearchComponent selectedAccount={account} selectAccountHandler={selectAccountHandler} clear={clear} resetClear={resetClear} disabled={hasAccess} />
        <RegionSelectComponent selectedRegion={region} selectRegionHandler={selectRegionHandler} disabled={regionDisabled} />
      </div>
      <Button color='primary' variant='contained' size='small' onClick={submitFeedLibrary} disabled={hasAccess || !account || !region}>
        Save
      </Button>
    </div>
  </Paper>
}

export const FeedLibraryComponent = styled(_FeedLibrary)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 35vh;
  padding: 12px;
  margin-top: 20px;
  .feed-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    column-gap: 80px;
    margin: 80px 0 30px 0;
    .inputs {
      display: flex;
      flex-direction: row;
      column-gap: 40px;
    }
  }
`
export default FeedLibraryComponent