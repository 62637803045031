import React, { useContext, useEffect, useState } from 'react'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import styled from 'styled-components'
import { useTypedAppSelector } from 'src/store'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { useDispatch } from 'react-redux'
import { Avatar, Button, Checkbox, Chip, darken, Dialog, DialogActions, DialogContent, DialogTitle, Fab, LinearProgress, MenuItem, MenuList, Paper, TextField, Tooltip, Typography } from '@mui/material'
import { Add, Edit, EditOutlined } from '@mui/icons-material'
import { BinIcon } from 'src/components/icons/bin'
import { adminAction } from 'src/store/actions/admin.actions'
import { SnackBarContext } from 'src/contexts/snack-bar.context'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _UserManagement: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [user, setUser] = useState<API_NS.Users>()
  const [newUserStr, setNewUserStr] = useState<string>('')
  const [roleToDelete, setRoleToDelete] = useState<API_NS.UserRoles | null>(null)
  const [userToDelete, setUserToDelete] = useState<API_NS.Users | null>(null)
  const [searchUser, setSearchUser] = useState<string>('')
  const [openDeleteRole, setOpenDeleteRole] = useState<boolean>(false)
  const [openDeleteUser, setOpenDeleteUser] = useState<boolean>(false)
  const [openAdd, setOpenAdd] = useState<boolean>(false)
  const [openNewUser, setOpenNewUser] = useState<boolean>(false)
  const users = useTypedAppSelector(s => s.adminState.users)
  const roles = useTypedAppSelector(s => s.adminState.roles)
  const userRoles = useTypedAppSelector(s => s.adminState.userRoles)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { setMessage } = useContext(SnackBarContext)
  const dispatch = useDispatch()

  const chooseUser = u => {
    setUser(u)
    dispatch(adminAction.requestUserRoles({ id: u.InternalUserId }))
  }

  const addNewUser = () => {
    dispatch(adminAction.addNewUser({ 
      InternalUserId: 0,
      InternalUserName: !newUserStr.toLowerCase().includes('@alltech.com') ? newUserStr + '@alltech.com' : newUserStr
    }))
  }

  const addUserRole = roleId => dispatch(adminAction.addUserRole({ InternalUserId: user?.InternalUserId, RoleId: roleId, HasWriteAccess: false }))

  const deleteUserRole = () => {
    roleToDelete && dispatch(adminAction.deleteUserRole({ id: roleToDelete.UserRoleId?.toString() }))
    setRoleToDelete(null)
  }

  const deleteUser = () => {
    userToDelete && dispatch(adminAction.deleteUser({ userId: userToDelete?.InternalUserId.toString() }))
    setUserToDelete(null)
  }

  const handleWriteAccess = ur =>
    dispatch(adminAction.updateUserRole({
      UserRoleId: ur.UserRoleId,
      InternalUserId: ur.InternalUserId,
      RoleId: ur.RoleId,
      HasWriteAccess: !ur.HasWriteAccess
    }))

  useEffect(() => {
    setLoading(users?.loading || userRoles?.loading ? true : false)
  }, [users?.loading, userRoles?.loading])

  useEffect(() => {
    users?.message && setMessage({ message: users.message.msg, severity: users.message.level, clear: () => dispatch(adminAction.clearUsersMessage()) })
    userRoles?.message && setMessage({ message: userRoles.message.msg, severity: userRoles.message.level, clear: () => dispatch(adminAction.clearUserRolesMessage()) })
  }, [users?.message, userRoles?.message])

  const addNewUserDialog = <Dialog fullWidth open={openNewUser} onClose={() => setOpenNewUser(false)}>
    <DialogTitle>Add New User</DialogTitle>
    <DialogContent>
      <Typography variant='body2'>It is important that you use the exact login from Active Directory, e.g. Bob Smith might be bsmith</Typography><br/>
      <TextField
        fullWidth
        variant='outlined'
        size='small'
        placeholder='Enter User Name'
        inputProps={{ style: { padding: 12 } }}
        value={newUserStr}
        onChange={e => setNewUserStr(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenNewUser(false); addNewUser() }}>Add</Button>
      <Button size='small' onClick={() => { setOpenNewUser(false); setNewUserStr('') }}>Cancel</Button>
    </DialogActions>
  </Dialog>

  const deleteUserRoleDialog = <Dialog fullWidth open={openDeleteRole} onClose={() => setOpenDeleteRole(false)}>
    <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this user's role?</DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenDeleteRole(false); deleteUserRole() }}>Confirm</Button>
      <Button size='small' onClick={() => setOpenDeleteRole(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>

  const deleteUserDialog = <Dialog fullWidth open={openDeleteUser} onClose={() => setOpenDeleteUser(false)}>
    <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this user?</DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenDeleteUser(false); deleteUser() }}>Confirm</Button>
      <Button size='small' onClick={() => setOpenDeleteUser(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>

  const addUserRoleDialog = <Dialog fullWidth open={openAdd} onClose={() => setOpenAdd(false)}>
    <DialogTitle>Choose a new role for {user?.Login}</DialogTitle>
    <DialogContent style={{ fontSize: 14 }}>
      <MenuList>
        {(roles?.value || [])
        .filter(r => !Boolean((userRoles?.value || []).find(u => u?.RoleId === r?.RoleId)))
        .sort((a, b) => a.RoleName.toLowerCase() > b.RoleName.toLowerCase() ? 1 : -1)
        .map((role, i) => 
          <MenuItem key={i} style={{ fontSize: 15 }} value={role.RoleId} onClick={() => { setOpenAdd(false); addUserRole(role.RoleId) }}>
            {role.RoleName}
          </MenuItem>
        )}
      </MenuList>
    </DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => setOpenAdd(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>

  return <div className={className}>
    <Paper className='paper paper-div'>
      <Paper className='user-paper'>
        <Tooltip title='Add New User' placement='top'>
          <Fab className='fab-button' color='primary' size='small' onClick={() => setOpenNewUser(true)}><Add /></Fab>
        </Tooltip>
        <div className='userrole-container' style={{ marginTop: 0 }}>
          {Object.values(users?.value || []).length > 0 && <TextField
            style={{ marginBottom: 6 }}
            fullWidth
            variant='filled'
            size='small'
            placeholder='Search for user'
            inputProps={{ style: { padding: 6, fontSize: 14 } }}
            value={searchUser}
            onChange={e => setSearchUser(e.target.value)}
          />}
          {(users?.value || []).filter(u => u.Login.toLowerCase().includes(searchUser.toLowerCase())).map((u, i) => <Chip
            className={`chip ${user?.InternalUserId === u?.InternalUserId ? 'selected' : ''}`}
            key={i}
            style={{ display: 'flex', justifyContent: 'space-between' }}
            label={u?.Login}
            color='primary' 
            deleteIcon={<Tooltip title='Delete'><span style={{ display: 'flex', alignItems: 'center' }}><BinIcon strokeWidth={2} size={25} /></span></Tooltip>}
            avatar={<Avatar>{u?.Login && u.Login.substring(0, 2).toUpperCase()}</Avatar>}
            onDelete={() => { setUserToDelete(u); setOpenDeleteUser(true) }}
            onClick={() => chooseUser(u)}
          />)}
        </div>
      </Paper>
      <Paper className='paper role-paper'>
        {user ? <React.Fragment>
          <Tooltip title='Add New User Role' placement='top'>
            <Fab className='fab-button' color='primary' size='small' disabled={!user} onClick={() => setOpenAdd(true)}>
              <Add />
            </Fab>
          </Tooltip>
          <Typography variant='subtitle2'>{user?.Login}</Typography>
          <div className='userrole-container'>
            {userRoles?.value && Array.isArray(userRoles?.value) && (userRoles?.value || []).map((role, i) => <Chip
              className='chip'
              style={{ display: 'flex', justifyContent: 'space-between' }}
              key={i}
              label={role?.RoleName}
              color='primary'
              deleteIcon={<Tooltip title='Delete'><span style={{ display: 'flex', alignItems: 'center' }}><BinIcon strokeWidth={2} size={25} /></span></Tooltip>}
              onDelete={() => { setRoleToDelete(role); setOpenDeleteRole(true) }}
              avatar={handleWriteAccess ? <Tooltip title='Write Permissions'>
                <Avatar>
                  <Checkbox
                    style={{ color: 'inherit' }}
                    icon={<EditOutlined style={{ opacity: 0.2 }} />}
                    checkedIcon={<Edit />}
                    checked={role.HasWriteAccess}
                    value={role.HasWriteAccess}
                    onChange={() => handleWriteAccess(role)}
                  />
                </Avatar>
              </Tooltip>
              : undefined}
            />)}
            {userRoles?.loading && <LinearProgress style={{ position: 'relative', width: '100%' }} />}
          </div>
        </React.Fragment>
        : <Typography style={{ textAlign: 'center', marginTop: 16 }} variant='subtitle2'>Choose a user from the list</Typography> }
      </Paper>
    </Paper>
    {deleteUserRoleDialog}
    {addUserRoleDialog}
    {addNewUserDialog}
    {deleteUserDialog}
  </div>
}


export const UserManagementComponent = styled(_UserManagement)`
  .paper {
    padding: 24px;
    background: white;
  }
  .paper-div {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .user-paper {
    position: relative;
    width: 60%;
    padding: 32px;
  }
  .role-paper {
    position: relative;
    background: ${p => p.theme.palette.alltech.light_grey[50]};
    width: 40%;
    margin-left: 20px;
  }
  .userrole-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    height: 50vh;
    overflow-y: auto;
  }
  .fab-button {
    position: absolute;
    top: -18px;
    right: 16px;
  }
  .chip {
    margin: 4px 10px;
    min-height: 32px;
    &:hover {
      background: ${p => darken(p.theme.palette.alltech.terracotta.main, 0.3)}
    }
  }
  .selected {
    background: ${p => darken(p.theme.palette.alltech.terracotta.main, 0.3)}
  }
  .MuiChip-avatar {
    margin-left: 0 !important;
    width: 30px !important;
    height: 30px !important;
  }
`
export default UserManagementComponent