import { API_NS } from '../../lib/api/endpoints/endpoint.types'

export const reportsAction = {

  requestReports: () => {
    return { type: "REQUEST_REPORTS" as const };
  },
  requestReportsSuccess: (payload: API_NS.Reports[]) => {
    return { payload, type: "REQUEST_REPORTS_SUCCESS" as const };
  },
  requestReportsFailed: (payload: string) => {
    return { payload, type: "REQUEST_REPORTS_FAILED" as const };
  },

  requestCountries: () => {
    return { type: "REQUEST_COUNTRIES" as const };
  },
  requestCountriesSuccess: (payload: API_NS.Countries[]) => {
    return { payload, type: "REQUEST_COUNTRIES_SUCCESS" as const };
  },
  requestCountriesFailed: (payload: string) => {
    return { payload, type: "REQUEST_COUNTRIES_FAILED" as const };
  },

  requestTranslations: (payload: { report: string, languageCode: string }) => {
    return { payload, type: "REQUEST_TRANSLATIONS" as const };
  },
  requestTranslationsSuccess: (payload: API_NS.Translations[]) => {
    return { payload, type: "REQUEST_TRANSLATIONS_SUCCESS" as const };
  },
  requestTranslationsFailed: (payload: string) => {
    return { payload, type: "REQUEST_TRANSLATIONS_FAILED" as const };
  },

  requestDefaultTranslations: (payload: { report: string }) => {
    return { payload, type: "REQUEST_DEFAULT_TRANSLATIONS" as const };
  },
  requestDefaultTranslationsSuccess: (payload: API_NS.Translations[]) => {
    return { payload, type: "REQUEST_DEFAULT_TRANSLATIONS_SUCCESS" as const };
  },
  requestDefaultTranslationsFailed: (payload: string) => {
    return { payload, type: "REQUEST_DEFAULT_TRANSLATIONS_FAILED" as const };
  },

  requestTranslationElements: (payload: { report: string }) => {
    return { payload, type: "REQUEST_TRANSLATIONS_ELEMENTS" as const };
  },
  requestTranslationElementsSuccess: (payload: { FieldName: string }[]) => {
    return { payload, type: "REQUEST_TRANSLATIONS_ELEMENTS_SUCCESS" as const };
  },
  requestTranslationElementsFailed: (payload: string) => {
    return { payload, type: "REQUEST_TRANSLATIONS_ELEMENTS_FAILED" as const };
  },

  submitTranslations: (payload: {report: string, languageCode: string, body: API_NS.SubmitTranslations[]}) => {
    return {payload, type: "SUBMIT_TRANSLATIONS" as const };
  },
  submitTranslationsSuccess: (payload: API_NS.SubmitTranslations[]) => {
    return { payload, type: "SUBMIT_TRANSLATIONS_SUCCESS" as const };
  },
  submitTranslationsFailed: (payload: string) => {
    return { payload, type: "SUBMIT_TRANSLATIONS_FAILED" as const };
  },

  requestAllProducts: () => {
    return { type: "REQUEST_ALL_PRODUCTS" as const };
  },
  requestAllProductsSuccess: (payload: API_NS.AlltechProduct[]) => {
    return { payload, type: "REQUEST_ALL_PRODUCTS_SUCCESS" as const };
  },
  requestAllProductsFailed: (payload: string) => {
    return { payload, type: "REQUEST_ALL_PRODUCTS_FAILED" as const };
  },

  requestProductsList: () => {
    return { type: "REQUEST_PRODUCTS_LIST" as const };
  },
  requestProductsListSuccess: (payload: API_NS.AlltechProduct[]) => {
    return { payload, type: "REQUEST_PRODUCTS_LIST_SUCCESS" as const };
  },
  requestProductsListFailed: (payload: string) => {
    return { payload, type: "REQUEST_PRODUCTS_LIST_FAILED" as const };
  },
  
  updateAlltechProduct: (payload: API_NS.UpdateAlltechProduct) => {
    return {payload, type: "UPDATE_ALLTECH_PRODUCT" as const };
  },
  updateAlltechProductSuccess: (payload: API_NS.UpdateAlltechProduct) => {
    return { payload, type: "UPDATE_ALLTECH_PRODUCT_SUCCESS" as const };
  },
  updateAlltechProductFailed: (payload: string) => {
    return { payload, type: "UPDATE_ALLTECH_PRODUCT_FAILED" as const };
  },

  requestProductUom: () => {
    return { type: "REQUEST_PRODUCT_UOM" as const };
  },
  requestProductUomSuccess: (payload: API_NS.ProductUom[]) => {
    return { payload, type: "REQUEST_PRODUCT_UOM_SUCCESS" as const };
  },
  requestProductUomFailed: (payload: string) => {
    return { payload, type: "REQUEST_PRODUCT_UOM_FAILED" as const };
  },
  
  requestAllProductUsage: () => {
    return { type: "REQUEST_ALL_PRODUCT_USAGE" as const };
  },
  requestAllProductUsageSuccess: (payload: API_NS.ProductUsage[]) => {
    return { payload, type: "REQUEST_ALL_PRODUCT_USAGE_SUCCESS" as const };
  },
  requestAllProductUsageFailed: (payload: string) => {
    return { payload, type: "REQUEST_ALL_PRODUCT_USAGE_FAILED" as const };
  },

  upsertAllProductUsage: (payload: API_NS.UpsertProductUsage) => {
    return {payload, type: "UPSERT_ALL_PRODUCT_USAGE" as const };
  },
  upsertAllProductUsageSuccess: (payload: boolean) => {
    return { payload, type: "UPSERT_ALL_PRODUCT_USAGE_SUCCESS" as const };
  },
  upsertAllProductUsageFailed: (payload: string) => {
    return { payload, type: "UPSERT_ALL_PRODUCT_USAGE_FAILED" as const };
  },

  deleteProductUsage: (payload: { id: number }) => {
    return {payload, type: "DELETE_PRODUCT_USAGE" as const };
  },
  deleteProductUsageSuccess: (payload: number) => {
    return { payload, type: "DELETE_PRODUCT_USAGE_SUCCESS" as const };
  },
  deleteProductUsageFailed: (payload: string) => {
    return { payload, type: "DELETE_PRODUCT_USAGE_FAILED" as const };
  },

  clearReportsMessage: () => {
    return { type: "CLEAR_REPORTS_MESSAGE" as const };
  },

  clearCountriesMessage: () => {
    return { type: "CLEAR_COUNTRIES_MESSAGE" as const };
  },

  clearTranslationsMessage: () => {
    return { type: "CLEAR_TRANSLATIONS_MESSAGE" as const };
  },

  clearDefaultTranslationsMessage: () => {
    return { type: "CLEAR_DEFAULT_TRANSLATIONS_MESSAGE" as const };
  },

  clearTranslationElementsMessage: () => {
    return { type: "CLEAR_TRANSLATION_ELEMENTS_MESSAGE" as const };
  },

  clearAllProductsMessage: () => {
    return { type: "CLEAR_ALL_PRODUCTS_MESSAGE" as const };
  },

  clearAlltechProductsMessage: () => {
    return { type: "CLEAR_ALLTECH_PRODUCTS_MESSAGE" as const };
  },

  clearProductUomMessage: () => {
    return { type: "CLEAR_PRODUCT_UOM_MESSAGE" as const };
  },

  clearAllProductUsageMessage: () => {
    return { type: "CLEAR_ALL_PRODUCT_USAGE_MESSAGE" as const };
  },
}

export type TReportsAction = ReturnType<typeof reportsAction[keyof typeof reportsAction]>