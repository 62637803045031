import { Typography } from '@mui/material'
import React, { useContext } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { NavBar } from 'src/components/navBar/navbar'
import EmailTranslationsComponent from './translations/email-translation'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import AlltechProductSectionComponent from './products/alltech-products-section'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _ReportsSectionNav: React.FC<proptypes & { className?: string }> = () => {
  const { path } = useRouteMatch()
  return <ErrorBoundary page="reports nav bar">
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography variant='h5'>Reports</Typography>
      <NavBar tabs={[
        {
          title: 'Email Translation',
          to: `${path}/Translations`,
        },
        {
          title: 'Alltech Products',
          to: `${path}/Product`,
        }
      ]} />
    </div>
  </ErrorBoundary>
}

const _ReportsSection: React.FC<proptypes & { className?: string }> = () => {
  const { path } = useRouteMatch()
  const { enabledSecurity } = useContext(EnabledSecurityContext)

  return <React.Fragment>
    <Switch>
      {sortSubFeatures('reports', 30, enabledSecurity) && <Route path={`${path}/Translations`} render={() => <ErrorBoundary page='email translations'><EmailTranslationsComponent /></ErrorBoundary>}></Route>}
      {sortSubFeatures('reports', 31, enabledSecurity) && <Route path={`${path}/Product`} render={() => <ErrorBoundary page='alltech product'><AlltechProductSectionComponent /></ErrorBoundary>}></Route>}
      <Route path='/' render={() => <Redirect to={`${path}/Translations`} />} />
    </Switch>
  </React.Fragment>
}

export const ReportsSectionComponent = _ReportsSection
export const ReportsSectionNavComponent = _ReportsSectionNav
export default ReportsSectionComponent