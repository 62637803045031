import { TSalesAction } from "../actions/sales.actions"
import { Reducer } from "redux"
import { API_NS } from "../../lib/api/endpoints/endpoint.types"
import { TStateAction } from "../actions/actions"
import { AsyncProcessState } from "../types/shared-state-types"

export type salesReducerState = {
  order?: AsyncProcessState<API_NS.Order>;
  tradeins?: AsyncProcessState<API_NS.TradeIns[]>;
  salesStaffs?: AsyncProcessState<API_NS.SalesStaff[]>;
  targetCategories?: AsyncProcessState<API_NS.TargetCategory[]>;
  salesTargets?: AsyncProcessState<API_NS.SalesTarget[]>;
}

const defaultValue: salesReducerState = {
}

const resetState = JSON.parse(JSON.stringify(defaultValue))

export const salesReducer: Reducer<salesReducerState, TSalesAction | TStateAction> = (state = defaultValue, action) => {
  switch (action.type) {
    case "REQUEST_ORDER":
      return {
        ...state,
        order: { loading: true, message: undefined }
      };
    case "REQUEST_ORDER_SUCCESS":
      return {
        ...state,
        order: { loaded: true, loading: false, value: action.payload, message: undefined }
      };
    case "REQUEST_ORDER_FAILED":
      return {
        ...state,
        order: { value: undefined, loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case 'AUTHORISE_ORDER':
      return {
        ...state,
        order: { ...state.order, loading: true, message: undefined }
      }
    case 'AUTHORISE_ORDER_SUCCESS':
      return {
        ...state,
        order: {
          ...state.order,
          value: action.payload?.MINPRICEUNDERAUTHORISED === 'T' && { ...(state.order?.value || {}), MINPRICEUNDERAUTHORISED: 'T' },
          loading: false,
          loaded: true,
          message: undefined
        }
      }
    case 'AUTHORISE_ORDER_FAILED':
      return {
        ...state,
        order: { value: [], loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      }

    case "REQUEST_TRADE_INS":
      return {
        ...state,
        tradeins: { loading: true, message: undefined }
      };
    case "REQUEST_TRADE_INS_SUCCESS":
      return {
        ...state,
        tradeins: { loaded: true, loading: false, value: action.payload, message: undefined }
      };
    case "REQUEST_TRADE_INS_FAILED":
      return {
        ...state,
        tradeins: { value: undefined, loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "SAVE_TRADE_INS":
      return {
        ...state,
        tradeins: { ...state.tradeins, loading: true, message: undefined }
      };
    case "SAVE_TRADE_INS_SUCCESS":
      return {
        ...state,
        tradeins: { ...state.tradeins, loaded: true, loading: false, message: {level: 'success', msg: 'Trade ins has been updated' } }
      };
    case "SAVE_TRADE_INS_FAILED":
      return {
        ...state,
        tradeins: { value: undefined, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while saving trade ins'} }
      };
  
    case "REQUEST_TARGET_CATEGORIES":
      return {
        ...state,
        targetCategories: { loading: true, message: undefined }
      };
    case "REQUEST_TARGET_CATEGORIES_SUCCESS":
      return {
        ...state,
        targetCategories: { loaded: true, loading: false, value: action.payload.sort((a, b) => a.CATEGORYNAME?.toLowerCase() > b.CATEGORYNAME?.toLowerCase() ? 1 : -1), message: undefined }
      };
    case "REQUEST_TARGET_CATEGORIES_FAILED":
      return {
        ...state,
        targetCategories: { value: undefined, loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_SALES_STAFFS":
      return {
        ...state,
        salesStaffs: { loading: true, message: undefined }
      };
    case "REQUEST_SALES_STAFFS_SUCCESS":
      return {
        ...state,
        salesStaffs: { loaded: true, loading: false, value: action.payload.sort((a, b) => a.USERNAME?.toLowerCase() > b.USERNAME?.toLowerCase() ? 1 : -1), message: undefined }
      };
    case "REQUEST_SALES_STAFFS_FAILED":
      return {
        ...state,
        salesStaffs: { value: undefined, loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };

    case "REQUEST_SALES_TARGETS":
      return {
        ...state,
        salesTargets: { loading: true, message: undefined }
      };
    case "REQUEST_SALES_TARGETS_SUCCESS":
      return {
        ...state,
        salesTargets: { loaded: true, loading: false, value: action.payload, message: undefined }
      };
    case "REQUEST_SALES_TARGETS_FAILED":
      return {
        ...state,
        salesTargets: { value: undefined, loading: false, loaded: false, message: {level: 'error', msg: 'There was a problem accessing the database. Please try again later or contact the IT department'} }
      };
  
    case "ADD_SALES_TARGET":
      return {
        ...state,
        salesTargets: { ...state.salesTargets, loading: true, message: undefined }
      };
    case "ADD_SALES_TARGET_SUCCESS":
      return {
        ...state,
        salesTargets: { loaded: true, loading: false, value: [...(state.salesTargets?.value || []), action.payload], message: undefined }
      };
    case "ADD_SALES_TARGET_FAILED":
      return {
        ...state,
        salesTargets: { value: undefined, loading: false, loaded: false, message: {level: 'error', msg: 'A problem occurred while adding sales targets'} }
      };

    case 'CLEAR_ORDER':
      return {
        ...state,
        order: undefined
      }

    case 'CLEAR_ORDER_MESSAGE':
      return {
        ...state,
        order: { ...state.order, message: undefined }
      }

    case 'CLEAR_TRADE_INS':
      return {
        ...state,
        tradeins: undefined
      }

    case 'CLEAR_TRADE_INS_MESSAGE':
      return {
        ...state,
        tradeins: { ...state.tradeins, message: undefined }
        }
  
    case 'CLEAR_SALES_STAFFS_MESSAGE':
      return {
        ...state,
        salesStaffs: { ...state.salesStaffs, message: undefined }
        }

    case 'CLEAR_TARGET_CATEGORIES_MESSAGE':
      return {
        ...state,
        targetCategories: { ...state.targetCategories, message: undefined }
        }

    case 'CLEAR_SALES_TARGETS_MESSAGE':
      return {
        ...state,
        salesTargets: { ...state.salesTargets, message: undefined }
        }

    case 'RESET_STATE':
      return {
        ...resetState,
      };
  }
  return state
}
