import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useTypedAppSelector } from 'src/store'
import styled from 'styled-components'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { reportsAction } from 'src/store/actions/reports.actions'
import { countriesSelection, reportsSelection } from './selections'
import HelperIconComponent from 'src/components/helperIcon/helper-icon'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { SnackBarContext } from 'src/contexts/snack-bar.context'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _EmailTranslations: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [selectedReport, setSelectedReport] = useState<{ReportName: string, ReportId: number}>()
  const [selectedCountry, setSelectedCountry] = useState<{CountryName: string, LanguageCode: string} | null>(null)
  const [translationEdits, setTranslationEdits] = useState<API_NS.SubmitTranslations[]>()
  const [openReport, setOpenReport] = useState<boolean>(false)
  const [openCountry, setOpenCountry] = useState<boolean>(false)
  const [isEdited, setIsEdited] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const reports = useTypedAppSelector(s => s.reportsState.reports)
  const countries = useTypedAppSelector(s => s.reportsState.countries)
  const defaultTranslations = useTypedAppSelector(s => s.reportsState.defaultTranslations)
  const translations = useTypedAppSelector(s => s.reportsState.translations)
  const translationElements = useTypedAppSelector(s => s.reportsState.translationElements)
  const submitLoading = useTypedAppSelector(s => s.reportsState.submitTranslationsLoading)
  const countryRef = useRef<HTMLButtonElement>(null)
  const reportRef = useRef<HTMLButtonElement>(null)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const dispatch = useDispatch()

  const handleReportChange = r => {
    setOpenReport(false)
    if (r.ReportId === selectedReport?.ReportId) return
    setSelectedCountry(null)
    setTranslationEdits([])
    setIsEdited(false)
    setSelectedReport({...r, ReportName: r.ReportName})
    dispatch(reportsAction.requestTranslationElements({ report: r.ReportName?.replace(' ', '') }))
    dispatch(reportsAction.requestDefaultTranslations({ report: r.ReportName?.replace(' ', '') }))
  }

  const handleCountryChange = c => {
    setOpenCountry(false)
    if (c.CountryName === selectedCountry?.CountryName && selectedReport !== undefined) return
    setSelectedCountry(c)
    setIsEdited(false)
    dispatch(reportsAction.requestTranslations({ report: selectedReport?.ReportName?.replace(' ', '') || '', languageCode: c.LanguageCode }))
  }

  const handleChangePage = (_e, newPage) => setPage(newPage)

  const handleChangeRowsPerPage = e => setRowsPerPage(parseInt(e.target.value, 10))

  const findDefaultValue = name => {
    const val = (defaultTranslations?.value || []).find(t => t.FieldName.toLowerCase() === name)
    return val ? val.FieldText : ''
  }

  const handleTranslationEdit = (e, name) => {
    if (translationEdits === undefined) return
    setIsEdited(true)
    if ((translationEdits || []).find(t => t.FieldName === name))
      setTranslationEdits(translationEdits.map(d => { return d.FieldName === name ? {...d, FieldText: e.target.value || ''} : d }))
    else
      setTranslationEdits([
        ...translationEdits,
        {
          FieldName: name, FieldText: e.target.value || '',
          LanguageCode: selectedCountry?.LanguageCode || '',
          ReportName: selectedReport?.ReportName?.replace(' ', '') || ''
        }
      ])
  }

  const resetTranslations = () => {
    setIsEdited(false)
    setTranslationEdits(translations?.value.map((t => ({ ...t, LanguageCode: selectedCountry?.LanguageCode || '', ReportName: selectedReport?.ReportName?.replace(' ', '') || '' }))))
  }

  const submitTranslations = () => {
    dispatch(reportsAction.submitTranslations({ report: selectedReport?.ReportName?.replace(' ', '') || '', languageCode: selectedCountry?.LanguageCode || '', body: translationEdits || [] }))
  }

  useEffect(() => {
    setHasAccess(!sortSubFeatures('reports', 30, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    !submitLoading && translations?.loaded && setIsEdited(false)
    !submitLoading && translations?.message?.level === 'success' && selectedReport?.ReportName &&
      dispatch(reportsAction.requestDefaultTranslations({ report: selectedReport.ReportName.replace(' ', '') }))
  }, [submitLoading, translations])

  useEffect(() => {
    if (selectedReport === null && selectedCountry === null) return
    setTranslationEdits((translations?.value || [])
      .map(r => ({ ...r, LanguageCode: selectedCountry?.LanguageCode || '', ReportName: selectedReport?.ReportName?.replace(' ', '') || '' })))
  }, [translations?.value])

  useEffect(() => {
    setLoading(reports?.loading || countries?.loading || defaultTranslations?.loading || translations?.loading || translationElements?.loading || submitLoading ? true : false)
  }, [reports?.loading, countries?.loading, defaultTranslations?.loading, translations?.loading, translationElements?.loading, submitLoading])

  useEffect(() => {
    reports?.message && setMessage({ message: reports.message.msg, severity: reports.message.level, clear: () => dispatch(reportsAction.clearReportsMessage()) })
    countries?.message && setMessage({ message: countries.message.msg, severity: countries.message.level, clear: () => dispatch(reportsAction.clearCountriesMessage()) })
    defaultTranslations?.message && setMessage({ message: defaultTranslations.message.msg, severity: defaultTranslations.message.level, clear: () => dispatch(reportsAction.clearDefaultTranslationsMessage()) })
    translations?.message && setMessage({ message: translations.message.msg, severity: translations.message.level, clear: () => dispatch(reportsAction.clearTranslationsMessage()) })
    translationElements?.message && setMessage({ message: translationElements.message.msg, severity: translationElements.message.level, clear: () => dispatch(reportsAction.clearTranslationElementsMessage()) })
  }, [reports?.message, countries?.message, defaultTranslations?.message, translations?.message, translationElements?.message])

  useEffect(() => {
    setLoading(true)
    dispatch(reportsAction.requestReports())
    dispatch(reportsAction.requestCountries())
    return () => {
      setLoading(false)
    }
  }, [])

  const actionButtons = <div className='action-buttons'>
    <Button color='primary' size='small' variant='contained' onClick={resetTranslations} disabled={hasAccess || !selectedCountry || !isEdited || submitLoading}>
      Reset
    </Button>
    <Button color='primary' size='small' variant='contained' onClick={submitTranslations} disabled={hasAccess || !selectedCountry || !isEdited || submitLoading}>
      {submitLoading && <CircularProgress style={{ color: 'white', marginRight: 10 }} size={20} />}
      Save
    </Button>
  </div>

  const tableHead = <TableRow style={{ height: 42 }}>
    <TableCell align='left' padding='none' style={{ width: '50%', paddingRight: 8 }}>
      <div className='default-header'>
        Default English Translations
        <HelperIconComponent iconSize={20} helpText={<div style={{ padding: 6, width: 320 }}>
          When there is no translation provided for a specific field for a chosen country, the default English translation for that field is used instead.
        </div>} />
      </div>
    </TableCell>
    <TableCell align='left' padding='none' style={{ width: '50%' }}>
      {countriesSelection(countries?.value, selectedCountry, countryRef, openCountry, setOpenCountry, handleCountryChange, hasAccess)}
    </TableCell>
  </TableRow>

  const tableBody = (translationElements?.value || [])
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((el, i) => <TableRow key={i} style={{ height: 42 }}>
      <TableCell id={el.FieldName} align='left' padding='none' style={{ paddingRight: 8 }}>
        <div style={{ height: '100%', maxHeight: 115, overflowY: 'auto' }}>{findDefaultValue(el.FieldName.toLowerCase())}</div>
      </TableCell>
      <TableCell align='left' padding='none'>
        {translationEdits ? <TextField
          value={translationEdits.find(d => d.FieldName.toLowerCase() === el.FieldName.toLowerCase())?.FieldText || ''}
          onChange={e => handleTranslationEdit(e, el.FieldName.toLowerCase())}
          variant='outlined'
          margin='dense'
          multiline
          maxRows={6}
          style={{ width: '100%' }}
          InputProps={{ style: { padding: '6px 8px' } }}
          inputProps={{ style: { fontSize: 13, lineHeight: 1.2 } }}
          disabled={hasAccess || !selectedCountry || translations?.loading}
        /> : undefined}
      </TableCell>
    </TableRow>
  )

  const tablePagination = (style) => <TablePagination
    style={style}
    component='div'
    rowsPerPageOptions={[10, 25, 50, 100]}
    count={(translationElements?.value || []).length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />

  return <div className={className}>
    <div className='reports-selection-div'>
      {reportsSelection(reports?.value, selectedReport, reportRef, openReport, setOpenReport, handleReportChange, hasAccess)}
    </div>
    {selectedReport && Object.entries(selectedReport).length > 0 && <Paper style={{ width: '100%', padding: '12px 8px', marginTop: 30 }}>
      {actionButtons}
      {tablePagination({ marginTop: 20 })}
      <Table>
        <TableHead>{tableHead}</TableHead>
        <TableBody>{tableBody}</TableBody>
      </Table>
      {tablePagination({ marginBottom: 20 })}
      {actionButtons}
    </Paper>}
  </div>
}


export const EmailTranslationsComponent = styled(_EmailTranslations)`
  display: flex;
  flex-direction: column;
  width: 100%;
  .reports-selection-div {
    display: flex;
    justify-content: flex-end;
  }
  .selection-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
  }
  .reports-dropdown, .countries-dropdown {
    display: flex;
    justify-content: space-between;
    text-transform: none;
    font-size: 14px;
    padding-right: 14px;
    min-width: 180px;
  }
  .countries-dropdown {
   border-radius: 0;
   border-bottom: 1px solid ${p => p.theme.palette.alltech.dark_grey[80]} ;
   font-size: 13px;
  }
  .default-header {
    display: flex;
    align-items: center;
    color: ${p => p.theme.palette.alltech.dark_grey[90]};
  }
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 30px;
  }
  .MuiTableCell-root {
    font-size: 13px;
    line-height: 1.2;
  }
  .MuiTablePagination-displayedRows, .MuiTablePagination-input, .MuiTablePagination-selectLabel {
    font-size: 12px;
    color: ${p => p.theme.palette.alltech.dark_grey[90]};
  }
`
export default EmailTranslationsComponent