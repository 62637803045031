
import { PaletteOptions, lighten } from '@mui/material/styles';

const makeShades = (color: string) => ({
    main: color,
    90: lighten(color, 0.1),
    80: lighten(color, 0.2),
    70: lighten(color, 0.3),
    60: lighten(color, 0.4),
    50: lighten(color, 0.5),
    40: lighten(color, 0.6),
    30: lighten(color, 0.7),
    20: lighten(color, 0.8),
    10: lighten(color, 0.9),
})

const alltech = {
    /** PANTONE_167 */
    terracotta: makeShades("rgb(193, 83, 27)"),
    /** PANTONE_350 */
    dark_green: makeShades("rgb(41, 81,53)"),
    /** PANTONE_376 */
    pale_green: makeShades("rgb(128, 188, 0)"),
    /** PANTONE_130 */
    yellow: makeShades("rgb(245, 168, 0)"),
    /** PANTONE_302 */
    dark_blue: makeShades("rgb(0, 58, 93)"),
    /** PANTONE_653 */
    light_blue: makeShades("rgb(49, 96, 148)"),
    /** PANTONE_431 */
    dark_grey: makeShades("rgb(91, 102, 112)"),
    /** PANTONE_COOL_GREY_1 */
    light_grey: makeShades("rgb(217, 216, 214)"),
    /** PANTONE_873 */
    beige: makeShades("rgb(238, 238, 221)"),
    /** PANTONE_349 */
    green: makeShades("rgb(0, 105, 55)"),
    /** PANTONE_1795 */
    red: makeShades("rgb(215, 40, 47)"),
}

export const MuiPalette: PaletteOptions = {
    primary: {
        main: alltech.terracotta.main
    },
    secondary: {
        main: alltech.dark_grey.main,
        900: '#6B757E',
        800: '#7C858D',
        700: '#8C949B',
        600: '#9DA3A9',
        500: '#ADB3B8',
    },
    error: {
        main: alltech.red.main,
        900: '#DB3E44',
        800: '#DF5359',
        700: '#E3696D',
        600: '#E77E82',
        500: '#EB9497',
    },
    success: {
        main: alltech.green.main,
        900: '#1A784B',
        800: '#33875F',
        700: '#4D9673',
        600: '#66A587',
        500: '#80B49B',
    },
    warning: {
        main: alltech.yellow.main,
        900: '#F6B11A',
        800: '#F7B933',
        700: '#F8C24D',
        600: '#F9CB66',
        500: '#FAD480',
    },
    action: {
        disabled: alltech.light_grey.main
    },
    mode: 'light'
};
export const customColours = {
    series: [
        alltech.terracotta.main,
        alltech.dark_blue.main,
        alltech.light_blue.main,
        alltech.pale_green.main,
        alltech.yellow.main,
        'hsl(240, 100%, 45%)',
        'hsl(120, 100%, 37%)',
        'hsl(296, 100%, 42%)',
        'hsl(180, 100%, 35%)',

        'hsl(0, 100%, 28%)',
        'hsl(45, 100%, 25%)',
        'hsl(120, 100%, 22%)',
        'hsl(240, 100%, 25%)',
        'hsl(296, 100%, 35%)',
        'hsl(180, 100%, 23%)',

        'hsl(0, 31.8%, 55%)',
        'hsl(53, 31%, 41%)',
        'hsl(120, 27.8%, 47.8%)',
        'hsl(240, 41%, 61%)',
        'hsl(296, 36%, 54%)',
        'hsl(180, 52%, 41%)',
    ],
    keenan: {
        green: "rgb(0,105,47)",
        limegreen: "rgb(207,214,0)",
        grey: "rgb(190,192,194)"
    },
    alltech: alltech,
    backgroundLight: {
        lightgrey: "rgba(249, 250, 251, 1)"
    },
    black: {
        90: '#1A1A1A',
        80: '#333333',
        70: '#4D4D4D',
        60: '#666666',
        50: '#808080',
    },
    darkGreen: {
        main: '#295135',
        90: '#3E6249',
        80: '#54745D',
        70: '#698572',
        60: '#7F9786',
        50: '#94A89A',
    },
    limeGreen: {
        main: '#80BC00',
        90: '#8DC31A',
        80: '#99C933',
        70: '#A6D04D',
        60: '#B3D766',
        50: '#C0DE80',
    },
    blue: {
        main: '#003A5D',
        90: '#1A4E6D',
        80: '#33617D',
        70: '#4D758E',
        60: '#66899E',
        50: '#809DAE',
    },
    lightBlue: {
        main: '#316094',
        90: '#46709F',
        80: '#5A80A9',
        70: '#6F90B4',
        60: '#83A0BF',
        50: '#98b0ca',
    },
    lightGrey: {
        main: '#D9D8D6',
        90: '#DDDCDA',
        80: '#E1E0DE',
        70: '#E4E4E2',
        60: '#E8E8E6',
        50: '#ECECEB',
    },
    khaki: {
        main: '#87604B',
        90: '#93705D',
        80: '#9f806F',
        70: '#AB9081',
        60: '#B7A093',
        50: '#C3B0A5',
    }
}

export const palette = { ...MuiPalette, ...customColours }
export type TPalette = typeof palette