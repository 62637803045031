import React from 'react'
import { darken } from '@mui/material'
import styled from 'styled-components'
import { NavTile } from './nav-tile'

export type proptypes = {
  tabs: {
    title: string;
    to: string;
  }[]
}

export const defaultProps: proptypes = {
  tabs: [
    {
      title: 'Feed Library',
      to: '/intouch/FeedLibrary'
    }
  ]
}

const _NavBar: React.FC<proptypes & { className?: string }> = (props) => {
  return <nav className={props.className}>
    {props.tabs.map(tab => (
      <NavTile key={tab.to} title={tab.title} navTo={tab.to} />
    ))}
  </nav>
}

export const NavBar = styled(_NavBar)`
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 10px 0 30px 0;
  background: white;
  box-shadow: 0 2px 8px ${p => darken(p.theme.palette.lightGrey.main, 0.05)};
`