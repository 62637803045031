import React, { useContext, useEffect, useRef, useState } from 'react'
import { ClickAwayListener, Grow, IconButton, InputAdornment, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, TextField } from '@mui/material'
import styled from 'styled-components'
import { AvatarIcon } from '../icons/avatar';
import { useDispatch } from 'react-redux';
import { userAction } from 'src/store/actions/user.actions';
import { useTypedAppSelector } from 'src/store';
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context';
import { ClearIcon } from '../icons/clear';
import { API_NS } from 'src/lib/api/endpoints/endpoint.types';
import { StyledPopper } from './client-search';

export type proptypes = {
  selectedNutritionist?: {acc: API_NS.Accounts | API_NS.Nutritionists, type: 'nutritionist' | 'customer' };
  selectNutritionistHandler?: (acc: API_NS.Accounts | API_NS.Nutritionists | undefined, type: 'nutritionist' | 'customer') => void;
  clear: boolean;
  resetClear?: () => void;
  disabled: boolean;
  clearInput?: boolean;
  setInput?: (b: boolean) => void;
}
export const defaultProps: proptypes = {
  clear: false,
  disabled: false
}

const _NutritionistSearch: React.FC<proptypes & { className?: string }> = (props) => {
  const { className, selectedNutritionist, selectNutritionistHandler, clear, resetClear, clearInput, setInput, disabled} = props
  const [searchText, setSearchText] = useState<string>('')
  const [filteredAccounts, setFilteredAccounts] = useState<any>([])
  const nutritionists = useTypedAppSelector(s => s.userState.nutritionists)
  const anchorRef = useRef<HTMLDivElement>(null)
  const { setLoading } = useContext(AppBarLoadingContext)
  const dispatch = useDispatch()

  const chooseAccount = nut => {
    setSearchText(nut.USERNAME)
    setFilteredAccounts([])
    selectNutritionistHandler && selectNutritionistHandler(nut, 'nutritionist')
    setInput && setInput(true)
  }

  const filterAccounts = () => {
    setFilteredAccounts(
      (nutritionists?.value || [])
        .filter(n => ((n?.USERNAME || '').toLowerCase().includes((searchText || '')?.toLowerCase()) ||
        (n?.USERID || '').toLowerCase().includes((searchText || '')?.toLowerCase())))
        .sort((a, b) => a?.USERNAME < b?.USERNAME ? -1 : 1)
    )
  }

  const searchHandler = e => {
    const str = e.target.value
    if (selectedNutritionist) selectNutritionistHandler && selectNutritionistHandler(undefined, 'nutritionist')
    setSearchText(str)
    if (str?.length < 3) return
    if (str?.length === 3) dispatch(userAction.requestNutritionists({ search: str }))
    if (str?.length > 3) filterAccounts()
  }

  const clearAccounts = () => {
    setSearchText('')
    selectNutritionistHandler && selectNutritionistHandler(undefined, 'nutritionist')
    setFilteredAccounts([])
  }

  useEffect(() => {
    if (clear) {
      setSearchText('')
      selectNutritionistHandler && selectNutritionistHandler(undefined, 'nutritionist')
      setFilteredAccounts([])
      resetClear && resetClear()
    }
  }, [clear, resetClear, selectNutritionistHandler])

  useEffect(() => {
    if (clearInput) {
      setSearchText('')
      setInput && setInput(false)
    }
  }, [clearInput])

  useEffect(() => {
    Object.values(nutritionists?.value || []).length > 0 && setFilteredAccounts(nutritionists?.value)
  }, [nutritionists?.value])

  useEffect(() => {
    setLoading(nutritionists?.loading ? true : false)
  }, [nutritionists?.loading])

  useEffect(() => {
    return () => {
      dispatch(userAction.clearNutritionists())
      setLoading(false)
    }
  }, [])

  return <div className={className}>
    <TextField
      ref={anchorRef}
      fullWidth
      style={{ background: 'white' }}
      variant='outlined'
      size='small'
      placeholder="Enter Nutritionist's Name"
      inputProps={{ style: { padding: 8, fontSize: 14, minWidth: 300 } }}
      value={searchText}
      onChange={searchHandler}
      disabled={disabled}
      InputProps={{ endAdornment: <InputAdornment style={{ display: searchText?.length > 0 ? 'flex' : 'none' }} position='end'>
        <IconButton onClick={() => clearAccounts()} edge='end'>
          <ClearIcon size={16} />
        </IconButton>
      </InputAdornment>}}
    />
    <StyledPopper
      open={filteredAccounts?.length > 0}
      anchorEl={anchorRef.current}
      placement='bottom-start'
      style={{ minWidth: anchorRef.current?.offsetWidth || '100%' }}
      transition
    >
      {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
        <Paper sx={{ bgcolor: 'white', zIndex: 9999 }}>
          <ClickAwayListener onClickAway={() => setFilteredAccounts([])}>
            <MenuList className='menu-list'>
              {filteredAccounts.map((f, i) => <MenuItem key={i} onClick={() => chooseAccount(f)}>
                <ListItemIcon><AvatarIcon size={20} /></ListItemIcon>
                <ListItemText secondary={f.USERNAME} primary={f.USERID} />
              </MenuItem>)}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>}
    </StyledPopper>
  </div>
}

export const NutritionistSearchComponent = styled(_NutritionistSearch)`
`
export default NutritionistSearchComponent