import { Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { NoDataIcon } from '../icons/no-data'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _NoData: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props

  return <div className={className}>
    <Typography variant='h6' color='textSecondary'>No Data Found</Typography>
    <NoDataIcon className='empty-icon' size={150} />
  </div>
}

export const NoDataComponent = styled(_NoData)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    .empty-icon{
        color: ${p => p.theme.palette.lightGrey.main};
    }
    .MuiTypography-root{
        font-weight: 400;
    }
`
export default NoDataComponent