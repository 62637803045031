import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, SxProps, TextField, Tooltip, Typography } from '@mui/material'
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { useHistory, useParams } from 'react-router'
import { ArrowLeftIcon } from 'src/components/icons/arrow-left'
import { EditIcon } from 'src/components/icons/edit'
import { BinIcon } from 'src/components/icons/bin'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import ClientSearchComponent from 'src/components/accountSearch/client-search'

const mailSelection = (regions: API_NS.MailRegions[], selectedRegion: number, updateMailRegion: (obj: any, row: any) => void, hasAccess: boolean, inTable: boolean, sx?: SxProps, row?: any) => {
  return <FormControl sx={{ minWidth: 110 }} size='small'>
    {!inTable && <InputLabel htmlFor='mail-select'>Mail Region</InputLabel>}
    <Select sx={{ ...sx, fontSize: '13px' }} id='mail-select' variant='outlined' color='secondary' value={selectedRegion} label={inTable ? '' : 'Mail Region....'} disabled={hasAccess}>
      {regions.map((r, i) => <MenuItem key={i} style={{ fontSize: 14 }} value={r.MailRegionId} onClick={() => updateMailRegion({MailRegionId: r.MailRegionId}, row)}>
        {r.MailRegionName}
      </MenuItem>)}
    </Select>
  </FormControl>
}

type emailProptypes = {
  open: boolean;
  setOpen: (b: boolean) => void;
  email?: {field: string, value: string};
  saveEmail: (arg: {[key: string]: string}) => void;
}

export const EmailDialog: React.FC<emailProptypes & { className?: string }> = (props) => {
  const { open, setOpen, email, saveEmail } = props
  const [str, setStr] = useState<string>(email?.value || '')
  const [validateEmail, setValidateEmail] = useState<boolean>(true)
  const regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/i)
  
  const emailVerification = str => {
    let count = 0
    let split = str.split(',')
    split.filter(s => regex.test(s) && (count += 1))
    return split.length === count
  }

  const save = (email: {field: string, value: string}) => {
    let emailVerif = email?.value ? emailVerification(email.value) : true
    setValidateEmail(emailVerif)
    if (emailVerif) {
      setOpen(false);
      saveEmail({[email.field]: email.value})
    }
  }

  return <Dialog fullWidth maxWidth='md' open={open} onClose={() => setOpen(false)}>
    <DialogTitle>Edit {email?.field}</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        variant='outlined'
        size='small'
        placeholder='Enter email(s)'
        inputProps={{ style: { padding: 12 } }}
        value={str || ''}
        onChange={e => setStr(e.target.value)}
        error={!validateEmail}
        helperText={validateEmail ? 'Emails separated by comma' : 'Invalid email(s)'}
      />
    </DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { email?.field && save({field: email.field, value: str}) }}>Save</Button>
      <Button size='small' onClick={() => setOpen(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>
}

type recipientProptypes = {
  inclusions: API_NS.ReportConfigInclusion[];
  reportId: number;
  regions: API_NS.MailRegions[];
  hasAccess: boolean;
}

export const NewRecipientContainer: React.FC<recipientProptypes & { className?: string }> = (props) => {
  const { className, inclusions, reportId, regions, hasAccess} = props
  const [account, setAccount] = useState<{acc: API_NS.Accounts, type: 'customer'} | undefined>(undefined)
  const [newRecipient, setNewRecipient] = useState<{MailRegionId: number, Email: string, CC: string}>({MailRegionId: 1, Email: '', CC: ''})
  const [validateEmail, setValidateEmail] = useState<boolean>(true)
  const [validateCC, setValidateCC] = useState<boolean>(true)
  const [clear, setClear] = useState<boolean>(false)
  const { setMessage } = useContext(SnackBarContext)
  const dispatch = useDispatch()
  const regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/i)

  const selectAccountHandler = (acc, type) => setAccount({ acc, type })
  
  const resetClear = () => setClear(false)

  const emailVerification = str => {
    let count = 0
    let split = str.split(',')
    split.filter(s => regex.test(s) && (count += 1))
    return split.length === count
  };

  const addRecipients = () => {
    if (!newRecipient || !account?.acc || !reportId) return
    let emailVerif = newRecipient?.Email ? emailVerification(newRecipient.Email) : true
    let ccVerif = newRecipient?.CC ? emailVerification(newRecipient.CC) : true
    setValidateCC(emailVerif)
    setValidateEmail(ccVerif)

    if (emailVerif && ccVerif) {
      let exists = inclusions?.filter(inc => inc.ClientId === account.acc.ClientCode)
      if (exists.length < 1) {
        dispatch(intouchAction.addReportConfigInclusion({...newRecipient, Email: newRecipient?.Email || '', CC: newRecipient?.CC || '', ReportId: reportId, ClientId: account.acc.ClientCode}))
        setClear(true)
        setNewRecipient({MailRegionId: 1, Email: '', CC: ''})
      } else {
        setMessage({ message: 'Recipient already exists', severity: 'success', clear: null })
      }
    }
  }

  const updateMailRegion = obj => setNewRecipient({ ...newRecipient, ...obj })

  return <div className={className} style={{ width: '100%' }}>
    <Paper className='new-inclusion'>
      <Typography variant='subtitle2' style={{ paddingBottom: 8 }}>New Recipient</Typography>
      <div className='inputs'>
        <ClientSearchComponent className='client-margin' selectedAccount={account} selectAccountHandler={selectAccountHandler} clear={clear} resetClear={resetClear} disabled={hasAccess} />
        <TextField
          id='email-text'
          style={{ margin: '0 20px' }}
          label='Email*'
          inputProps={{ style: { fontSize: 14, minWidth: 200 } }}
          size='small'
          margin='dense'
          fullWidth
          value={newRecipient?.Email || ''}
          disabled={!account?.acc}
          error={!validateEmail}
          onChange={e => setNewRecipient({...newRecipient, Email: e.target.value})}
          helperText={validateEmail ? 'Emails separated by comma' : 'Invalid email(s)'}
        />
        <TextField
          style={{ margin: '0 40px 0 20px' }}
          label='CC'
          inputProps={{ style: { fontSize: 14, minWidth: 200 } }}
          size='small'
          margin='dense'
          fullWidth
          value={newRecipient?.CC || ''}
          disabled={!account?.acc}
          error={!validateCC}
          onChange={e => setNewRecipient({...newRecipient, CC: e.target.value})}
          helperText={validateCC ? 'Emails separated by comma' : 'Invalid email(s)'}
        />
        {mailSelection(regions, newRecipient.MailRegionId, updateMailRegion, !account?.acc || hasAccess, false, { marginBottom: '24px' })}
      </div>
      <Button
        style={{ alignSelf: 'flex-end' }}
        variant='contained'
        color='primary'
        size='small'
        onClick={addRecipients}
        disabled={!account?.acc || !newRecipient?.Email || hasAccess}
      >
        Add Recipient
      </Button>
    </Paper>
  </div>
}

const deleteInclusionDialog = (openDelete, setOpenDelete, deleteInclusion) => <Dialog fullWidth open={openDelete} onClose={() => setOpenDelete(false)}>
  <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this inclusion?</DialogContent>
  <DialogActions>
    <Button size='small' onClick={() => { setOpenDelete(false); deleteInclusion() }}>Confirm</Button>
    <Button size='small' onClick={() => setOpenDelete(false)}>Cancel</Button>
  </DialogActions>
</Dialog>

export type proptypes = {}
export const defaultProps: proptypes = {}

const _ConfigInclusions: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [reportId, setReportId] = useState<number>()
  const [selectedInclusion, setSelectedInclusion] = useState<API_NS.ReportConfigInclusion>()
  const [editEmail, setEditEmail] = useState<{field: string, value: string}>()
  const [inclusionToDelete, setInclusionToDelete] = useState<number>()
  const [openEmail, setOpenEmail] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const regions = useTypedAppSelector(s => s.intouchState.mailRegions)
  const inclusions = useTypedAppSelector(s => s.intouchState.reportConfigInclusions)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { setMessage } = useContext(SnackBarContext)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const CustomGridActionsCellItem = GridActionsCellItem as any

  const columns = [
    {
      field: 'ClientId',
      headerName: 'Client',
      flex: 0.4,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1
    },
    { 
      field: 'Email',
      headerName: 'Email',
      flex: 1,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
    },
    {
      field: 'editEmail',
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={<Tooltip title='Edit Email' followCursor><span><EditIcon className='primary-color' size={22} /></span></Tooltip>}
          label='Edit Email'
          onClick={() => { setEditEmail({ field: 'Email', value: p.row.Email }); setOpenEmail(true); setSelectedInclusion(p.row) }}
          disabled={hasAccess}
        />
      ]
    },
    {
      field: 'CC',
      headerName: 'CC',
      flex: 1,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
    },
    {
      field: 'editCC',
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={<Tooltip title='Edit CC' followCursor><span><EditIcon className='primary-color' size={22} /></span></Tooltip>}
          label='Edit CC'
          onClick={() => { setEditEmail({ field: 'CC', value: p.row.CC }); setOpenEmail(true); setSelectedInclusion(p.row) }}
          disabled={hasAccess}
        />
      ]
    },
    {
      field: 'MailRegionId',
      headerName: 'Mail Region',
      flex: 0.4,
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (p: GridRenderCellParams) => mailSelection((regions?.value || []), p.value, updateMailRegion, hasAccess, true, undefined, p.row),
    },
    {
      field: 'delete',
      flex: 0.1,
      minWidth: 50,
      type: 'actions',
      disableExport: true,
      sortable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={<Tooltip title='Remove Inclusion' followCursor><span><BinIcon className='primary-color' size={25} /></span></Tooltip>}
          label='Remove Inclusion'
          onClick={() => { setInclusionToDelete(p.row.ReportInclusionId); setOpenDelete(true) }}
          disabled={hasAccess}
        />
      ]
    }
  ]
  
  const deleteInclusion = () => inclusionToDelete && dispatch(intouchAction.deleteReportConfigInclusion({ id: inclusionToDelete }))

  const updateMailRegion = (obj, row) => {
    setSelectedInclusion(row)
    updateInclusion(obj)
  }

  const updateInclusion = obj => {
    if (!selectedInclusion) return
    dispatch(intouchAction.updateReportConfigInclusion({...selectedInclusion, ...obj}))
    setSelectedInclusion(undefined)
  }

  useEffect(() => {
    setLoading(inclusions?.loading || regions?.loading ? true : false)
  }, [inclusions?.loading, regions?.loading])

  useEffect(() => {
    inclusions?.message && setMessage({ message: inclusions.message.msg, severity: inclusions.message.level, clear: () => dispatch(intouchAction.clearConfigInclusionsMessage()) })
    regions?.message && setMessage({ message: regions.message.msg, severity: regions.message.level, clear: () => dispatch(intouchAction.clearMailRegionsMessage()) })
  }, [inclusions?.message, regions?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 27, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    let { id } = params
    setReportId(parseInt(id))
    dispatch(intouchAction.requestMailRegions())
    dispatch(intouchAction.requestReportConfigInclusions({ id: parseInt(id) }))
    return () => {
      dispatch(intouchAction.clearReportConfigInclusions())
    }
  }, [])

  return <Paper className={className}>
    <Button style={{ alignSelf: 'flex-start' }} variant='contained' color='primary' size='small' startIcon={<ArrowLeftIcon size={20} />} onClick={() => history.push({ pathname: `/intouch/ARConfig/configurations` })}>
      Back
    </Button>
    <NewRecipientContainer inclusions={inclusions?.value || []} reportId={reportId || 0} regions={regions?.value || []} hasAccess={hasAccess} />
    <DataGridComponent
      title=' '
      data={(inclusions?.value || []).map((inc, i) => ({...inc, id: i}))}
      columns={columns as any}
      apiRef={apiRef}
      rowHeight={45}
      rowsPerPage={25}
      loading={inclusions?.loading}
      defaultState={{ sorting: { sortModel: [{ field: 'ClientId', sort: 'asc' }] }}}
    />
    {openEmail && <EmailDialog open={openEmail} setOpen={setOpenEmail} email={editEmail} saveEmail={updateInclusion} />}
    {openDelete && deleteInclusionDialog(openDelete, setOpenDelete, deleteInclusion)}
  </Paper>
}

export const ConfigInclusionsComponent = styled(_ConfigInclusions)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  .new-inclusion {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    width: 100%;
    padding: 12px 10px;
    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .client-margin {
    margin: 0 20px 24px 0;
  }
  .primary-color {
    color:  ${p => p.theme.palette.alltech.terracotta.main};
  }
`
export default ConfigInclusionsComponent