import React, { useContext } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { NavBar } from 'src/components/navBar/navbar'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import ProductUsageComponent from './product-usage'
import ProductMaintenanceComponent from './product-maintenance'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _AlltechProductSection: React.FC<proptypes & { className?: string }> = (props) => {
  const { path } = useRouteMatch()
  const { enabledSecurity } = useContext(EnabledSecurityContext)

  return <div className={props.className}>
    <ErrorBoundary page="alltech product tabs">
      <NavBar className='nav-bar' tabs={[
        {
          title: 'Product Usage',
          to: `${path}/usage`,
        },
        {
          title: 'Maintenance',
          to: `${path}/maintenance`,
        }
      ]} />
    </ErrorBoundary>
    <Switch>
      {sortSubFeatures('intouch', 31, enabledSecurity) && <Route path={`${path}/usage`} render={() => <ErrorBoundary page='usage'><ProductUsageComponent /></ErrorBoundary>}></Route>}
      {sortSubFeatures('intouch', 31, enabledSecurity) && <Route path={`${path}/maintenance`} render={() => <ErrorBoundary page='maintenance'><ProductMaintenanceComponent /></ErrorBoundary>}></Route>}
    </Switch>
  </div>
}

export const AlltechProductSectionComponent = styled(_AlltechProductSection)`
  width: 100%;
  .nav-bar {
    height: 34px;
    margin-top: 0;
  }
`
export default AlltechProductSectionComponent