import { API_NS } from '../../lib/api/endpoints/endpoint.types'

export const rpmAction = {

  requestClientRpms: (payload: { clientId: string }) => {
    return { payload, type: "REQUEST_CLIENT_RPMS" as const };
  },
  requestClientRpmsSuccess: (payload: API_NS.Rpms[]) => {
    return { payload, type: "REQUEST_CLIENT_RPMS_SUCCESS" as const };
  },
  requestClientRpmsFailed: (payload: string) => {
    return { payload, type: "REQUEST_CLIENT_RPMS_FAILED" as const };
  },

  requestNutritionistRpms: (payload: { id: string }) => {
    return { payload, type: "REQUEST_NUTRITIONIST_RPMS" as const };
  },
  requestNutritionistRpmsSuccess: (payload: API_NS.Rpms[]) => {
    return { payload, type: "REQUEST_NUTRITIONIST_RPMS_SUCCESS" as const };
  },
  requestNutritionistRpmsFailed: (payload: string) => {
    return { payload, type: "REQUEST_NUTRITIONIST_RPMS_FAILED" as const };
  },

  updateComment: (payload: API_NS.UpdateComment) => {
    return { payload, type: "UPDATE_COMMENT" as const };
  },
  updateCommentSuccess: (payload: API_NS.UpdateComment) => {
    return { payload, type: "UPDATE_COMMENT_SUCCESS" as const };
  },
  updateCommentFailed: (payload: string) => {
    return { payload, type: "UPDATE_COMMENT_FAILED" as const };
  },

  updateValidation: (payload: API_NS.UpdateValidation) => {
    return { payload, type: "UPDATE_VALIDATION" as const };
  },
  updateValidationSuccess: (payload: API_NS.UpdateValidation) => {
    return { payload, type: "UPDATE_VALIDATION_SUCCESS" as const };
  },
  updateValidationFailed: (payload: string) => {
    return { payload, type: "UPDATE_VALIDATION_FAILED" as const };
  },

  requestRpmEmailList: (payload: { clientCode: string }) => {
    return { payload, type: "REQUEST_RPM_EMAIL_LIST" as const };
  },
  requestRpmEmailListSuccess: (payload: API_NS.RpmEmailList[]) => {
    return { payload, type: "REQUEST_RPM_EMAIL_LIST_SUCCESS" as const };
  },
  requestRpmEmailListFailed: (payload: string) => {
    return { payload, type: "REQUEST_RPM_EMAIL_LIST_FAILED" as const };
  },

  sendRpmEmail: (payload: API_NS.SendRpmEmail) => {
    return { payload, type: "SEND_RPM_EMAIL" as const };
  },
  sendRpmEmailSuccess: (payload: boolean) => {
    return { payload, type: "SEND_RPM_EMAIL_SUCCESS" as const };
  },
  sendRpmEmailFailed: (payload: string) => {
    return { payload, type: "SEND_RPM_EMAIL_FAILED" as const };
  },

  sendRpmEmailReset: () => {
    return { type: "SEND_RPM_EMAIL_RESET" as const };
  },

  clearRpms: () => {
    return { type: "CLEAR_RPMS" as const };
  },

  clearRpmsMessage: () => {
    return { type: "CLEAR_RPMS_MESSAGE" as const };
  },
  
  clearValidationStatus: () => {
    return { type: "CLEAR_VALIDATION_STATUS" as const };
  },

  clearRpmEmailList: () => {
    return { type: "CLEAR_RPM_EMAIL_LIST" as const };
  },

  clearRpmEmailMessage: () => {
    return { type: "CLEAR_RPM_EMAIL_MESSAGE" as const };
  }
}

export type TRpmAction = ReturnType<typeof rpmAction[keyof typeof rpmAction]>