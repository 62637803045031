import { TypedUseSelectorHook, useSelector } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { TStateAction } from "./actions/actions";
import { TAdminAction } from "./actions/admin.actions";
import { TIntouchAction } from "./actions/intouch.actions";
import { TReportsAction } from "./actions/reports.actions";
import { TRpmAction } from "./actions/rpm.actions";
import { TSalesAction } from "./actions/sales.actions";
import { TUserAction } from "./actions/user.actions";
import { adminEpic } from "./epics/admin.epic";
import { intouchEpic } from "./epics/intouch.epic";
import { reportsEpic } from "./epics/reports.epic";
import { rpmEpic } from "./epics/rpm.epic";
import { salesEpic } from "./epics/sales.epic";
import { userEpic } from "./epics/user.epic";
import { adminReducer } from "./reducers/admin.reducer";
import { intouchReducer } from "./reducers/intouch.reducer";
import { reportsReducer } from "./reducers/reports.reducer";
import { rpmReducer } from "./reducers/rpm.reducer";
import { salesReducer } from "./reducers/sales.reducer";
import { userReducer } from "./reducers/user.reducer";

/**
 * combination of all epics (async action listerners) into one
 */
const appEpics = combineEpics(
    userEpic,
    intouchEpic,
    adminEpic,
    rpmEpic,
    reportsEpic,
    salesEpic
)

/**
 * combination of individual reducers, (chunks of global app state)
 */
export const appReducer = combineReducers({
    userState: userReducer,
    intouchState: intouchReducer,
    adminState: adminReducer,
    rpmState: rpmReducer,
    reportsState: reportsReducer,
    salesState: salesReducer
})

type TStoreActions =
    | TUserAction
    | TIntouchAction
    | TAdminAction
    | TStateAction
    | TRpmAction
    | TReportsAction
    | TSalesAction

export type TCombinedState = ReturnType<typeof appReducer>

/**
 * Strictly typed version of the standard "useSelector" hook
 * @example const user = useTypedAppSelector(userSelectors.user)
 * @example const user = useTypedAppSelector(state => state.users.user)
 */
export const useTypedAppSelector: TypedUseSelectorHook<TCombinedState> = useSelector

/**
 * Create Redux Store with middleware and reducers attached.
 */
export const configureAppStore = (enableDevTools: boolean) => {

    const epicMiddleware = createEpicMiddleware<TStoreActions, any, TCombinedState>();
    const applyEpics = applyMiddleware(epicMiddleware)

    /**
     * apply epic middleware to the store,
     * use with chrome redux devtools only if enabled in the environment file
     */
    const appliedMiddleware = enableDevTools ? composeWithDevTools(applyEpics) : applyEpics

    const store = createStore<TCombinedState, TStoreActions, unknown, unknown>(appReducer, appliedMiddleware)

    /**
     * attach epics to the store
     */
    epicMiddleware.run(appEpics);

    return store
}

const reduxStore = configureAppStore(process.env.NODE_ENV !== "production")

const mockStore = createStore<TCombinedState, TStoreActions, unknown, unknown>(appReducer)

// expose store when run in Cypress
if ((window as any).Cypress) {
    (window as any).store = reduxStore
}

export { reduxStore, mockStore };
