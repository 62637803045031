import React, { ReactChild } from 'react'
import { ThemeOptions, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider } from 'styled-components'
import { MuiTheme } from './theme';

type props = {
  children: ReactChild,
  themeOverrides?: ThemeOptions
}

export const KADDiThemeProvider = (props: props) => {
  return <MuiThemeProvider theme={MuiTheme(props.themeOverrides)}>
    <ThemeProvider theme={MuiTheme(props.themeOverrides)}>
      {props.children}
    </ThemeProvider>
  </MuiThemeProvider>
}


