import { API_NS } from '../../lib/api/endpoints/endpoint.types'

export const salesAction = {

  requestOrder: (payload: { id: string }) => {
    return { payload, type: "REQUEST_ORDER" as const };
  },
  requestOrderSuccess: (payload: API_NS.Order) => {
    return { payload, type: "REQUEST_ORDER_SUCCESS" as const };
  },
  requestOrderFailed: (payload: string) => {
    return { payload, type: "REQUEST_ORDER_FAILED" as const };
  },

  authoriseOrder: (payload: { SalesOrderId: string }) => {
    return { payload, type: "AUTHORISE_ORDER" as const };
  },
  authoriseOrderSuccess: (payload: API_NS.Order) => {
    return { payload, type: "AUTHORISE_ORDER_SUCCESS" as const };
  },
  authoriseOrderFailed: (payload: string) => {
    return { payload, type: "AUTHORISE_ORDER_FAILED" as const };
  },

  requestTradeIns: (payload: { orderId: string }) => {
    return { payload, type: "REQUEST_TRADE_INS" as const };
  },
  requestTradeInsSuccess: (payload: API_NS.TradeIns[]) => {
    return { payload, type: "REQUEST_TRADE_INS_SUCCESS" as const };
  },
  requestTradeInsFailed: (payload: string) => {
    return { payload, type: "REQUEST_TRADE_INS_FAILED" as const };
  },

  saveTradeIns: (payload: API_NS.SaveTradeIn[]) => {
    return { payload, type: "SAVE_TRADE_INS" as const };
  },
  saveTradeInsSuccess: (payload: boolean) => {
    return { payload, type: "SAVE_TRADE_INS_SUCCESS" as const };
  },
  saveTradeInsFailed: (payload: string) => {
    return { payload, type: "SAVE_TRADE_INS_FAILED" as const };
  },

  requestSalesStaffs: () => {
    return { type: "REQUEST_SALES_STAFFS" as const };
  },
  requestSalesStaffsSuccess: (payload: API_NS.SalesStaff[]) => {
    return { payload, type: "REQUEST_SALES_STAFFS_SUCCESS" as const };
  },
  requestSalesStaffsFailed: (payload: string) => {
    return { payload, type: "REQUEST_SALES_STAFFS_FAILED" as const };
  },

  requestTargetCategories: () => {
    return { type: "REQUEST_TARGET_CATEGORIES" as const };
  },
  requestTargetCategoriesSuccess: (payload: API_NS.TargetCategory[]) => {
    return { payload, type: "REQUEST_TARGET_CATEGORIES_SUCCESS" as const };
  },
  requestTargetCategoriesFailed: (payload: string) => {
    return { payload, type: "REQUEST_TARGET_CATEGORIES_FAILED" as const };
  },

  requestSalesTargets: (payload: { targetCategoryId: number, market: string, year: number }) => {
    return { payload, type: "REQUEST_SALES_TARGETS" as const };
  },
  requestSalesTargetsSuccess: (payload: API_NS.SalesTarget[]) => {
    return { payload, type: "REQUEST_SALES_TARGETS_SUCCESS" as const };
  },
  requestSalesTargetsFailed: (payload: string) => {
    return { payload, type: "REQUEST_SALES_TARGETS_FAILED" as const };
  },

  addSalesTarget: (payload: API_NS.SalesTargetReq) => {
    return { payload, type: "ADD_SALES_TARGET" as const };
  },
  addSalesTargetSuccess: (payload: API_NS.SalesTarget) => {
    return { payload, type: "ADD_SALES_TARGET_SUCCESS" as const };
  },
  addSalesTargetFailed: (payload: string) => {
    return { payload, type: "ADD_SALES_TARGET_FAILED" as const };
  },
  
  clearOrder: () => {
    return { type: "CLEAR_ORDER" as const };
  },
  
  clearOrderMessage: () => {
    return { type: "CLEAR_ORDER_MESSAGE" as const };
  },

  clearTradeIns: () => {
    return { type: "CLEAR_TRADE_INS" as const };
  },
  
  clearTradeInsMessage: () => {
    return { type: "CLEAR_TRADE_INS_MESSAGE" as const };
  },

  clearSalesStaffsMessage: () => {
    return { type: "CLEAR_SALES_STAFFS_MESSAGE" as const };
  },

  clearTargetCategoriesMessage: () => {
    return { type: "CLEAR_TARGET_CATEGORIES_MESSAGE" as const };
  },

  clearSalesTargetsMessage: () => {
    return { type: "CLEAR_SALES_TARGETS_MESSAGE" as const };
  },

}

export type TSalesAction = ReturnType<typeof salesAction[keyof typeof salesAction]>