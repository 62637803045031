import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, ClickAwayListener, Grow, ListItemText, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material'
import styled from 'styled-components'
import { useTypedAppSelector } from 'src/store';
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context';
import { API_NS } from 'src/lib/api/endpoints/endpoint.types';
import { intouchSelectors } from 'src/store/selectors/intouch.selector';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { intouchAction } from 'src/store/actions/intouch.actions';

export type proptypes = {
  selectedRegion?: API_NS.Regions & { FullName: string };
  selectRegionHandler?: (acc: API_NS.Regions | undefined) => void;
  disabled?: boolean;
}

export const defaultProps: proptypes = {
}

export const StyledPopper = styled(Popper)`
  z-index: 9999;
  .menu-list {
    max-height: 300px;
    overflow-y: auto;
  }
  .MuiListItemText-primary {
    font-size: 13px;
  }
`

const _RegionSelect: React.FC<proptypes & { className?: string }> = (props) => {
  const { className, selectedRegion, selectRegionHandler, disabled} = props
  const [open, setOpen] = useState<boolean>(false)
  const regions = useTypedAppSelector(s => s.intouchState.regions)
  const regionsList = useTypedAppSelector(intouchSelectors.regionsList)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const { setLoading } = useContext(AppBarLoadingContext)
  const dispatch = useDispatch()

  const chooseRegion = region => {
    selectRegionHandler && selectRegionHandler(region)
  }

  useEffect(() => {
    setLoading(regions?.loading ? true : false)
  }, [regions?.loading])

  useEffect(() => {
    dispatch(intouchAction.requestRegions())
    setLoading(false)
  }, [])

  return <div className={className}>
    <Button
      className='region-button'
      ref={anchorRef}
      color='secondary'
      variant='outlined'
      size='small'
      onClick={() => setOpen(!open)}
      endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
      disabled={disabled}
    >
      {(selectedRegion?.FullName || '').length > 0 ? selectedRegion?.FullName : 'Choose Region'}
    </Button>
    <StyledPopper
      open={open}
      anchorEl={anchorRef.current}
      placement='bottom-start'
      style={{ minWidth: anchorRef.current?.offsetWidth || '100%' }}
      transition
    >
      {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
        <Paper sx={{ bgcolor: 'white' }}>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <MenuList className='menu-list' autoFocusItem variant='selectedMenu' >
              {(regionsList || []).map((r, i) =>
                <MenuItem key={i} value={r.RegionId} selected={r.RegionId === selectedRegion?.RegionId} onClick={() => { setOpen(false); chooseRegion(r) }}>
                  <ListItemText primary={r.FullName} />
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>}
    </StyledPopper>
  </div>
}

export const RegionSelectComponent = styled(_RegionSelect)`
  .region-button {
    min-width: 300px;
    font-size: 13px;
    justify-content: space-between;
    padding: 6px;
  }
`
export default RegionSelectComponent