import React, { useContext, useEffect, useRef, useState } from 'react'
import { Card, CardContent, ClickAwayListener, darken, Grow, IconButton, ListItemText, MenuItem, MenuList, Paper, Tooltip, Typography } from '@mui/material';
import styled from 'styled-components'
import { LockIcon } from 'src/components/icons/lock';
import { RolesIcon } from 'src/components/icons/roles';
import { UsersIcon } from 'src/components/icons/users';
import { ListIcon } from 'src/components/icons/list';
import { APIConfigIcon } from 'src/components/icons/api-config';
import { APIContractIcon } from 'src/components/icons/api-contract';
import { DemoUsersIcon } from 'src/components/icons/demo-users';
import { FeedLibraryIcon } from 'src/components/icons/feed-library';
import { MappingsIcon } from 'src/components/icons/mappings';
import { UomMappingIcon } from 'src/components/icons/uom-mapping';
import { ReviewIcon } from 'src/components/icons/review';
import { TranslationIcon } from 'src/components/icons/translation';
import { AuthoriseOrderIcon } from 'src/components/icons/authorise-order';
import { SalesTargetsIcon } from 'src/components/icons/sales-targets';
import { TradeInsIcon } from 'src/components/icons/trade-ins';
import { EnabledSecurityContext } from 'src/contexts/enable-security.context';
import { sortSubFeatures } from 'src/lib/utils/methods';
import { useHistory } from 'react-router';
import { StyledPopper } from './rpm/rpm-validation';
import { CircleTickIcon } from 'src/components/icons/circle-tick';
import { CircleIcon } from 'src/components/icons/circle';
import { SettingsIcon } from 'src/components/icons/settings';
import { useTypedAppSelector } from 'src/store';

export type proptypes = {}

const _HomePage: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const defaultMenu = ['Feed Library', 'RPM Validation', 'Provider Mappings', 'API Config']
  const [menuSettings, setMenuSettings] = useState<string[]>(defaultMenu)
  const [openSettings, setOpenSettings] = useState<boolean>(false)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const features = useTypedAppSelector(s => s.userState.userFeatures)
  const ref = useRef<HTMLButtonElement>(null)
  const history = useHistory()
  
  const fullList = [
    { name: 'API', icon: <ListIcon size={50} />, section: 'intouch', path: 'intouch/API', permission: 26 },
    { name: 'API Config', icon: <APIConfigIcon size={50} />, section: 'intouch', path: 'intouch/ARConfig/configurations', permission: 27 },
    { name: 'API Contracts', icon: <APIContractIcon size={50} />, section: 'intouch', path: 'intouch/ARConfig/contracts', permission: 28 },
    { name: 'Demo Users', icon: <DemoUsersIcon size={50} />, section: 'intouch', path: 'intouch/IntouchDemoUser', permission: 29 },
    { name: 'Feed Library', icon: <FeedLibraryIcon size={50} />, section: 'intouch', path: 'intouch/FeedLibrary', permission: 1 },
    { name: 'Order Authorise', icon: <AuthoriseOrderIcon size={50} />, section: 'sales', path: 'Sales/salesorderauth', permission: 25 },
    { name: 'Permissions', icon: <LockIcon size={50} />, section: 'admin', path: 'admin/permissions', permission: 22 },
    { name: 'Provider Mappings', icon: <MappingsIcon size={50} />, section: 'intouch', path: 'intouch/ProviderMappings', permission: 18 },
    { name: 'Roles', icon: <RolesIcon size={50} />, section: 'admin', path: 'admin/userroles/roles', permission: 22 },
    { name: 'RPM Validation', icon: <ReviewIcon size={50} />, section: 'rpm', path: 'rpm/RPMValidation', permission: 17 },
    { name: 'Sales Targets', icon: <SalesTargetsIcon size={50} />, section: 'sales', path: 'Sales/targets', permission: 24 },
    { name: 'Trade Ins', icon: <TradeInsIcon size={50} />, section: 'sales', path: 'Sales/TradeIns', permission: 20 },
    { name: 'Translations', icon: <TranslationIcon size={50} />, section: 'reports', path: 'reports/Translations', permission: 30 },
    { name: 'UOM Mappings', icon: <UomMappingIcon size={50} />, section: 'intouch', path: 'intouch/UoMMapping', permission: 19 },
    { name: 'Users', icon: <UsersIcon size={52} />, section: 'admin', path: 'admin/userroles/users', permission: 22 },
  ]

  const onChangeHandler = val => {
    let arr = menuSettings.includes(val.name) ? menuSettings.filter(m => m !== val.name) : [...menuSettings, val.name]
    setMenuSettings((arr || []).length === 0 ? defaultMenu : arr)
    localStorage.setItem('quickmenu', JSON.stringify((arr || []).length === 0 ? defaultMenu : arr))
  }

  useEffect(() => {
    const item = localStorage.getItem('quickmenu')
    item && setMenuSettings(JSON.parse(item))
  }, [])

  return <div className={className}>
    <div className='title-container'>
      <Typography variant='h5'>Quick Access</Typography>
      {Object.values(features?.value || []).length > 0 && <Tooltip title='Quick Access Settings' followCursor>
        <IconButton ref={ref} size='small' onClick={() => setOpenSettings(true)}><SettingsIcon size={28} /></IconButton>
      </Tooltip>}
      <StyledPopper
        open={openSettings}
        anchorEl={ref.current}
        role={undefined}
        placement='bottom-start'
        style={{ minWidth: ref.current?.offsetWidth || '100%' }}
        data-cy='kpi-dropdown'
        transition
      >
        {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
          <Paper sx={{ bgcolor: 'white' }}>
            <ClickAwayListener onClickAway={() => setOpenSettings(false)}>
                <MenuList id='quick-menu' autoFocusItem={openSettings} style={{ maxHeight: 300, overflowY: 'auto', paddingTop: 0 }} variant='menu'>
                  {fullList.map((fl, i) => sortSubFeatures(fl.section, fl.permission, enabledSecurity) ?
                    <MenuItem key={i} value={fl.name} onClick={() => onChangeHandler(fl)} selected={menuSettings?.includes(fl.name)}>
                      {menuSettings?.includes(fl.name) ? <CircleTickIcon size={25} /> : <CircleIcon size={25} />}
                      <ListItemText primaryTypographyProps={{ fontSize: '13px' }} primary={fl.name} />
                    </MenuItem>
                  : undefined)}
                </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>}
      </StyledPopper>
    </div>
    <Paper className='paper'>
      {Object.values(features?.value || []).length > 0 ? <div className='container'>
        {fullList.map((fl, i) => sortSubFeatures(fl.section, fl.permission, enabledSecurity) && menuSettings.includes(fl.name) ?
          <Card key={i} className='card-menu' onClick={() => history.push(fl.path)}>
            <CardContent>
              {fl.icon}
              <Typography className='card-title'>{fl.name}</Typography>
            </CardContent>
          </Card>
        : undefined )}
      </div>
      : <div className='no-menu'>We can't determine what you can view...</div>}
    </Paper>
  </div>
}

const HomePageComponent = styled(_HomePage)`
  margin-top: 30px;
  width: 98%;
  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .paper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 400px;
    margin-top: 8px;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 20px;
  }
  .card-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4px;
    width: 250px;
    background: ${p => p.theme.palette.alltech.terracotta[90]};
    color: white;
    .MuiCardContent-root {
      padding: 12px;
      text-align: center;
    }
    .card-title {
      font-size: 13px;
      font-weight: 500;
    }
    &:hover {
      background: ${p => darken(p.theme.palette.alltech.terracotta.main, 0.2)};
    }
  }
  .no-menu {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 100%;
  }
`
export default HomePageComponent
