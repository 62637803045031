import { Button, darken } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export type proptypes = {
  navTo: string;
  title: string;
}
export const defaultProps: proptypes = {
  navTo: './',
  title: 'Permissions',
}

const _NavTile: React.FC<proptypes & { className?: string }> = (props) => {
  return <div className={props.className}>
    <Button component={React.forwardRef<HTMLAnchorElement, any>((_props, _ref) => (
      <NavLink activeClassName={'selected'} ref={_ref} to={props.navTo} {..._props} />
    ))}>
      {props.title}
    </Button>
  </div>
}

export const NavTile = styled(_NavTile)`
  .MuiButton-root {
    flex: auto;
    padding: 8px 16px;
    border-radius: 0;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 13px;
    &:hover {
      background: none;
    }
    &.selected {
      background: ${p => p.theme.palette.alltech.terracotta.main};
      color: white;
      border-radius: 0;
      &:hover {
        background: ${p => darken(p.theme.palette.primary.main, 0.2)};
        color: white;
      }
    }
    &:not(.selected){
      color: ${p => p.theme.palette.alltech.terracotta.main};
      background: white; 
      &:hover {
        background: ${p => darken(p.theme.palette.common.white, 0.05)};
      }
    }
  }
`