import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Button, ClickAwayListener, Grow, Input, lighten, MenuItem, MenuList, Paper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { API_NS } from "src/lib/api/endpoints/endpoint.types";
import { useTypedAppSelector } from "src/store";
import styled from "styled-components";
import { StyledPopper } from "../rpm/rpm-validation";

export const marketsSelection = (markets, selectedMarket, marketHandler, marketRef, openMarket, setOpenMarket, loading) => <div className='selection-form'>
  <Typography style={{ fontSize: 11, visibility: selectedMarket?.Value ? 'visible' : 'hidden' }}>Market*</Typography>
  <Button
    className='dropdown'
    ref={marketRef}
    color='secondary'
    variant='outlined'
    size='small'
    onClick={() => setOpenMarket(!openMarket)}
    endIcon={openMarket ? <ArrowDropUp /> : <ArrowDropDown />}
    disabled={loading}
  >
    {selectedMarket?.Value || 'Market*'}
  </Button>
  <StyledPopper open={openMarket} anchorEl={marketRef.current} placement='bottom-start' style={{ minWidth: marketRef.current?.offsetWidth || '100%' }} transition>
    {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
      <Paper sx={{ bgcolor: 'white' }}>
        <ClickAwayListener onClickAway={() => setOpenMarket(false)}>
          <MenuList autoFocusItem style={{ maxHeight: 300, overflowY: 'auto', padding: '4px 0' }} variant='selectedMenu'>
          <MenuItem style={{ fontSize: 14 }} value='' onClick={() => marketHandler({Key: null, Value: ''})} selected={selectedMarket.Key === null}><em>None</em></MenuItem>
            {(markets?.value || []).filter(m => m.Value !== 'All').map((m, i) =>
              <MenuItem key={i} style={{ fontSize: 14 }} value={m.Key} onClick={() => marketHandler(m)} selected={selectedMarket.Key === m.Key}>
                {m.Value}
              </MenuItem>
            )}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Grow>}
  </StyledPopper>
</div>

export const categoriesSelection = (categories, selectedCategory, categoryHandler, categoryRef, openCategory, setOpenCategory, loading) => <div className='selection-form'>
  <Typography style={{ fontSize: 11, visibility: selectedCategory?.name ? 'visible' : 'hidden' }}>Category*</Typography>
  <Button
    className='dropdown'
    ref={categoryRef}
    color='secondary'
    variant='outlined'
    size='small'
    onClick={() => setOpenCategory(!openCategory)}
    endIcon={openCategory ? <ArrowDropUp /> : <ArrowDropDown />}
    disabled={loading}
  >
    {selectedCategory?.name || 'Category*'}
  </Button>
  <StyledPopper open={openCategory} anchorEl={categoryRef.current} placement='bottom-start' style={{ minWidth: categoryRef.current?.offsetWidth || '100%' }} transition>
    {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
      <Paper sx={{ bgcolor: 'white' }}>
        <ClickAwayListener onClickAway={() => setOpenCategory(false)}>
          <MenuList autoFocusItem style={{ maxHeight: 300, overflowY: 'auto', padding: '4px 0' }} variant='selectedMenu'>
          <MenuItem style={{ fontSize: 14 }} value='' onClick={() => categoryHandler({id: null, name: ''})} selected={selectedCategory.id === null}><em>None</em></MenuItem>
            {(categories?.value || []).map((c, i) => (
              <MenuItem
                key={i}
                style={{ fontSize: 14 }}
                value={c.C_TARGETCATEGORYID}
                onClick={() => categoryHandler({id: c.C_TARGETCATEGORYID, name: c.CATEGORYNAME})}
                selected={selectedCategory.id === c.C_TARGETCATEGORYID}
              >
                {c.CATEGORYNAME}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Grow>}
  </StyledPopper>
</div>

type staffProptypes = {
  open: boolean;
  setOpen: (b: boolean) => void;
  selectedStaff: API_NS.SalesStaff | undefined;
  staffHandler: (s: API_NS.SalesStaff | undefined) => void;
}

const StyledStaffPopper = styled(StyledPopper)`
  .staff-search {
    width: 100%;
    padding: 4px 0;
    font-size: 14px;
    background-color: ${p => lighten(p.theme.palette.lightGrey[50], 0.3)};
  }
`

export const StaffSelection: React.FC<staffProptypes & { className?: string }> = (props) => {
  const { className, open, setOpen, selectedStaff, staffHandler } = props
  const salesStaff = useTypedAppSelector(s => s.salesState.salesStaffs)
  const [filtered, setFiltered] = useState<API_NS.SalesStaff[]>()
  const [search, setSearch] = useState<string>('')
  const staffRef = useRef<HTMLButtonElement>(null)
  const searchRef = useRef<HTMLButtonElement>(null)

  const handleSearch = str => {
    setSearch(str)
    setFiltered((salesStaff?.value || []).filter(s => s.USERNAME.toLowerCase().includes(str.toLowerCase())))
  }

  useEffect(() => {
    searchRef?.current?.focus()
  }, [search])

  useEffect(() => {
    salesStaff?.loaded && setFiltered(salesStaff?.value)
  }, [salesStaff])

  return <div className={className} style={{ width: '100%' }}>
    <div className='selection-form'>
      <Typography style={{ fontSize: 11, visibility: selectedStaff?.USERNAME ? 'visible' : 'hidden' }}>Staff*</Typography>
      <Button
        className='dropdown'
        ref={staffRef}
        color='secondary'
        variant='outlined'
        size='small'
        onClick={() => setOpen(!open)}
        endIcon={open? <ArrowDropUp /> : <ArrowDropDown />}
        disabled={salesStaff?.loading}
      >
        {selectedStaff?.USERNAME || 'Staff*'}
      </Button>
      <StyledStaffPopper open={open} anchorEl={staffRef.current} placement='bottom-start' style={{ minWidth: staffRef.current?.offsetWidth || '100%' }} transition>
        {({ TransitionProps }) => <Grow {...TransitionProps} timeout={200}>
          <Paper sx={{ bgcolor: 'white' }}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <div>
                <Input
                  id='staff-search'
                  className='staff-search'
                  inputRef={searchRef}
                  type='search'
                  placeholder='Search Staff'
                  defaultValue={search}
                  onChange={e => handleSearch(e.target.value)}
                  inputProps={{ style: { padding: '5px 0 5px 16px' } }}
                />
                <MenuList autoFocusItem style={{ maxHeight: 300, overflowY: 'auto', padding: '4px 0' }} variant='selectedMenu'>
                <MenuItem style={{ fontSize: 14 }} value='' onClick={() => staffHandler(undefined)} selected={selectedStaff?.USERID === null}><em>None</em></MenuItem>
                  {(filtered || []).map((f, i) => (
                    <MenuItem
                      key={i}
                      style={{ fontSize: 14 }}
                      value={f.USERID}
                      onClick={() => staffHandler(f)}
                      selected={selectedStaff?.USERID === f.USERID}
                    >
                      {f.USERNAME}
                    </MenuItem>
                  ))}
                </MenuList>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>}
      </StyledStaffPopper>
    </div>
  </div>
}