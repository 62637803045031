import { Typography } from '@mui/material'
import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { NavBar } from 'src/components/navBar/navbar'
import PermissionsComponent from './permissions'
import UserRolesSectionComponent from './user-roles.section'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _AdminSectionNav: React.FC<proptypes & { className?: string }> = () => {
  const { path } = useRouteMatch()
  return <ErrorBoundary page="admin nav bar">
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography variant='h5'>Administration</Typography>
      <NavBar tabs={[
        {
          title: 'Permissions',
          to: `${path}/permissions`,
        },
        {
          title: 'User Role Management',
          to: `${path}/userroles`,
        }
      ]} />
    </div>
  </ErrorBoundary>
}

const _AdminSection: React.FC<proptypes & { className?: string }> = () => {
  const { path } = useRouteMatch()
  return <React.Fragment>
    <Switch>
      <Route path={`${path}/permissions`} render={() => <ErrorBoundary page='permissions'><PermissionsComponent /></ErrorBoundary>}></Route>
      <Route path={`${path}/userroles`} render={() => <ErrorBoundary page='user roles'><UserRolesSectionComponent /></ErrorBoundary>}></Route>
      <Route path="/" render={() => <Redirect to={`${path}/permissions`} />} />
    </Switch>
  </React.Fragment>
}

export const AdminSectionComponent = _AdminSection
export const AdminSectionNavComponent = _AdminSectionNav
export default AdminSectionComponent