import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { Button, Paper } from '@mui/material'
import { GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { useHistory } from 'react-router'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _AccuracyReportContracts: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const accuracyReports = useTypedAppSelector(s => s.intouchState.accuracyReports)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const history = useHistory()

  const columns = [
    {
      field: 'ReportName',
      headerName: 'Reports',
      flex: 1,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
    },
    {
      field: 'config',
      headerName: 'Configuration',
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (p: GridRenderCellParams) => <Button
        variant='contained'
        size='small'
        color='primary'
        onClick={() => history.push(`/intouch/ARConfig/contracts/${p.row.ReportId}`)}
        disabled={hasAccess}
      >
        Inclusions
      </Button>
    },
  ]

  useEffect(() => {
    setLoading(accuracyReports?.loading ? true : false)
  }, [accuracyReports?.loading])

  useEffect(() => {
    accuracyReports?.message && setMessage({ message: accuracyReports.message.msg, severity: accuracyReports.message.level, clear: () => dispatch(intouchAction.clearAccuracyReportsMessage()) })
  }, [accuracyReports?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 28, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    dispatch(intouchAction.requestAccuracyReports())
  }, [])

  return <Paper className={className}>
    <DataGridComponent
      title=' '
      data={accuracyReports?.value || []}
      columns={columns as any}
      apiRef={apiRef}
      rowHeight={45}
      defaultRowId={'ReportId'}
      loading={accuracyReports?.loading}
      usePagination={false}
      useSearch={false}
      defaultState={{ sorting: { sortModel: [{ field: 'ReportName', sort: 'asc' }] }}}
    />
  </Paper>
}

export const AccuracyReportContractsComponent = styled(_AccuracyReportContracts)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 20px 8px;
  .MuiButton-root {
    font-size: 12px;
  }
`
export default AccuracyReportContractsComponent