import React, { useState } from 'react';

const AppBarLoadingContext = React.createContext();

const AppBarLoadingContextProvider = (props) => {
  const [loading, _setLoading] = useState(false)

  const setLoading = load => _setLoading(load)

  return <AppBarLoadingContext.Provider value={{ loading, setLoading }}>
    {props.children}
  </AppBarLoadingContext.Provider>
}

export { AppBarLoadingContext, AppBarLoadingContextProvider };