import React, { forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { DataGridPro, GridCellParams, GridColDef, GridRowClassNameParams, GridRowHeightReturnValue, GridRowsProp, GridSelectionModel, GridSortModel, GridToolbarContainer, GridToolbarQuickFilter, GridFilterModel, gridClasses, GridRowHeightParams } from '@mui/x-data-grid-pro'
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { TablePaginationComponent } from './table-pagination';
import { Checkbox, darken, SxProps, Typography } from '@mui/material';
import NoDataComponent from '../common/no-data';
import { SearchIcon } from '../icons/search';
import { CircleTickIcon } from '../icons/circle-tick';
import { CircleIcon } from '../icons/circle';
import { CircleLineIcon } from '../icons/circle-line';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export type proptypes = {
  data: GridRowsProp;
  columns: GridColDef[];
  title?: string | JSX.Element;
  loading?: boolean;
  apiRef: any;
  defaultState?: GridInitialStatePro | undefined;
  defaultRowId?: string;
  headerHeight?: number;
  rowHeight?: number;
  getRowHeight?: ((params: GridRowHeightParams) => GridRowHeightReturnValue);
  sortChangeHandler?: (model: GridSortModel) => void | undefined;
  filtered?: string[] | undefined;
  filterChangeHandler?: (model: GridFilterModel) => void | undefined;
  useSearch?: boolean;
  usePagination?: boolean;
  rowsPerPage?: number;
  pinnedRow?: any | undefined;
  totalsPosition?: 'top' | 'bottom' | undefined;
  headerMenu?: () => JSX.Element | undefined;
  footer?: any | undefined;
  detailPanel?: any;
  onRowClick?: ((e: any) => void) | undefined;
  getRowClassName?: (p: GridRowClassNameParams) => string;
  getCellClassName?: (p: GridCellParams) => string;
  sx?: SxProps;
  onVisibleRowsChange?: (() => void) | undefined;
  checkboxSelection?: boolean;
  disableRowSelectionOnClick?: boolean;
  selectionModelHandler?: ((e: any) => void) | undefined;
  selectionModel?: GridSelectionModel;
  filters?: string;
  showPanelToggleIcon?: boolean;
  disableColumnResize?: boolean;
}

const CustomToolbar = props => {
  return <GridToolbarContainer>
    {props.title && <Typography variant='subtitle1'>{props.title}</Typography>}
    <div>
      {props.useSearch && <GridToolbarQuickFilter />}
      {props.headerMenu && props.headerMenu()}
    </div>
  </GridToolbarContainer>
}

const _DataGrid: React.FC<proptypes & { className?: string }> = (props) => {
  const {
    className,
    data,
    columns,
    title,
    loading = false,
    apiRef,
    defaultState,
    defaultRowId,
    headerHeight = 45,
    rowHeight = 35,
    getRowHeight,
    sortChangeHandler,
    filtered,
    filterChangeHandler,
    useSearch = true,
    usePagination = true,
    rowsPerPage = 10,
    pinnedRow = {},
    totalsPosition = undefined,
    headerMenu,
    footer,
    detailPanel,
    onRowClick,
    getRowClassName,
    getCellClassName,
    sx,
    onVisibleRowsChange,
    checkboxSelection,
    disableRowSelectionOnClick = true,
    selectionModelHandler,
    selectionModel,
    showPanelToggleIcon = false,
    disableColumnResize = true
  } = props

  const [page, setPage] = useState<number>(0)
  const [rowsPage, setRowsPage] = useState<number>(rowsPerPage)
  const [rowPin, setRowPin] = useState<boolean>(false)
  const RowDetailPanel = detailPanel

  useEffect(() => {
    filtered !== undefined && apiRef.current.setQuickFilterValues(filtered)
  }, [filtered, apiRef])

  useEffect(() => {
    setRowPin(Boolean(totalsPosition))
  }, [totalsPosition])

  useEffect(() => {
    onVisibleRowsChange && onVisibleRowsChange()
  }, [page, rowsPage, onVisibleRowsChange, sortChangeHandler, filterChangeHandler])

  const ColourSearchIcon = () => <SearchIcon className='icon-color' />
  const NoData = () => <div style={{ height: data?.length > 0 ? 0 : 250 }}><NoDataComponent /></div>
  const CustomCheckIcon = ({ checkboxRef, ...props }: { checkboxRef: React.ForwardedRef<HTMLButtonElement> }) => (
      <Checkbox {...props} ref={checkboxRef} icon={<CircleIcon />} checkedIcon={<CircleTickIcon />} indeterminateIcon={<CircleLineIcon />} />
  )
  const ForwardCheckIcon = forwardRef<HTMLButtonElement>((props, ref) => { return <CustomCheckIcon {...props} checkboxRef={ref} /> })

  return <div className={className}>
    <DataGridPro
      sx={{...sx,
        [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: { outline: 'transparent' },
        [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: { outline: 'none' }
      }}
      apiRef={apiRef}
      rows={data}
      pinnedRows={pinnedRow && totalsPosition ? { [totalsPosition]: [pinnedRow] } : undefined}
      columns={columns}
      loading={loading}
      getRowId={defaultRowId ? r => r[defaultRowId] : undefined}
      disableColumnMenu
      disableColumnReorder
      disableColumnSelector
      disableColumnFilter
      disableColumnResize={disableColumnResize}
      disableDensitySelector
      disableSelectionOnClick={disableRowSelectionOnClick}
      isRowSelectable={p => false}
      headerHeight={headerHeight}
      rowHeight={rowHeight}
      getRowHeight={getRowHeight}
      autoHeight={data?.length > 0}
      sortingOrder={['asc', 'desc']}
      initialState={{
          ...defaultState,
          columns: {
              ...defaultState?.columns,
              columnVisibilityModel: { ...defaultState?.columns?.columnVisibilityModel, "__detail_panel_toggle__": showPanelToggleIcon }
          }
      }}
      components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: NoData,
          QuickFilterIcon: ColourSearchIcon,
          BaseCheckbox: ForwardCheckIcon,
          DetailPanelExpandIcon: ExpandMore,
          DetailPanelCollapseIcon: ExpandLess,
          Footer: footer
      }}
      componentsProps={{
          pagination: { ActionsComponent: TablePaginationComponent },
          toolbar: {
              quickFilterProps: { debounceMs: 100 },
              title: title,
              useSearch: useSearch,
              headerMenu: headerMenu,
              data: data,
          }
      }}
      localeText={{
          toolbarExport: '',
          toolbarQuickFilterPlaceholder: '',
          noResultsOverlayLabel: 'No Results Found',
          MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) => `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`,
              labelRowsPerPage: 'Rows per Page'
          }
      }}
      onSortModelChange={sortChangeHandler}
      onFilterModelChange={filterChangeHandler}
      getDetailPanelHeight={() => 'auto'}
      getDetailPanelContent={({ row }) => RowDetailPanel ? <RowDetailPanel row={row} /> : undefined}
      onRowClick={e => {
          if (onRowClick) { onRowClick(e.row) }
          else {
              const prev = apiRef.current.getExpandedDetailPanels()[0];
              if (prev !== e.id) apiRef.current.toggleDetailPanel(prev);
              apiRef.current.toggleDetailPanel(e.id)
          }
      }}
      getRowClassName={getRowClassName}
      getCellClassName={getCellClassName}
      experimentalFeatures={{ rowPinning: rowPin }}
      page={page}
      pageSize={rowsPage}
      onPageChange={page => setPage(page)}
      onPageSizeChange={pageSize => { setRowsPage(pageSize); setPage(0); }}
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination={usePagination}
      hideFooter={!usePagination && !footer}
      checkboxSelection={checkboxSelection}
      onSelectionModelChange={selectionModelHandler}
      selectionModel={selectionModel}
    />
  </div>
}

export const DataGridComponent = styled(_DataGrid)`
  min-height: 50px;
  width: 100%;
  .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
    margin: 0;
  }
  .MuiTablePagination-selectLabel, .MuiTablePagination-input, .MuiTablePagination-displayedRows {
    font-size: 12px;
    color: ${p => p.theme.palette.alltech.dark_grey[90]}
  }
  .MuiDataGrid-root {
    border: none;
  }
  .MuiDataGrid-main {
    box-shadow: 0 3px 4px ${p => p.theme.palette.lightGrey.main};
    border-radius: 4px;
    min-height: ${p => p.data.length > 0 ? '100%' : '250px'}
  }
  .MuiDataGrid-cell, .MuiDataGrid-columnHeader {
    font-size: 13px;
    padding: 0 4px;
    &:focus, &:focus-within {
      outline: none;
    }
  }
  .MuiDataGrid-columnHeaderCheckbox {
    padding: 0;
  }
  .MuiDataGrid-columnSeparator {
    display: ${p => p.disableColumnResize || p.disableColumnResize === undefined ? 'none' : 'flex'} !important;
  }
  .MuiDataGrid-toolbarContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
    .MuiButtonBase-root {
      min-width: 40px;
      .MuiButton-startIcon {
        margin: 0;
      }
    }
    .MuiInputBase-input {
      font-size: 14px;
    }
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid ${p => p.theme.palette.secondary[800]};
  }
  .loading-backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: inherit;
    z-index: 3;
    background-color: white;
    opacity: 0.5;
  }
  .icon-color {
    color: ${p => p.theme.palette.primary.main};
  }
  .MuiDataGrid-row--detailPanelExpanded {
    background-color: ${p => p.theme.palette.primary.main} !important;
    outline: 1px solid ${p => p.theme.palette.primary.main} !important;
    outline-offset: -1px;
    color: white;
    &:hover {
      background-color: ${p => darken(p.theme.palette.primary.main, 0.2)};
    }
    .icon {
      color: white;
    }
  }
  .MuiDataGrid-detailPanel {
    outline: 1px solid ${p => p.theme.palette.primary.main};
    outline-offset: -1px;
    border-radius: 0 0 5px 5px;
  }
  .MuiDataGrid-detailPanelToggleCell--expanded {
    color: white;
  }
  .no-results {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MuiDataGrid-pinnedRows {
    background: ${p => p.theme.palette.secondary[900]};
    color: white;
    font-weight: 500;
  }
  .MuiDialog-paper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    .loading-spinner {
      margin-right: 10px;
    }
  }
`