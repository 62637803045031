import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { Button, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import { GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { useTypedAppSelector } from 'src/store'
import { salesAction } from 'src/store/actions/sales.actions'
import { SettingsIcon } from 'src/components/icons/settings'
import { useIntl } from 'react-intl'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { ClearIcon } from 'src/components/icons/clear'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _Tradeins: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [orderStr, setOrderStr] = useState<string>('') // OR44YA100034 for testing
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const tradeIns = useTypedAppSelector(s => s.salesState.tradeins)
  const [list, setList] = useState<API_NS.TradeIns[] | undefined>()
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const dispatch = useDispatch()
  const intl = useIntl()
  const apiRef = useGridApiRef()

  const columns = [
    {
      field: 'icon',
      headerName: ' ',
      flex: 0.1,
      disableExport: true,
      sortable: false,
      renderCell: (_p: GridRenderCellParams) => <div style={{ display: 'flex' }}><SettingsIcon className='settings-icon' size={24} /></div>
    },
    {
      field: 'trade',
      headerName: ' ',
      flex: 1,
      sortable: false,
      renderCell: (p: GridRenderCellParams) => <div style={{ maxWidth: '100%' }}>
        <Typography variant='body1'>{p.row.NAME}</Typography>
        <Typography variant='caption'>Purchased on {intl.formatDate(p.row.C_PURCHASEDATE, { year: 'numeric', month: '2-digit', day: '2-digit' })}</Typography>
      </div>
    },
    {
      field: 'TRADEINPRICE',
      headerName: 'Trade-in Price',
      flex: 0.5,
      renderCell: (p: GridRenderCellParams) => <TextField
        value={p?.value || ''}
        onChange={e => updatePrice(e, 'TRADEINPRICE', p.row.C_ACCPRODTRADEINID)}
        variant='standard'
        margin='dense'
        type='number'
        style={{ width: '100%' }}
        inputProps={{ style: { fontSize: 13 } }}
        disabled={true}
      /> 
    },
    {
      field: 'MAXTRADINPRICE',
      headerName: 'Max Trade-in Price',
      flex: 0.5,
      renderCell: (p: GridRenderCellParams) => <TextField
        value={p?.value || ''}
        onChange={e => updatePrice(e, 'MAXTRADINPRICE', p.row.C_ACCPRODTRADEINID)}
        variant='standard'
        margin='dense'
        type='number'
        style={{ width: '100%' }}
        inputProps={{ style: { fontSize: 13 } }}
        disabled={hasAccess}
      /> 
    },
  ]

  const updatePrice = (e, col, id) => {
    setList((list || []).map(t => {
      if (t.C_ACCPRODTRADEINID === id)
        t[col] = e.target.value
      return t
    }))
  }

  const cancelHandler = () => {
    setOrderStr('')
    setList(undefined)
    dispatch(salesAction.clearTradeIns())
  }

  const saveHandler = () => {
    const body = (list || []).map(l => ({
      TradeInPriceId: l.C_ACCPRODTRADEINID,
      MaxTradeInPrice: l.MAXTRADINPRICE
    }))

    dispatch(salesAction.saveTradeIns(body))
  }

  const searchHandler = () => dispatch(salesAction.requestTradeIns({ orderId: orderStr }))

  const clearOrders = () => {
    setOrderStr('')
    setList(undefined)
    dispatch(salesAction.clearTradeIns())
  }

  useEffect(() => {
    if (tradeIns?.loaded) setList(tradeIns?.value || [])
  }, [tradeIns?.loaded, tradeIns?.value])

  useEffect(() => {
    setLoading(tradeIns?.loading ? true : false)
  }, [tradeIns?.loading])

  useEffect(() => {
    tradeIns?.message && setMessage({ message: tradeIns.message.msg, severity: tradeIns.message.level, clear: () => dispatch(salesAction.clearTradeInsMessage()) })
  }, [tradeIns?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 20, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    return () => {
      dispatch(salesAction.clearTradeIns())
      setLoading(false)
    }
  }, [])

  const actionButtons = <div className='action-buttons'>
    <Button variant='contained' size='small' color='primary' onClick={cancelHandler} disabled={tradeIns?.loading}>Cancel</Button>
    <Button variant='contained' size='small' color='primary' onClick={saveHandler} disabled={tradeIns?.loading || Object.values(tradeIns?.value || []).length < 1}>Save</Button>
  </div>

  return <Paper className={className}>
    <div className='container'>
      <TextField
        label='Enter Order ID'
        inputProps={{ style: { fontSize: 14, minWidth: 400, textTransform: 'uppercase', padding: '10px 14px' } }}
        size='small'
        margin='dense'
        value={orderStr || ''}
        disabled={hasAccess}
        onChange={e => setOrderStr(e.target.value)}
        InputProps={{ endAdornment: <InputAdornment style={{ display: orderStr?.length > 0 ? 'flex' : 'none' }} position='end'>
          <IconButton onClick={() => clearOrders()} edge='end'>
            <ClearIcon size={16} />
          </IconButton>
        </InputAdornment>}}
      />
      <Button className='search-button' color='primary' variant='contained' size='small' disabled={!orderStr || hasAccess} onClick={searchHandler}>Search</Button>
    </div>
    {tradeIns?.value && <div style={{ marginTop: 50, width: '100%' }}>
      {actionButtons}
      <div className='tradeins-table'>
        <DataGridComponent
          title=' '
          data={(tradeIns?.value)?.map((v, i) => ({ id: i, ...v })) || []}
          columns={columns}
          apiRef={apiRef}
          rowHeight={45}
          rowsPerPage={25}
          getRowHeight={() => 'auto'}
          useSearch={false}
          loading={false}
          defaultState={{ sorting: { sortModel: [{ field: 'NAME', sort: 'asc' }] } }}
        />
      </div>
      {actionButtons}
    </div>}
  </Paper>
}

export const TradeinsComponent = styled(_Tradeins)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 50vh;
  padding: 30px;
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .tradeins-table {
    width: 100%;
    margin: 20px 0;
  }
  .search-button {
    margin-left: 20px;
  }
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    column-gap: 30px;
  }
  .settings-icon {
    color: ${p => p.theme.palette.alltech.dark_grey[80]};
  }
`
export default TradeinsComponent