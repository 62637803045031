import { API_NS } from '../../lib/api/endpoints/endpoint.types'
import { AccountInfo } from '@azure/msal-common';

export const userAction = {
  setUser: (payload: AccountInfo | null) => {
    return { payload, type: 'SET_USER' as const }
  },

  requestUserFeatures: (payload: { email: string }) => {
    return { payload, type: "REQUEST_USER_FEATURES" as const };
  },
  requestUserFeaturesSuccess: (payload: API_NS.UserFeatures[]) => {
    return { payload, type: "REQUEST_USER_FEATURES_SUCCESS" as const };
  },
  requestUserFeaturesFailed: (payload: string) => {
    return { payload, type: "REQUEST_USER_FEATURES_FAILED" as const };
  },

  requestAllAccounts: (payload: { search: string }) => {
    return {payload, type: "REQUEST_ALL_ACCOUNTS" as const };
  },
  requestAllAccountsSuccess: (payload: API_NS.Accounts[]) => {
    return { payload, type: "REQUEST_ALL_ACCOUNTS_SUCCESS" as const };
  },
  requestAllAccountsFailed: (payload: string) => {
    return { payload, type: "REQUEST_ALL_ACCOUNTS_FAILED" as const };
  },

  requestNutritionists: (payload: { search: string }) => {
    return {payload, type: "REQUEST_NUTRITIONISTS" as const };
  },
  requestNutritionistsSuccess: (payload: API_NS.Nutritionists[]) => {
    return { payload, type: "REQUEST_NUTRITIONISTS_SUCCESS" as const };
  },
  requestNutritionistsFailed: (payload: string) => {
    return { payload, type: "REQUEST_NUTRITIONISTS_FAILED" as const };
  },

  clearAccounts: () => {
    return { type: "CLEAR_ALL_ACCOUNTS" as const };
  },

  clearNutritionists: () => {
    return { type: "CLEAR_NUTRITIONISTS" as const };
  }
}

export type TUserAction = ReturnType<typeof userAction[keyof typeof userAction]>