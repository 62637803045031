import React, { useContext, useEffect } from 'react'
import { Drawer, lighten, List, ListItem, ListItemText } from '@mui/material';
import styled from 'styled-components'
import { useTypedAppSelector } from 'src/store';
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import ErrorBoundary from 'src/components/ErrorBoundary';
import { sortFeatures } from 'src/lib/utils/methods';
import { userAction } from 'src/store/actions/user.actions';
import { useDispatch } from 'react-redux';
import { EnabledSecurityContext } from 'src/contexts/enable-security.context';
import HomePageComponent from 'src/pages/sections/homepage';
import AdminSectionComponent, { AdminSectionNavComponent } from 'src/pages/sections/admin/admin.section';
import RPMSectionComponent, { RPMSectionNavComponent } from 'src/pages/sections/rpm/rpm.section';
import ReportsSectionComponent, { ReportsSectionNavComponent } from 'src/pages/sections/reports/reports.section';
import IntouchSectionComponent, { IntouchSectionNavComponent } from 'src/pages/sections/intouch/intouch.section';
import SalesSectionComponent, { SalesSectionNavComponent } from 'src/pages/sections/sales/sales.section';
import { SnackBarContext } from 'src/contexts/snack-bar.context';

export type proptypes = {
  drawerOpen: boolean;
  onDrawerToggle: () => void;
}

export const defaultProps: proptypes = {
  drawerOpen: false,
  onDrawerToggle: () => {}
}

const _AppRoutes: React.FC<proptypes & { className?: string }> = (props) => {
  const { className, drawerOpen, onDrawerToggle } = props
  const features = useTypedAppSelector(s => s.userState.userFeatures)
  const user = useTypedAppSelector(s => s.userState.user)
  const { enabledSecurity, setEnabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const location = useLocation()
  const dispatch = useDispatch()

  const handleDrawerClose = () => { if (window.screen.width <= 600) setTimeout(onDrawerToggle, 250) }

  useEffect(() => {
    features?.message && setMessage({ message: features.message.msg, severity: features.message.level, clear: null })
  }, [features?.message])

  useEffect(() => {
    user && dispatch(userAction.requestUserFeatures({ email: user.username.split('@')[0] }))
  }, [])

  return <div className={className}>
    <Drawer
      className='drawer'
      variant='persistent'
      anchor='left'
      PaperProps={{ style: { position: 'fixed', width: 180, marginTop: 64 } }}
      open={drawerOpen}
    >
      {features?.loaded && <List component='nav'> {/* TOTAL NUMBER OF FEATURES FOR ADMIN = 15 */}
        {process.env.REACT_APP_ENV !== 'production' && (features?.value || []).length === 15 && <ListItem button dense onClick={() => setEnabledSecurity(!enabledSecurity)}>
          <ListItemText primary={enabledSecurity ? 'Disable Security' : 'Enable Security'} />
        </ListItem>}
        <ListItem button dense component={Link} to='/' onClick={handleDrawerClose} selected={location.pathname === '/'}>
          <ListItemText primary='Home' />
        </ListItem>
        <ListItem button dense component={Link} to='/admin' onClick={handleDrawerClose} selected={location.pathname.includes('/admin')}>
          <ListItemText primary='Administration' />
        </ListItem>
        <ListItem button dense component={Link} to='/intouch' onClick={handleDrawerClose} selected={location.pathname.includes('/intouch')}>
          <ListItemText primary='InTouch' />
        </ListItem>
        <ListItem button dense component={Link} to='/rpm' onClick={handleDrawerClose} selected={location.pathname.includes('/rpm')}>
          <ListItemText primary='RPM' />
        </ListItem>
        <ListItem button dense component={Link} to='/reports' onClick={handleDrawerClose} selected={location.pathname.includes('/reports')}>
          <ListItemText primary='Reports' />
        </ListItem>
        <ListItem button dense component={Link} to='/Sales' onClick={handleDrawerClose} selected={location.pathname.includes('/Sales')}>
          <ListItemText primary='Sales' />
        </ListItem>
      </List>}
    </Drawer>
    <main className={`content ${drawerOpen ? 'contentShift' : ''}`}>
      {location.pathname === '/' && <div className={`helpText ${drawerOpen ? 'helpTextShift' : ''}`}>
        Customise the quick access menu with shortcuts to features you use frequently with the settings icon in the top right hand corner of the page.
      </div>}
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '100%' }}>
        <Switch>
          <Route path='/admin' render={() => <AdminSectionNavComponent />} />
          <Route path='/intouch' render={() => <IntouchSectionNavComponent />} />
          <Route path='/rpm' render={() => <RPMSectionNavComponent />} />
          <Route path='/reports' render={() => <ReportsSectionNavComponent />} />
          <Route path='/Sales' render={() => <SalesSectionNavComponent />} />
        </Switch>

        <Switch>
          {sortFeatures(features?.value || [], 'admin', enabledSecurity) && <Route path='/admin' render={() => <ErrorBoundary page='admin section'><AdminSectionComponent /></ErrorBoundary>} />}
          {sortFeatures(features?.value || [], 'intouch', enabledSecurity) && <Route path='/intouch' render={() => <ErrorBoundary page='intouch section'><IntouchSectionComponent /></ErrorBoundary>} />}
          {sortFeatures(features?.value || [], 'rpm', enabledSecurity) && <Route path='/rpm' render={() => <ErrorBoundary page='rpm section'><RPMSectionComponent /></ErrorBoundary>} />}
          {sortFeatures(features?.value || [], 'reports', enabledSecurity) && <Route path='/reports' render={() => <ErrorBoundary page='reports section'><ReportsSectionComponent /></ErrorBoundary>} />}
          {sortFeatures(features?.value || [], 'sales', enabledSecurity) && <Route path='/Sales' render={() => <ErrorBoundary page='sales section'><SalesSectionComponent /></ErrorBoundary>} />}
          <Route exact path="/" render={() => <HomePageComponent />} />
        </Switch>
      </div>
    </main>
  </div>
}

const AppRoutesComponent = styled(_AppRoutes)`
  display: flex;
  margin-top: 64px;
  .drawer {
    width: 180px;
    flex-shrink: 0;
    min-height: 100vh;
  }
  .MuiListItemText-primary {
    font-size: 14px;
  }
  .MuiListItem-root {
    padding: 6px 16px;
    &:hover {
      background-color: ${p => lighten(p.theme.palette.alltech.terracotta[10], 0.3)};
    }
  }
  .Mui-selected {
    color: ${p => p.theme.palette.alltech.terracotta.main} !important;
    background: ${p => p.theme.palette.alltech.terracotta[20]} !important;
  }
  .content {
    flex-grow: 1;
    padding: 20px 12px;
    transition: ${p => p.theme.transitions.create('margin', {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.leavingScreen
    })};
    margin-left: -180px;
  }
  .contentShift {
    transition: ${p => p.theme.transitions.create('margin', {
      easing: p.theme.transitions.easing.easeOut,
      duration: p.theme.transitions.duration.enteringScreen
    })};
    margin-left: 0;
  }
  .helpText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 14px;
    padding: 6px;
    background: ${p => p.theme.palette.alltech.light_blue[60]};
    box-shadow: 0 2px 4px ${p => p.theme.palette.alltech.dark_grey.main};
    transition: ${p => p.theme.transitions.create('left', {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.leavingScreen
    })};
  }
  .helpTextShift {
    left: 180px;
    transition: ${p => p.theme.transitions.create('left', {
      easing: p.theme.transitions.easing.easeOut,
      duration: p.theme.transitions.duration.enteringScreen
    })};
  }
`
export default AppRoutesComponent