import { Typography } from '@mui/material'
import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { NavBar } from 'src/components/navBar/navbar'
import RPMValidationComponent from './rpm-validation'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _RPMSectionNav: React.FC<proptypes & { className?: string }> = () => {
  const { path } = useRouteMatch()
  return <ErrorBoundary page="rpm nav bar">
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography variant='h5'>RPM</Typography>
      <NavBar tabs={[
        {
          title: 'RPM Validation',
          to: `${path}/RPMValidation`,
        }
      ]} />
    </div>
  </ErrorBoundary>
}

const _RPMSection: React.FC<proptypes & { className?: string }> = () => {
  const { path } = useRouteMatch()
  return <React.Fragment>
    <Switch>
      <Route path={`${path}/RPMValidation`} render={() => <ErrorBoundary page='rpm validation'><RPMValidationComponent /></ErrorBoundary>}></Route>
    </Switch>
  </React.Fragment>
}

export const RPMSectionComponent = _RPMSection
export const RPMSectionNavComponent = _RPMSectionNav
export default RPMSectionComponent