import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { GridActionsCellItem, GridColumnHeaderParams, GridRenderCellParams, GridRowParams, GridSortItem, gridSortModelSelector, gridStringOrNumberComparator, useGridApiRef } from '@mui/x-data-grid-pro'
import { BinIcon } from 'src/components/icons/bin'
import { Button, ClickAwayListener, Dialog, DialogActions, DialogContent, Grow, lighten, MenuItem, MenuList, Paper, Tooltip, Typography } from '@mui/material'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { ArrowRightIcon } from 'src/components/icons/arrow-right'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { intouchSelectors } from 'src/store/selectors/intouch.selector'
import { SettingsIcon } from 'src/components/icons/settings'
import { AddProviderMappingContainer, EditExternalClientDialog, EditIntouchClientDialog } from  '../components'
import { EditIcon } from 'src/components/icons/edit'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { ActiveFilterIcon } from 'src/components/icons/active-filter'
import { FilterIcon } from 'src/components/icons/filter'
import { StyledPopper } from '../../rpm/rpm-validation'
import { CircleTickIcon } from 'src/components/icons/circle-tick'
import { CircleIcon } from 'src/components/icons/circle'
import HelperIconComponent from 'src/components/helperIcon/helper-icon'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _ProviderMappings: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [selectedRow, setSelectedRow] = useState<API_NS.ProviderMappings>()
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [openEditIntouchDialog, setOpenEditIntouchDialog] = useState<boolean>(false)
  const [openEditExternalDialog, setOpenEditExternalDialog] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [activeProviders, setActiveProviders] = useState<string[]>([])
  const [tableData, setTableData] = useState<Partial<API_NS.ProviderMappings> & { id: number }[] | undefined>()
  const providers = useTypedAppSelector(s => s.intouchState.providers)
  const providerMappings = useTypedAppSelector(s => s.intouchState.providerMappings)
  const updateMappingStatus = useTypedAppSelector(s => s.intouchState.updateProviderMappingStatus)
  const addMappingStatus = useTypedAppSelector(s => s.intouchState.addProviderMappingStatus)
  const providersList = useTypedAppSelector(intouchSelectors.providersList)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme().palette
  const anchorRef = useRef<HTMLButtonElement>(null)
  const CustomGridActionsCellItem = GridActionsCellItem as any

  const deleteMapping = () => {
    if (selectedRow && selectedRow?.ProviderClientMappingId > 0)
      dispatch(intouchAction.deleteProviderMapping({ mapId: selectedRow.ProviderClientMappingId.toString() }))
  }

  const activeProviderChange = val => {
    let arr = activeProviders.includes(val) ? activeProviders.filter(a => a !== val) : [...activeProviders, val]
    setActiveProviders(arr)
  }

  const columns = [
    {
      field: 'editExternal',
      flex: 0.1,
      minWidth: 40,
      type: 'actions',
      sortable: false,
      filterable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          className='icons'
          icon={<Tooltip title='Edit External Client' followCursor><span><EditIcon size={20} /></span></Tooltip>}
          label='Edit External Client'
          disabled={hasAccess}
          onClick={() => { setOpenEditExternalDialog(true); setSelectedRow(p.row) }}
        />
      ]
    },
    {
      field: 'ExternalClientId',
      headerName: 'External Client',
      flex: 0.4,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1,
    },
    {
      field: 'arrow',
      headerName: '',
      flex: 0.2,
      sortable: false,
      filterable: false,
      renderCell: (_p: GridRenderCellParams) => <ArrowRightIcon size={25} />
    },
    {
      field: 'editIntouch',
      flex: 0.1,
      minWidth: 40,
      type: 'actions',
      sortable: false,
      filterable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          className='icons'
          icon={<Tooltip title='Edit Intouch Client' followCursor><span><EditIcon size={20} /></span></Tooltip>}
          label='Edit Intouch Client'
          disabled={hasAccess}
          onClick={() => { setOpenEditIntouchDialog(true); setSelectedRow(p.row) }}
        />
      ]
    },
    {
      field: 'Username',
      headerName: 'InTouch Client ID',
      flex: 0.4,
      sortComparator: (v1, v2, p1, p2): number => {
        let model;
        try {
            model = apiRef && gridSortModelSelector(apiRef)
        }
        catch(e) {
            model = undefined;
        }
        if ((v1 === undefined || v2 === undefined) || (v1 === null || v2 === null) && model) {
            if (v1 === v2) return 0;
            else {
              const sortColumn = model.find((s: GridSortItem) => s.field == p1.field);
              if (sortColumn && sortColumn.sort === "desc")
                return (v1 === undefined) || (v1 === null) ? -1 : 1;
              else
                return (v1 === undefined) || (v1 === null) ? 1 : -1;
            }
        }
        else return gridStringOrNumberComparator(v1, v2, p1, p2);
      }
    },
    {
      field: 'AccountName',
      headerName: 'InTouch Client Name',
      flex: 1,
      sortComparator: (v1, v2, p1, p2): number => {
        let model;
        try {
            model = apiRef && gridSortModelSelector(apiRef)
        }
        catch(e) {
            model = undefined;
        }
        if ((v1 === undefined || v2 === undefined) || (v1 === null || v2 === null) && model) {
            if (v1 === v2) return 0;
            else {
              const sortColumn = model.find((s: GridSortItem) => s.field == p1.field);
              if (sortColumn && sortColumn.sort === "desc")
                return (v1 === undefined) || (v1 === null) ? -1 : 1;
              else
                return (v1 === undefined) || (v1 === null) ? 1 : -1;
            }
        }
        else return gridStringOrNumberComparator(v1, v2, p1, p2);
      }
    },
    {
      field: 'ProviderName',
      headerName: 'Provider',
      flex: 0.6,
      sortComparator: (a, b) => a?.toLowerCase() < b?.toLowerCase() ? -1 : 1
    },
    {
      field: 'Nutritionist',
      headerName: 'Nutritionist',
      flex: 0.6,
      sortComparator: (v1, v2, p1, p2): number => {
        let model;
        try {
            model = apiRef && gridSortModelSelector(apiRef)
        }
        catch(e) {
            model = undefined;
        }
        if ((v1 === undefined || v2 === undefined) || (v1 === null || v2 === null) && model) {
            if (v1 === v2) return 0;
            else {
              const sortColumn = model.find((s: GridSortItem) => s.field == p1.field);
              if (sortColumn && sortColumn.sort === "desc")
                return (v1 === undefined) || (v1 === null) ? -1 : 1;
              else
                return (v1 === undefined) || (v1 === null) ? 1 : -1;
            }
        }
        else return gridStringOrNumberComparator(v1, v2, p1, p2);
      }
    },
    {
      field: 'actions',
      flex: 0.3,
      minWidth: 100,
      type: 'actions',
      sortable: false,
      filterable: false,
      renderHeader: (_p: GridColumnHeaderParams) => (<HelperIconComponent iconSize={22} helpText={<div style={{ padding: 6, width: 340 }}>
        To enable the delete function for a provider mapping, please click on the configure button for that mapping and manually remove all of its auto rpms.
      </div>} />),
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          className='icons'
          icon={<Tooltip title='Configure' followCursor><span><SettingsIcon size={25} /></span></Tooltip>}
          label='Configure'
          onClick={() => { dispatch(intouchAction.setAutoRpmUser({ external: p.row.ExternalClientId, internal: p.row.Username })); history.push(`/intouch/ProviderMappings/AutoRpms/${p.row.ProviderClientMappingId}`) }}
          disabled={!p.row.Username || hasAccess}
        />,
        <CustomGridActionsCellItem
          className='icons'
          icon={<Tooltip title='Delete' followCursor><span><BinIcon size={25} /></span></Tooltip>}
          label='Delete'
          onClick={() => { setSelectedRow(p.row); setOpenDelete(true) }}
          disabled={!p.row.Username || (p.row.Username && p.row.HasMapping) || hasAccess}
        />
      ]
    }
  ]

  useEffect(() => {
    if (!providers?.value || !providers.loaded) return
    if ((providersList || []).length === 0) return

    if (activeProviders.length > 0)
      setTableData(providersList.filter(p => p.ProviderName ? activeProviders.some(a => a === p.ProviderName) : false))
    else
      setTableData(providersList)
  }, [providers, providersList, activeProviders])
  
  useEffect(() => {
    setLoading(providers?.loading || providerMappings?.loading || updateMappingStatus?.loading || addMappingStatus?.loading ? true : false)
  }, [providers?.loading, providerMappings?.loading, updateMappingStatus?.loading, addMappingStatus?.loading])

  useEffect(() => {
    providers?.message && setMessage({ message: providers.message.msg, severity: providers.message.level, clear: () => dispatch(intouchAction.clearProvidersMessage()) })
    providerMappings?.message && setMessage({ message: providerMappings.message.msg, severity: providerMappings.message.level, clear: () => dispatch(intouchAction.clearProvidersMappingsMessage()) })
    updateMappingStatus?.message && setMessage({ message: updateMappingStatus.message.msg, severity: updateMappingStatus.message.level, clear: () => dispatch(intouchAction.clearUpdateProviderMappingMessage()) })
    addMappingStatus?.message && setMessage({ message: addMappingStatus.message.msg, severity: addMappingStatus.message.level, clear: () => dispatch(intouchAction.clearAddProviderMappingMessage()) })
    if (updateMappingStatus?.message?.level === 'success') {
      openEditExternalDialog && setOpenEditExternalDialog(false)
      openEditIntouchDialog && setOpenEditIntouchDialog(false)
    }
  }, [providers?.message, providerMappings?.message, updateMappingStatus?.message, addMappingStatus?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 18, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    dispatch(intouchAction.requestProviderMappings())
    dispatch(intouchAction.requestProviders())

    return () => {
      setLoading(false)
    }
  }, [])

  const deletePMDialog = <Dialog fullWidth open={openDelete} onClose={() => setOpenDelete(false)}>
    <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this provider mapping?</DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenDelete(false); deleteMapping() }}>Confirm</Button>
      <Button size='small' onClick={() => setOpenDelete(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>

  const headerMenu = () => <React.Fragment>
    <Tooltip title='Filter' followCursor>
      <Button className='filter-button' color='primary' ref={anchorRef} onClick={() => setOpenFilter(!openFilter)}>
       {activeProviders.length > 0 ? <ActiveFilterIcon size={26} /> : <FilterIcon size={26} />}
      </Button>
    </Tooltip>
    <StyledPopper open={openFilter} anchorEl={anchorRef.current} role={undefined} placement='bottom-start' style={{ zIndex: 999 }} data-cy='filter-dropdown' transition>
      {({ TransitionProps }) => (<Grow {...TransitionProps} timeout={200}>
        <Paper sx={{ bgcolor: 'white', boxShadow: `0px 4px 8px ${theme.secondary[500]}` }}>
          <ClickAwayListener onClickAway={() => setOpenFilter(false)}>
            <div>
              <MenuItem id='clear-select' style={{ padding: '8px 16px', background: lighten(theme.lightGrey[50], 0.2), fontSize: 13 }} onClick={() => setActiveProviders([])} disabled={activeProviders.length === 0}>
                Clear Selection
              </MenuItem>
              <MenuList id='providers-menu' style={{ maxHeight: 300, overflowY: 'auto', paddingTop: 0 }} variant='menu'>
                {(providers?.value || []).map((p, i) => (
                  <MenuItem style={{ padding: '6px 8px', fontSize: 13 }} key={`${p.ProviderCode}-${p.ProviderName}`} onClick={() => activeProviderChange(p.ProviderName)} selected={activeProviders.includes(p.ProviderName)}>
                    {activeProviders.includes(p.ProviderName) ? <CircleTickIcon size={25} /> : <CircleIcon size={25} />}
                    {p.ProviderName}
                  </MenuItem>
                ))}
              </MenuList>
            </div>
          </ClickAwayListener>
        </Paper>
      </Grow>)}
    </StyledPopper>
  </React.Fragment>

  return <div className={className}>
    <Paper className='paper'>
      <AddProviderMappingContainer hasAccess={hasAccess} />
      {(tableData || [])?.length > 0 && <DataGridComponent
        title=' '
        data={tableData || []}
        columns={columns as any}
        apiRef={apiRef}
        rowHeight={45}
        rowsPerPage={25}
        headerMenu={headerMenu}
        loading={providerMappings?.loading}
        defaultState={{ sorting: { sortModel: [{ field: 'ExternalClientId', sort: 'asc' }] }}}
      />}
      {openEditExternalDialog && <EditExternalClientDialog
        mapping={selectedRow}
        open={openEditExternalDialog}
        setOpen={setOpenEditExternalDialog}
        setMessage={setMessage}
        loading={updateMappingStatus?.loading}
      />}
      {openEditIntouchDialog && <EditIntouchClientDialog
        mapping={selectedRow}
        open={openEditIntouchDialog}
        setOpen={setOpenEditIntouchDialog}
        setMessage={setMessage}
        loading={updateMappingStatus?.loading}
        hasAccess={hasAccess}
      />}
      {deletePMDialog}
    </Paper>
  </div>
}

export const ProviderMappingsComponent = styled(_ProviderMappings)`
  display: flex;
  flex-direction: column;
  width: 100%;
  .provider-selection-div {
    display: flex;
    justify-content: center;
  }
  .providers-dropdown {
    display: flex;
    justify-content: space-between;
    text-transform: none;
    font-size: 14px;
    padding-right: 14px;
    min-width: 200px;
    margin-left: 12px;
  }
  .paper {
    width: 100%;
    height: 100%;
    min-height: 70vh;
    padding: 8px;
  }
  .arrow-icon {
    margin: 0 10px;
  }
  .icons {
    color: ${p => p.theme.palette.alltech.terracotta.main};
    .Mui-disabled {
      color: ${p => p.theme.palette.alltech.dark_grey[70]};
    }
  }
  .add-button {
    margin-left: 20px;
  }
  .add-mapping-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0 30px 0;
  }
  .filter-button {
    border-radius: 20px;
  }
`
export default ProviderMappingsComponent