import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { Fab, Paper, Switch, Tooltip, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { LockIcon } from 'src/components/icons/lock'
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { BinIcon } from 'src/components/icons/bin'
import { addNewClientDialog, AddNewCustomerDialog, ChangePasswordDialog, deleteClientDialog } from './components'
import { SnackBarContext } from 'src/contexts/snack-bar.context'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _APIManagement: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [account, setAccount] = useState<{acc: API_NS.Accounts, type: 'customer'} | undefined>(undefined)
  const [selectedCustomer, setSelectedCustomer] = useState<API_NS.ApiCustomers>()
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [clear, setClear] = useState<boolean>(false)
  const [openNewCustomer, setOpenNewCustomer] = useState<boolean>(false)
  const [openNewClient, setOpenNewClient] = useState<boolean>(false)
  const [openDeleteClient, setOpenDeleteClient] = useState<boolean>(false)
  const [openChangePw, setOpenChangePw] = useState<boolean>(false)
  const [clientIdToDelete, setClientIdToDelete] = useState<number>()
  const [customerDetail, setCustomerDetail] = useState<{LoginId: number, Login: string, Password: string, IsEnabled: boolean}>()
  const customers = useTypedAppSelector(s => s.intouchState.apiCustomers)
  const clientStatus = useTypedAppSelector(s => s.intouchState.clientStatus)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const customerApiRef = useGridApiRef()
  const clientApiRef = useGridApiRef()
  const dispatch = useDispatch()
  const CustomGridActionsCellItem = GridActionsCellItem as any

  const selectAccountHandler = (acc, type) => setAccount({ acc, type })
  
  const resetClear = () => setClear(false)

  const customerDetailChange = val => setCustomerDetail({...(customerDetail ? customerDetail : {}), ...val})

  const addNewCustomer = details => {
    setCustomerDetail(details)
    if (!details) return

    const exists = (customers?.value || []).find(c => c.Login === details?.Login)
    if (Object.values(exists || []).length > 0)
      setMessage({ message: 'This customer has already been set up', severity: 'warning', clear: null })
    else
      dispatch(intouchAction.addApiCustomer({ Login: details.Login, Password: details.Password }))
  }

  const updatePassword = () => {
    if (!customerDetail) return    
    dispatch(intouchAction.updateApiCustomer(customerDetail))
  }

  const handleCustomerSwitch = (e, row) => {
    dispatch(intouchAction.updateApiCustomerEnabled({Login: row.Login, LoginId: row.LoginId, IsEnabled: e.target.checked}))
  }

  const addNewClient = () => {
    if (!selectedCustomer || !account) return

    const exists = (selectedCustomer?.Clients || []).find(c => c.Client === account?.acc?.ClientCode)
    if (Object.values(exists || []).length > 0)
      setMessage({ message: 'This client has already been set up', severity: 'warning', clear: null })
    else
      dispatch(intouchAction.addApiClient({ LoginId: selectedCustomer.LoginId, Client: account.acc.ClientCode }))
  }

  const deleteClient = () => {
    if (!selectedCustomer || !clientIdToDelete) return
    dispatch(intouchAction.deleteApiClient({ id: clientIdToDelete, data: { LoginId: selectedCustomer?.LoginId } }))
  }

  const customerColumns = [
    {
      field: 'actions',
      flex: 0.1,
      type: 'actions',
      sortable: false,
      filterable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={<Tooltip title='Change Password' followCursor><span><LockIcon className='primary-color' size={25} /></span></Tooltip>}
          label='Change Password'
          disabled={hasAccess}
          onClick={() => { customerDetailChange(p.row); setOpenChangePw(true) }}
        />
      ]
    },
    {
      field: 'Login',
      headerName: ' ',
      flex: 1,
      cellClassName: 'table-cell',
      sortComparator: (a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1,
    },
    {
      field: 'IsEnabled',
      headerName: ' ',
      flex: 0.5,
      minWidth: 180,
      sortable: false,
      filterable: false,
      align: 'left',
      cellClassName: 'table-cell',
      renderCell: (p: GridRenderCellParams) => <div style={{ width: '100%', textAlign: 'center' }}>
        <Typography variant='caption'>Disable</Typography>
        <Switch
          disabled={hasAccess}
          checked={p.value}
          onClick={e => e.stopPropagation()}
          onChange={e => handleCustomerSwitch(e, p.row)}
          value={p.value}
          color='primary'
        />
        <Typography variant='caption'>Enable</Typography>
      </div>
    },
  ]

  const clientColumns = [
    {
      field: 'Client',
      headerName: ' ',
      flex: 1,
      cellClassName: 'table-cell',
      sortComparator: (a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1,
    },
    {
      field: 'actions',
      flex: 0.2,
      type: 'actions',
      sortable: false,
      filterable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={<Tooltip title='Delete' followCursor><span><BinIcon className='primary-color' size={25} /></span></Tooltip>}
          label='Delete'
          disabled={hasAccess}
          onClick={() => { setClientIdToDelete(p.row.Id); setOpenDeleteClient(true) }}
        />
      ]
    },
  ]

  useEffect(() => {
    setLoading(customers?.loading ? true : false)
  }, [customers?.loading])

  useEffect(() => {
    customers?.message && setMessage({ message: customers.message.msg, severity: customers.message.level, clear: () => dispatch(intouchAction.clearApiCustomersMessage()) })
    if (customers?.message?.level === 'success') setCustomerDetail(undefined)
  }, [customers?.message])

  useEffect(() => {
    if (clientStatus && !clientStatus?.loading && clientStatus?.message?.level === 'success') {
      if (clientStatus?.message?.msg.includes('added')) {
        const findCustomer = (customers?.value || []).find(c => c.LoginId === selectedCustomer?.LoginId)
        if (Object.values(findCustomer || [])?.length > 0) setSelectedCustomer(findCustomer)
        setMessage({ message: 'Client has been added', severity: 'success', clear: null })
        setCustomerDetail(undefined)
      }
      if (clientStatus?.message?.msg.includes('deleted')) {
        const findCustomer = (customers?.value || []).find(c => c.LoginId === selectedCustomer?.LoginId)
        if (Object.values(findCustomer || [])?.length > 0) setSelectedCustomer(findCustomer)
        setMessage({ message: 'Client has been deleted', severity: 'success', clear: null })
        setClientIdToDelete(undefined)
      }
    }
  }, [clientStatus?.message])


  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 26, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    dispatch(intouchAction.requestApiCustomers())
    return () => {
      setLoading(false)
    }
  }, [])

  return <div className={className}>
    <Paper className='paper paper-div'>
      <Paper className='customer-paper'>
        <Tooltip title='Add New Customer' placement='top'>
          <Fab className='fab-button' color='primary' size='small' onClick={() => setOpenNewCustomer(true)}><Add /></Fab>
        </Tooltip>
        <div className='user-container' style={{ marginTop: 0 }}>
          <DataGridComponent
            title=' '
            data={(customers?.value || []).map((c, i) => ({ ...c, id: i }))}
            columns={customerColumns as any}
            apiRef={customerApiRef}
            rowHeight={45}
            loading={customers?.loading}
            useSearch={false}
            headerHeight={0}
            usePagination={false}
            getRowClassName={p => p.row?.Login === selectedCustomer?.Login ? 'selected-row' : ''}
            onRowClick={r => setSelectedCustomer(r) }
            defaultState={{ sorting: { sortModel: [{ field: 'Login', sort: 'asc' }] }}}
          />
        </div>
      </Paper>
      <Paper className='client-paper'>
        {Object.values(selectedCustomer || []).length > 0 ? <React.Fragment>
          <Tooltip title='Add New Client' placement='top'>
            <Fab className='fab-button' color='primary' size='small' onClick={() => setOpenNewClient(true)}><Add /></Fab>
          </Tooltip>
          <div className='user-container'>
            {Object.values(selectedCustomer?.Clients || []).length > 0 ?
              <DataGridComponent
                title=' '
                data={(selectedCustomer?.Clients || []).map((c, i) => ({ ...c, id: i }))}
                columns={clientColumns as any}
                apiRef={clientApiRef}
                rowHeight={45}
                usePagination={false}
                loading={clientStatus?.loading}
                useSearch={false}
                headerHeight={0}
                defaultState={{ sorting: { sortModel: [{ field: 'Client', sort: 'asc' }] }}}
              />
              : <Typography style={{ textAlign: 'center', marginTop: 16 }} variant='subtitle2'>No Clients to Show</Typography>}
          </div>
        </React.Fragment>
        : <Typography style={{ textAlign: 'center', marginTop: 16 }} variant='subtitle2'>Choose a Customer from the List</Typography> }
      </Paper>
      {addNewClientDialog(openNewClient, setOpenNewClient, account, selectAccountHandler, clear, setClear, resetClear, addNewClient, hasAccess)}
      {deleteClientDialog(openDeleteClient, setOpenDeleteClient, deleteClient)}
      {openNewCustomer && <AddNewCustomerDialog
        className={className}
        open={openNewCustomer}
        setOpen={setOpenNewCustomer}
        customer={customerDetail}
        setCustomer={setCustomerDetail}
        addCustomer={addNewCustomer}
      />}
      {openChangePw && <ChangePasswordDialog
        open={openChangePw}
        setOpen={setOpenChangePw}
        customer={customerDetail}
        setCustomer={setCustomerDetail}
        updatePassword={updatePassword}
        setClear={setClear}
      />}
    </Paper>
  </div>
}

export const APIManagementComponent = styled(_APIManagement)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .paper-div {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .paper {
    padding: 24px;
    background: white;
  }
  .customer-paper {
    position: relative;
    width: 60%;
    padding: 32px;
  }
  .client-paper {
    position: relative;
    width: 40%;
    padding: 32px;
    margin-left: 20px;
  }
  .user-container {
    display: flex;
    flex-direction: column;
    height: 60vh;
    overflow-y: auto;
  }
  .fab-button {
    position: absolute;
    top: -18px;
    right: 16px;
  }
  .selected-row {
    background: ${p => p.theme.palette.alltech.terracotta[20]};
    &:hover {
      background: ${p => p.theme.palette.alltech.terracotta[30]} !important;
    }
  }
  .password-field {
    margin-top: 12px;
    .MuiOutlinedInput-root {
      padding-right: 6px;
    }
  }
  .primary-color {
    color: ${p => p.theme.palette.alltech.terracotta.main};
  }
`
export default APIManagementComponent