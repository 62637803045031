import React, { useContext, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Paper, Tooltip } from '@mui/material'
import { GridActionsCellItem, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { DataGridComponent } from 'src/components/data-grid/data-grid'
import { ArrowRightIcon } from 'src/components/icons/arrow-right'
import { BinIcon } from 'src/components/icons/bin'
import { AppBarLoadingContext } from 'src/contexts/appbar-loading.context'
import { EnabledSecurityContext } from 'src/contexts/enable-security.context'
import { SnackBarContext } from 'src/contexts/snack-bar.context'
import { API_NS } from 'src/lib/api/endpoints/endpoint.types'
import { sortSubFeatures } from 'src/lib/utils/methods'
import { useTypedAppSelector } from 'src/store'
import { intouchAction } from 'src/store/actions/intouch.actions'
import { AddDemoUserContainer } from './components'
import styled from 'styled-components'

export type proptypes = {
}
export const defaultProps: proptypes = {
}

const _DemoUsers: React.FC<proptypes & { className?: string }> = (props) => {
  const { className } = props
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [userToDelete, setUserToDelete] = useState<API_NS.DemoUsers | null>(null)
  const [openDelete, setOpenDelete] = useState<boolean>(false)
  const demoUsers = useTypedAppSelector(s => s.intouchState.demoUsers)
  const { setLoading } = useContext(AppBarLoadingContext)
  const { enabledSecurity } = useContext(EnabledSecurityContext)
  const { setMessage } = useContext(SnackBarContext)
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const intl = useIntl()
  const CustomGridActionsCellItem = GridActionsCellItem as any

  const deleteDemoUser = () => {
    userToDelete && dispatch(intouchAction.deleteDemoUser({ clientid: userToDelete.DemoClientCode }))
    setUserToDelete(null)
  }
  
  const syncUserData = user => {
    setMessage({ message: 'Please allow at least 1 minute for the sync process to complete', severity: 'info', clear: null })
    dispatch(intouchAction.syncDemoUser({ ClientId: user.OriginalClientCode , DemoClientId: user.DemoClientCode }))
  }

  const columns = [
    {
      field: 'OriginalClientCode',
      headerName: 'Client Code',
      flex: 1,
      sortComparator: (a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1,
    },
    {
      field: 'arrow',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: (_p: GridRenderCellParams) => <ArrowRightIcon size={26} />
    },
    {
      field: 'DemoClientCode',
      headerName: 'Demo Client Code',
      flex: 1,
      sortComparator: (a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1,
    },
    {
      field: 'LastRefreshed',
      headerName: 'Last Synced Date',
      flex: 0.6,
      filterable: false,
      valueFormatter: (p: any) => {
        const d = p.value as string
        return d ? intl.formatDate(d, { year: '2-digit', month: '2-digit', day: '2-digit' }) : '-'
      },
      sortComparator: (a, b) => moment(a).isBefore(b) ? -1 : 1,
    },
    {
      field: 'sync',
      headerName: ' ',
      flex: 0.4,
      minWidth: 100,
      sortable: false,
      filterable: false,
      align: 'center',
      renderCell: (p: GridRenderCellParams) =>
        <Button variant='contained' color='primary' size='small' onClick={() => syncUserData(p.row)} disabled={hasAccess}>Sync User</Button>
    },
    {
      field: 'actions',
      flex: 0.3,
      type: 'actions',
      sortable: false,
      filterable: false,
      getActions: (p: GridRowParams) => [
        <CustomGridActionsCellItem
          icon={<Tooltip title='Delete' followCursor><span><BinIcon className='primary-color' size={25} /></span></Tooltip>}
          label='Delete'
          disabled={hasAccess}
          onClick={() => { setUserToDelete(p.row); setOpenDelete(true) }}
        />
      ]
    }
  ]

  useEffect(() => {
    setLoading(demoUsers?.loading ? true : false)
  }, [demoUsers?.loading])

  useEffect(() => {
    demoUsers?.message && setMessage({ message: demoUsers.message.msg, severity: demoUsers.message.level, clear: () => dispatch(intouchAction.clearDemoUsersMessage()) })
  }, [demoUsers?.message])

  useEffect(() => {
    setHasAccess(!sortSubFeatures('intouch', 29, enabledSecurity))
  }, [enabledSecurity])

  useEffect(() => {
    dispatch(intouchAction.requestDemoUsers())
    return () => {
      setLoading(false)
    }
  }, [])

  const deleteDemoUserDialog = <Dialog fullWidth open={openDelete} onClose={() => setOpenDelete(false)}>
    <DialogContent style={{ fontSize: 15 }}>Are you sure you want to delete this demo user?</DialogContent>
    <DialogActions>
      <Button size='small' onClick={() => { setOpenDelete(false); deleteDemoUser() }}>Confirm</Button>
      <Button size='small' onClick={() => setOpenDelete(false)}>Cancel</Button>
    </DialogActions>
  </Dialog>

  return <div className={className}>
    <Paper className='paper'>
      <AddDemoUserContainer hasAccess={hasAccess} />
      <DataGridComponent
        title=' '
        data={(demoUsers?.value || []).map((d, i) => ({ ...d, id: i }))}
        columns={columns as any}
        apiRef={apiRef}
        rowHeight={45}
        rowsPerPage={100}
        loading={demoUsers?.loading}
        defaultState={{ sorting: { sortModel: [{ field: 'OriginalClientCode', sort: 'asc' }] }}}
      />
    </Paper>
    {deleteDemoUserDialog}
  </div>
}

export const DemoUsersComponent = styled(_DemoUsers)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .paper {
    width: 100%;
    height: 100%;
    min-height: 75vh;
    padding: 8px;
  }
  .add-user-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0 30px 0;
  }
  .arrow-icon {
    margin: 0 10px;
  }
  .add-button {
    margin-left: 20px;
  }
  .column-header, .table-cell {
    font-size: 13px;
  }
  .primary-color {
    color:  ${p => p.theme.palette.alltech.terracotta.main};
  }
`
export default DemoUsersComponent