import { combineEpics, Epic, } from "redux-observable"
import { TRpmAction, rpmAction } from "../actions/rpm.actions"
import { catchError, filter, map, switchMap } from "rxjs/operators"
import { endpoints } from "../../lib/api/endpoints/endpoint_list"
import { of } from "rxjs"
import { TCombinedState } from '../index'
import { epicErrorLogger } from "../../lib/utils/epic-error-logger"

/**
 * Trigger API calls or any other async actions
 */
export const rpmEpic: Epic<TRpmAction, any, TCombinedState> = combineEpics(

  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_CLIENT_RPMS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.clientRpms.get({ clientId: a.payload.clientId.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return rpmAction.requestClientRpmsSuccess(epicRes.data.Payload)
          } else {
            return rpmAction.requestClientRpmsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(rpmAction.requestClientRpmsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_NUTRITIONIST_RPMS' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.nutritionistRpms.get({ id: a.payload.id.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return rpmAction.requestNutritionistRpmsSuccess(epicRes.data.Payload)
          } else {
            return rpmAction.requestNutritionistRpmsFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(rpmAction.requestNutritionistRpmsFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_COMMENT' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateComment.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return rpmAction.updateCommentSuccess(a.payload)
          } else {
            return rpmAction.updateCommentFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(rpmAction.updateCommentFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'UPDATE_VALIDATION' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.updateValidation.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return rpmAction.updateValidationSuccess(a.payload)
          } else {
            return rpmAction.updateValidationFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(rpmAction.updateValidationFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'REQUEST_RPM_EMAIL_LIST' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.rpmEmailList.get({ clientCode: a.payload.clientCode.toString() }).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return rpmAction.requestRpmEmailListSuccess(epicRes.data.Payload)
          } else {
            return rpmAction.requestRpmEmailListFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(rpmAction.requestRpmEmailListFailed(epicError))
        })
      )
    })
  ),
  (action$, state$) => action$.pipe(
    map(a => a.type === 'SEND_RPM_EMAIL' ? a : null as never),
    filter(a => Boolean(a)),
    switchMap(a => {
      return endpoints.sendRpmEmail.post(a.payload).pipe(
        map(epicRes => {
          if (epicRes.status === 200) {
            return rpmAction.sendRpmEmailSuccess(epicRes.data.Payload)
          } else {
            return rpmAction.sendRpmEmailFailed(epicRes.data?.error as string || '')
          }
        }),
        catchError(epicError => {
          epicErrorLogger(epicError)
          return of(rpmAction.sendRpmEmailFailed(epicError))
        })
      )
    })
  )
  
)