import React, { useState } from 'react';

const SnackBarContext = React.createContext();

const SnackBarContextProvider = (props) => {
  const [message, _setMessage] = useState({ message: undefined, severity: 'success', clear: null })

  const setMessage = msg => _setMessage(msg)

  return <SnackBarContext.Provider value={{ message, setMessage }}>
    {props.children}
  </SnackBarContext.Provider>
}

export { SnackBarContext, SnackBarContextProvider };